import { post } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';
import tracking from 'core-web/components/Tracking';

export default async (basketId, partNo, quantity, pricelistId, infos = {}, comment) => {
    const trackingData = tracking.getTrackingData();

    const data = {
        'part_no': partNo,
        quantity,
        'application_id': getModel('application').applicationId,
        infos,
        'pricelist_id': pricelistId,
        ...trackingData,
    };

    if (comment) {
        data.comment = comment;
    }

    return await post(`/frontend/basket/${basketId}/items`, data);
};
