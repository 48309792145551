import release from '!!raw-loader!./../../../release.txt';
import * as Sentry from '@sentry/browser';
import * as SentryIntegrations from '@sentry/integrations';

//const Cookie = require('js-cookie')

const shouldSendError = () => {
    return process.env.REACT_APP_SENTRY_CLIENT_ENABLED;
};

export const sentry = () => {
    const sentryOptions = {
        dsn: process.env.REACT_APP_SENTRY_CLIENT_DSN,
        release,
        maxBreadcrumbs: 50,
        attachStacktrace: true,
        environment: process.env.REACT_APP_ENV,
        whitelistUrls: [window.location.host],
        tracesSampleRate: 0,
        ignoreErrors: [
            "Failed to construct 'URL': Please use the 'new' operator",
            'JSON Parse error: Unexpected identifier "collector"',
            'JSON Parse error: Unexpected identifier "setImmediate$0"',
            'document.getElementsByClassName.ToString',
            'fb_xd_fragment',
        ],
        ignoreUrls: [
            'polyfill.io',
            // Facebook
            /graph\.facebook\.com/i,
            /connect\.facebook\.net\/en_US\/all\.js/i,
            /.*fbq.*/,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
        ],
    };

    // Development mode
    if (!shouldSendError()) {
        // Prevent messages being sent to Sentry
        sentryOptions.beforeSend = () => null;

        // Instead, dump the errors to the console
        sentryOptions.integrations = [
            new SentryIntegrations.Debug({
                debugger: false,
            }),
        ];
    }

    Sentry.init(sentryOptions);

    return Sentry;
};

export const captureException = (err, ctx) => {
    Sentry.configureScope(scope => {
        if (err.message) {
            // fix deduplication for ssr/browser
            scope.setFingerprint([err.message]);
        }

        if (err.statusCode) {
            scope.setExtra('statusCode', err.statusCode);
        }

        scope.setTag('ssr', false);

        if (ctx) {
            const { res, errorInfo, query, pathname } = ctx;

            if (res && res.statusCode) {
                scope.setExtra('statusCode', res.statusCode);
            }

            scope.setExtra('query', query);
            scope.setExtra('pathname', pathname);

            // when client use package to get cookie
            /*
            const sessionId = Cookie.get('sid')
            if (sessionId) {
                scope.setUser({ id: sessionId })
            }
            */

            if (errorInfo) {
                Object.keys(errorInfo).forEach(key => scope.setExtra(key, errorInfo[key]));
            }
        }
    });

    return Sentry.captureException(err);
};

export default {
    sentry,
    captureException,
};
