import { buildQueryString, get, request } from 'core-web/libs/grebbcommerce-api/util';
import { API_URL, inServer } from 'core-web/constants';

export default async (applicationId, url, parameters = {}, origin) => {
    // Build parameters.
    const builtParameters = buildQueryString(parameters);

    const requestUrl = `/frontend/content/resolve-redirect?application_id=${applicationId}&slug=${encodeURIComponent(
        url
    )}${builtParameters && `&${builtParameters}`}`;

    if (
        inServer &&
        process.env.REACT_APP_ALLOWED_DOMAINS &&
        process.env.REACT_APP_ALLOWED_DOMAINS.indexOf(url) === -1 &&
        API_URL
    ) {
        return await request({ method: 'GET', path: requestUrl, baseUrl: API_URL });
    }

    if (!API_URL && origin) {
        return await request({ method: 'GET', path: requestUrl, baseUrl: origin });
    }

    return await get(requestUrl);
};
