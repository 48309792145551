import { post } from 'core-web/libs/grebbcommerce-api/util/authClient';
import { getModel } from 'core-web/state';
import { auth } from 'core-web/util/firebase';

export default async (basketId, paymentMethodId, returnDeliveryMethods = false, returnDeliveryPoints = false) => {
    let token;
    if (auth.currentUser) {
        token = await auth.currentUser.getIdToken();
    }

    const response = await post(
        `/frontend/checkout/${basketId}/method/${paymentMethodId}`,
        {
            'application_id': getModel('application').applicationId,
            'return_delivery_methods': returnDeliveryMethods,
            'return_delivery_points': returnDeliveryPoints,
        },
        token ? { 'Authorization': `Bearer ${token}` } : {}
    );

    return await response.body();
};
