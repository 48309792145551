const breakpointValues = {
    breakpoints: ['375px', '768px', '1024px', '1222px', '1440px'],
    labels: ['sm', 'md', 'lg', 'nav', 'xl'],
};

// See if we can import these from the theme instead.
try {
    const themeBreakpoints = require('theme/config/breakpoints');
    breakpointValues.breakpoints = themeBreakpoints.breakpoints || breakpointValues.breakpoints;
    breakpointValues.labels = themeBreakpoints.labels || breakpointValues.labels;
} catch (e) {
    // Do nothing.
}

export const breakpoints = breakpointValues.breakpoints;
export const labels = breakpointValues.labels;
