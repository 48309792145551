import { useEffect } from 'react';
import Cookies from 'js-cookie';
import getQueryParams from 'core-web/util/getQueryParams';
import { getOneTrustConsent } from './Tracking/utils/getOneTrustConsent';

const SalesforceTracking = () => {
    useEffect(() => {
        const { targetingConsent } = getOneTrustConsent();

        let cookieCheckInterval;

        const location = window.location;
        const params = getQueryParams(location);
        const sfmcId = params['sfmc_key'];

        // User has not made OneTrust selection yet, if theres a salesforceId param use interval to look for cookie settings
        if (!targetingConsent && sfmcId) {
            cookieCheckInterval = setInterval(() => {
                const OTCookieConsent = getOneTrustConsent();
                const saleforceTrackingAllowed = OTCookieConsent?.targetingConsent;

                if (saleforceTrackingAllowed) {
                    Cookies.set('sfmc_id', sfmcId, { expires: 365 });
                } else if (!saleforceTrackingAllowed) {
                    // User has denied marketing cookies
                    clearInterval(cookieCheckInterval);
                }
            }, 1000);
        } else if (targetingConsent && sfmcId) {
            // user has allowed marketing cookies and sfmcId exists
            Cookies.set('sfmc_id', sfmcId, { expires: 365 });
        }

        return () => {
            clearInterval(cookieCheckInterval);
        };
    }, []);

    return null;
};

export default SalesforceTracking;
