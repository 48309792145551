import { PACKAGE_TYPE_ID } from 'theme/config/constants';

const FREIGHT_PART_NO = process.env.REACT_APP_STORM_FREIGHT_NO;
const parametricsBlockList = ['du_', 'mk_', 'hjl_', 'dg_', 'hy_', 'DU_', 'MK_', 'HJL_', 'DG_', 'HY_'];
const appName = process.env.REACT_APP_NAME || '';

export const round = amount => {
    return parseFloat(amount, 10);
};

export const formatPrice = price => {
    let fixedPrice = price;
    const p = price.toString();
    const onlydecimals = p.split(/[.,]+/)[1] || null;

    if (onlydecimals?.length > 2) {
        fixedPrice = Math.round(price * 100) / 100;
    }
    return fixedPrice;
};

export const getProductName = product => {
    const title = product.name || '';

    switch (appName) {
        case 'hylte':
            return product.description_header || title;
        case 'duab':
            return product.meta_tags || title || product.title; // || selectedVariant?.variant_name
        default:
            return title;
    }
};

export const getProduct = (product, position = null) => {
    if (!product) {
        return;
    }

    const {
        part_no: partNo,
        name,
        parentProductName,
        categories,
        price,
        ean,
        ean_code: eanCode,
        category_id: categoryId,
        manufacturer,
        manufacturer_name: manufacturerName,
        vat_rate: vatRate,
        quantity,
        format,

        parent_product_id: parentProductId,
        meta_tags: metaTags,
        type,
    } = product;

    const productId = product.product_id || product.productId || product.id;

    const fixedPrice = price && vatRate ? formatPrice(price * vatRate) : 0;

    const dataProduct = {
        name: parentProductName || name || metaTags || '',
        nameFormatted: getProductName(product),
        id: partNo || '',
        ean: ean || eanCode || '',
        price: fixedPrice,
        priceExVat: price || '',
        brand: manufacturer?.name || manufacturerName || '',
        productId: `${productId || ''}`,
    };

    if (quantity) {
        dataProduct.quantity = quantity;
    }

    if (categoryId) {
        let allCategories;
        if (Array.isArray(categories) && categories.length > 0) {
            allCategories = [...categories];
            if (allCategories.length > 1 && allCategories[0].id === categoryId) {
                allCategories.reverse();
            }
        }

        if (allCategories) {
            const categoryString = allCategories.map(category => category.value || category.name).join('/');
            dataProduct.category = categoryString;
            const category = allCategories.find(category => category.id === categoryId) || {};
            dataProduct.dimension2 = category.code || allCategories.pop().code;
        }
    }

    let isVariantProduct = type !== PACKAGE_TYPE_ID; // Default to true if not package
    if (Array.isArray(product.variants)) {
        isVariantProduct = !!product.variants.length; // exist if product comes from PDP (storm)
    }

    const parametrics = format?.variant_parametrics; // ?? product.variant_parametrics
    if (isVariantProduct && Array.isArray(parametrics) && parametrics.length > 0) {
        const filteredParametrics = parametrics.filter(({ code = '' }) => {
            return !parametricsBlockList.find(blockedParametric => (code || '').includes(blockedParametric));
        });

        if (filteredParametrics.length > 0) {
            dataProduct.variant = filteredParametrics
                .map(data => data.value)
                .filter(p => p)
                .join('|');
        }
    }

    if (product.list !== undefined) {
        dataProduct.list = product.list;
    }

    if (position !== null) {
        dataProduct.position = position + 1;
    }

    if (parentProductId) {
        dataProduct.groupId = parentProductId;
    }

    return dataProduct;
};

export const getProducts = items => {
    const products = [];
    for (let i = 0; i < items.length; i++) {
        const exludePartNos = [FREIGHT_PART_NO];
        if (!exludePartNos.includes(items[i].part_no)) {
            products.push(getProduct(items[i], i));
        }
    }
    return products.filter(item => item);
};
