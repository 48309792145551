import { initializeApp } from 'firebase/app';
import {
    FacebookAuthProvider,
    GoogleAuthProvider,
    signOut as firebaseSignOut,
    getAuth,
    getIdToken,
    getIdTokenResult,
    signInWithCustomToken,
    signInWithPopup,
    signInWithRedirect,
} from 'firebase/auth';
import Cookies from 'js-cookie';
import { get, post, put } from 'core-web/libs/grebbcommerce-api/util/authClient';
import { getModel } from 'core-web/state';
import getCountrySpecificVariable from 'core-web/util/getCountrySpecificVariable';
import { inServer } from 'core-web/constants';

let auth = {};
let isMobile = false;

const initFirebase = (locale = 'sv_SE') => {
    if (!inServer) {
        const firebaseConfig = getCountrySpecificVariable('REACT_APP_FIREBASE_CREDENTIALS', locale);
        const firebaseApp = initializeApp(firebaseConfig);

        auth = getAuth(firebaseApp);

        isMobile = typeof window !== 'undefined' && (window.isMobile || /Mobi/i.test(window.navigator.userAgent));
    }
};

const verifyCustomerId = async () => {
    let response;

    try {
        response = await getIdTokenResult(auth.currentUser);
        if (response.claims && response.claims.customer_id) {
            Cookies.set('isLoggedIn', true, { expires: 7 });
            // User has customer_id
            return;
        }

        const create = await post(
            '/auth/create',
            { 'application_id': getModel('application').applicationId },
            { Authorization: `Bearer ${response.token}` }
        );
        if (create.status() === 201) {
            return await getIdToken(auth.currentUser, true);
        }
    } catch (e) {
        return;
    }
};

const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    provider.setCustomParameters({
        prompt: 'select_account',
    });

    try {
        await signInWithPopup(auth, provider);
        await verifyCustomerId();
    } catch (error) {
        return error.message;
    }
};

const signInWithFacebook = async () => {
    const provider = new FacebookAuthProvider();

    provider.setCustomParameters({
        prompt: 'select_account',
    });

    try {
        !isMobile ? await signInWithPopup(auth, provider) : await signInWithRedirect(auth, provider);

        await verifyCustomerId();
    } catch (error) {
        return error;
    }
};

const requestEmailToken = async email => {
    try {
        await put(`/auth/magic`, { 'application_id': getModel('application').applicationId, email });

        window.localStorage.setItem('emailForSignIn', email);
    } catch (error) {
        console.error(error);
    }
};

const signInWithCustom = async (email, token) => {
    try {
        const response = await post('/auth/magic', {
            'application_id': getModel('application').applicationId,
            email,
            signature: token,
        });
        const data = await response.body();

        if (!data.data && data.message) {
            return data.message;
        }

        await signInWithCustomToken(auth, data.data.id_token);

        window.localStorage.setItem('emailForSignIn', '');

        await verifyCustomerId();
    } catch (error) {
        console.error(error);
    }
};

const user = async () => {
    try {
        await getIdTokenResult(auth.currentUser);
    } catch (error) {
        console.error(error);
    }
};

const getCustomer = async () => {
    try {
        const token = await getIdTokenResult(auth.currentUser);

        return await get(
            `/auth/me`,
            { 'application_id': getModel('application').applicationId },
            { 'Authorization': `Bearer ${token.token}` }
        );
    } catch (e) {
        console.error(e);
    }
};

const signOut = async () => {
    try {
        await firebaseSignOut(auth);
        Cookies.remove('isLoggedIn');
    } catch (error) {
        console.error(error);
    }
};

export {
    signInWithGoogle,
    signInWithFacebook,
    signInWithCustom,
    requestEmailToken,
    signOut,
    user,
    getCustomer,
    auth,
    initFirebase,
};
