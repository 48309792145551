import { post } from 'core-web/libs/grebbcommerce-api/util/authClient';
import { getModel } from 'core-web/state';
import { auth } from 'core-web/util/firebase';

export default async (storeId, checkoutId, phone) => {
    const data = {
        application_id: getModel('application').applicationId,
        store_id: storeId,
        checkout_id: checkoutId,
        phone_number: phone,
    };

    const token = await auth.currentUser.getIdToken();

    return await post('/frontend/collector/paylink', data, { 'Authorization': `Bearer ${token}` });
};
