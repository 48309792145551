import { STORM_ROLE_ADMIN, STORM_ROLE_SALE } from 'theme/config/constants';
import { getState } from 'core-web/state';

const getCustomerSalesAuth = roles => {
    if (!roles || !roles.length) {
        roles = getState('customer')?.roles;
    }

    return roles.some(
        role => role === parseInt(STORM_ROLE_SALE, 10) || role === parseInt(STORM_ROLE_ADMIN, 10)
    );
};

export default getCustomerSalesAuth;
