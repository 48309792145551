import { SHOW_OVERLAY, HIDE_OVERLAYS } from './constants';

// The initial state of this store.
const initialState = {
    current: null,
    data: null,
    wrapper: null,
};

export { initialState };

export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_OVERLAY:
            return {
                ...state,
                current: action.current,
                data: action.data,
                wrapper: action.wrapper,
            };

        case HIDE_OVERLAYS:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
};
