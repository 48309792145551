import { get } from 'core-web/libs/grebbcommerce-api/util/authClient';
import { getModel } from 'core-web/state';

export default async basketId => {
    const response = await get(
        `/frontend/basket/${basketId}/abandoned`,
        {
            application_id: getModel('application').applicationId,
        },
        {}
    );
    return await response.body();
};
