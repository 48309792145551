import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { injectModel } from 'core-web/state';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';

const Wrapper = styled('div')`
    display: flex;
    max-width: 390px;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
`;

const Warning = styled('p')`
    position: relative;
    text-align: center;
    width: fit-content;
    background-color: ${colors.lightGrey};
    padding: 8px 16px 12px 16px;
    font-size: 14px;
    color: ${colors.black};
    border-radius: 4px;
    border: 1px solid ${colors.grey};

    &::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        top: -10px;
        transform: rotate(-45deg);
        left: 50%; // offset should move with padding of parent
        border: 1px solid ${colors.grey};
        border-bottom: none;
        border-left: none;
        background-color: ${colors.lightGrey};
    }
`;

const Button = styled('button')`
    text-decoration: underline;
    text-transform: lowercase;
`;
const ConsentWarning = ({ consent, overlay, setHideWarning }) => {
    const { t } = useTranslation();
    const onClick = () => {
        overlay.show('customer_login');
        setHideWarning(false);
    };
    const infoText = consent?.text;
    return (
        <Wrapper>
            <Warning>
                {infoText}&nbsp;
                <Button onClick={() => onClick()}>{t('checkout_auth.login.submit_text')}</Button>
            </Warning>
        </Wrapper>
    );
};

ConsentWarning.propTypes = {
    consent: PropTypes.string,
    overlay: PropTypes.object.isRequired,
    setHideWarning: PropTypes.object.isRequired,
};

ConsentWarning.defaultProps = {
    consent: '',
};

export default injectModel('overlay')(ConsentWarning);
