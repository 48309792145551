import GTM from '@grebban/gtm';
import tracking from 'core-web/components/Tracking';

export default async ({ query, exactMatchPath }) => {
    const salesforceId = tracking.getSalesforceTrackingKey();

    GTM.addDataLayerEvent('exact_category_match', {
        exactMatchPath,
        query,
        subscriberID: salesforceId ? salesforceId : '',
    });
};
