import { getModel } from 'core-web/state';
import { API_ENDPOINT, API_URL } from 'core-web/constants';
import buildQueryString from './buildQueryString';

class ApiResponse {
    constructor(response, error = null) {
        this.res = response;
        this.error = error;
    }
    response() {
        return this.res;
    }
    error() {
        return this.error;
    }
    body() {
        switch (this.header('content-type')) {
            case 'application/json':
                return this.response().json();
            case 'text/plain':
                return this.response().text();
            default:
                return this.response();
        }
        // Exception
    }
    status() {
        return this.response()?.status;
    }
    ok() {
        return this.response()?.ok;
    }
    headers() {
        return this.response()?.headers;
    }
    header(name) {
        return this.headers()?.get(name);
    }
}

const client = async ({ path, method, body, headers, controllerSignal, ...extraConfig }) => {
    const { origin, protocol } = getModel('application');
    const url = (API_URL ? API_URL : protocol + origin + API_ENDPOINT) + path;

    if (process.env.NODE_ENV === 'development' && !headers.hasOwnProperty('Cache-Control')) {
        headers['Cache-Control'] = 'no-cache';
    }
    const config = {
        method,
        ...extraConfig,
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Accept': 'application/json',
            ...headers,
            ...extraConfig.headers,
        },
    };
    if (body) {
        config.body = buildQueryString(body);
    }

    if (controllerSignal) {
        config.signal = controllerSignal;
    }

    try {
        const response = await fetch(url, config);
        return new ApiResponse(response);
    } catch (e) {
        console.error(e);
        return new ApiResponse(null, e);
    }
};

const getParameters = (url, query) => {
    if (!query) {
        return url;
    }
    const prefix = url.indexOf('?') > -1 ? '&' : '?';
    const queryString = buildQueryString(query);
    return prefix + queryString;
};

export const get = (endpoint, body, headers, controllerSignal) =>
    client({
        path: endpoint + getParameters(endpoint, body),
        method: 'GET',
        body: null,
        headers,
        controllerSignal,
    });

export const post = (endpoint, body, headers) =>
    client({
        path: endpoint,
        method: 'POST',
        body,
        headers: { ...headers, ...{ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } },
    });

export const put = (endpoint, body, headers) =>
    client({
        path: endpoint,
        method: 'PUT',
        body,
        headers: { ...headers, ...{ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } },
    });

export const del = (endpoint, body, headers) =>
    client({
        path: endpoint,
        method: 'DEL',
        body,
        headers: { ...headers, ...{ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } },
    });
