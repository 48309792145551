import { PRODUCT_BACKORDER, PRODUCT_PREORDER } from 'theme/config/constants';
import { StormProduct } from '../types/product';
import formatDate from '../util/formatDate';
import { getFlag } from '../util/getFlag';

interface AlgoliaOnHand {
    [key: string]: {
        stock: number;
        lead_days: number;
        next_delivery: number | null;
    };
}

interface APIOnHand {
    value: number;
    leadtime_day_count: number;
    next_delivery_date: number;
}

interface Flag {
    code: string;
}

interface Flags {
    [index: number]: Flag;
}

interface ProductFormat {
    flags: Flags;
}

interface IncludedProducts {
    on_hand: APIOnHand;
    on_hand_store: APIOnHand;
    quantity: number;
    format: ProductFormat;
}

interface SelectedProductVariants {
    [key: string]: StormProduct;
}

export const mergeOnHands = (onHands: APIOnHand[]) => {
    const mapAsAlgolia: AlgoliaOnHand = {};
    onHands.forEach((onHand, index) => {
        if (!onHand) {
            return;
        }

        mapAsAlgolia[`onHand_${index}`] = {
            stock: onHand.value,
            lead_days: onHand.leadtime_day_count,
            next_delivery: onHand.next_delivery_date,
        };
    });

    return mapAsAlgolia;
};

const compareWorst = (result, current) => {
    if (result.stock === null || result.stock > current.value) {
        result.stock = current.value;
    }

    if (current.bookable && (!result.bookable || result.bookable > current.bookable)) {
        result.bookable = current.bookable;
    }

    if (result.leadTime === null || (current.leadTime !== null && result.leadTime < current.leadTime)) {
        result.leadTime = current.leadTime;
    }

    if (result.nextDelivery === false) {
        return result;
    }

    if (result.nextDelivery === null || (current.deliveryDate !== null && result.nextDelivery < current.deliveryDate)) {
        result.nextDelivery = current.deliveryDate;
    }

    if (!current.value && current.deliveryDate === null) {
        result.nextDelivery = false;
    }

    return result;
};

const checkNextDelivery = (delivery: number | null, locale: string) => {
    const today = new Date().getTime();
    let nextDelivery = delivery;

    if (nextDelivery) {
        const nextDeliveryTime = new Date((nextDelivery + 7200) * 1000).getTime();

        if (today < nextDeliveryTime) {
            nextDelivery = formatDate(nextDeliveryTime, 'yyyy-MM-dd', locale);
        } else {
            nextDelivery = null;
        }
    }

    return nextDelivery;
};

export const getPackageStockStatus = (includedProducts: IncludedProducts[], locale = 'sv_SE') => {
    const today = new Date().getTime();

    let stockWeb = {
        stock: null,
        leadTime: null,
        nextDelivery: null,
        bookable: null,
    };

    let stockStore = {
        stock: null,
        leadTime: null,
        nextDelivery: null,
    };

    let isBookable = false;

    stockWeb = includedProducts.reduce((result, { on_hand: onHand, on_hand_store: onHandStore, quantity, format }) => {
        if (onHandStore?.value === undefined) {
            return result;
        }

        const onHandSum = onHand.value + onHandStore?.value;
        const value = Math.floor(onHandSum / quantity);
        const leadTimeWeb = onHand.value > 0 && onHand.leadtime_day_count !== null ? onHand.leadtime_day_count : null;
        const leadTimeStore =
            onHandStore?.value > 0 && onHandStore?.leadtime_day_count !== null ? onHandStore?.leadtime_day_count : null;
        const leadTime =
            leadTimeWeb !== null && (leadTimeStore === null || leadTimeWeb < leadTimeStore)
                ? leadTimeWeb
                : leadTimeStore;

        const deliveryDateWeb = onHand.next_delivery_date * 1000 > today ? onHand.next_delivery_date : null;
        const deliveryDateStore =
            onHandStore?.next_delivery_date * 1000 > today ? onHandStore?.next_delivery_date : null;
        const deliveryDate =
            deliveryDateWeb !== null && (deliveryDateStore === null || deliveryDateWeb < deliveryDateStore)
                ? deliveryDateWeb
                : deliveryDateStore;

        if (!value) {
            isBookable = !!getFlag([PRODUCT_PREORDER, PRODUCT_BACKORDER], format?.flags);
            if (isBookable) {
                return compareWorst(result, { value, leadTime, deliveryDate, bookable: result.stock });
            }
        }

        return compareWorst(result, { value, leadTime, deliveryDate });
    }, stockWeb);

    stockStore = includedProducts.reduce((result, { on_hand_store: onHandStore }) => {
        const { value, leadtime_day_count: leadtime, next_delivery_date: deliveryDate } = onHandStore || {};

        return compareWorst(result, { value, leadtime, deliveryDate });
    }, stockStore);

    stockWeb.nextDelivery = checkNextDelivery(stockWeb.nextDelivery, locale);
    stockStore.nextDelivery = checkNextDelivery(stockStore.nextDelivery, locale);

    return [stockWeb, stockStore];
};

export const handleOnHandData = (onHand: AlgoliaOnHand | APIOnHand | APIOnHand[]) => {
    let stock: number | null = 0;
    let leadTime: null | number = null;
    let nextDelivery: null | number | string = null;
    const today = new Date().getTime() / 1000;

    const locations = onHand;

    for (const key in locations) {
        const warehouse = locations[key];

        stock += warehouse.value;

        const warehouseLeadTime = warehouse.leadtime_day_count || 0;

        if (warehouse.value > 0) {
            if (leadTime === null) {
                leadTime = warehouseLeadTime;
            } else {
                leadTime = warehouseLeadTime < leadTime ? warehouseLeadTime : leadTime;
            }
        }

        const warehouseDelivery = Math.floor(warehouse.next_delivery_date);
        const currentDelivery = Math.floor(nextDelivery);
        if (warehouseDelivery && warehouseDelivery > today) {
            if (!nextDelivery) {
                nextDelivery = warehouseDelivery;
            } else if (warehouseDelivery < currentDelivery) {
                nextDelivery = warehouseDelivery;
            }
        }
    }

    return {
        value: stock && stock > 0 ? stock : 0,
        'leadtime_day_count': leadTime,
        'next_delivery_date': nextDelivery,
    };
};

// @todo: @timmie why cant we use "as" keyword when I moved this file to core-web? // Adam
const getProductStockStatus = (onHand: AlgoliaOnHand | APIOnHand | APIOnHand[], locale = 'sv_SE') => {
    let stock: number | null = 0;
    let leadTime: null | number = null;
    let nextDelivery: null | number | string = null;
    const today = new Date().getTime() / 1000;

    if (Array.isArray(onHand)) {
        return getProductStockStatus(mergeOnHands(onHand));
    }

    // data from API
    if (onHand.hasOwnProperty('value')) {
        // const data = onHand as APIOnHand;
        const data = onHand;
        stock = data.value;
        leadTime = data.leadtime_day_count;
        nextDelivery = data.next_delivery_date;
    } else {
        // data from algolia.
        // const locations = onHand as AlgoliaOnHand;
        const locations = onHand;

        for (const key in locations) {
            const warehouse = locations[key];

            stock += warehouse.stock;

            if (warehouse.stock > 0 && warehouse.lead_days !== null) {
                if (leadTime === null) {
                    leadTime = warehouse.lead_days;
                } else {
                    leadTime = warehouse.lead_days < leadTime ? warehouse.lead_days : leadTime;
                }
            }

            const warehouseDelivery = Math.floor(warehouse.next_delivery);
            const currentDelivery = Math.floor(nextDelivery);
            if (warehouse.next_delivery && warehouseDelivery > today) {
                if (!nextDelivery) {
                    nextDelivery = warehouseDelivery;
                } else if (warehouseDelivery < currentDelivery) {
                    nextDelivery = warehouseDelivery;
                }
            }
        }
    }

    nextDelivery = checkNextDelivery(nextDelivery, locale);

    return {
        stock: stock && stock > 0 ? stock : 0,
        leadTime,
        nextDelivery,
    };
};

export const checkAndRetrieveStockStatus = (
    onHand: APIOnHand,
    onHandStore: APIOnHand,
    includedProducts: IncludedProducts[],
    selectedProductVariants: SelectedProductVariants | IncludedProducts[],
    onlyPickUpInStore = true
) => {
    let stockStatus, stockStatusStore;

    if ((includedProducts || []).length) {
        // package product
        const packageProducts =
            Object.values(selectedProductVariants).some(p => !p) || !includedProducts
                ? includedProducts
                : Object.values(selectedProductVariants);

        [stockStatus, stockStatusStore] = getPackageStockStatus(packageProducts);
    } else {
        // non-package
        stockStatus = getProductStockStatus([onHand, onHandStore]);
        stockStatusStore = getProductStockStatus(onHandStore);
    }

    // If product is available for purchase online through a store it is "in stock", except if it has the flag for "only in store"
    if (!onlyPickUpInStore && stockStatus.stock === 0 && stockStatusStore.stock > 0) {
        stockStatus.stock = stockStatusStore.stock;
    }

    return { stockStatus, stockStatusStore };
};

export default getProductStockStatus;
