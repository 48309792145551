import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { injectModel } from 'core-web/state';
import { getModuleTypeOnShowVat } from 'core-web/util/getModuleTypeOnShowVat';
import ErrorCatcher from 'core-web/components/ErrorCatcher';
import styled from 'theme/libs/styled';
import { above } from './util/mediaqueries';

const MODULE_NAME_KEY = 'layout';

const PaddingBox = styled('div')`
    &[data-apply-padding='true'] {
        ${above.lg} {
            padding: 0px 16px;
        }
    }
`;

const availableContentModules = {
    // Add the content modules that exists in core-web/views directly in here.
    // We don't want to dynamically import them as that would case circular references.
};

try {
    const themeContentModules = require('theme/content-modules').default;
    for (const i in themeContentModules) {
        // Override the existing one if it exists.
        availableContentModules[i] = themeContentModules[i];
    }
} catch (e) {
    // Do nothing.
}

const ContentModule = injectModel('products')(({ data, products: { showVat }, ...rest }) => {
    const showModule = getModuleTypeOnShowVat(data?.data, showVat);
    const appName = process.env.REACT_APP_NAME || '';

    const applyPaddingOn = [
        {
            type: 'manufacturer',
            template: 'module_template',
        },
        {
            type: 'product_category',
            template: 'module_template',
        },
    ];

    const pageType = useSelector(({ page }) => page.type);
    const pageTemplate = useSelector(({ page }) => page.template);

    const shouldApplyPadding = !!applyPaddingOn.find(p => p.template === pageTemplate && p.type === pageType);

    const type = data[MODULE_NAME_KEY];
    const moduleName = (data && type) || null;
    if (!showModule) {
        return null;
    }

    if (moduleName) {
        const ContentModule = availableContentModules[moduleName] || null;

        if (ContentModule) {
            return (
                <ErrorCatcher>
                    <PaddingBox
                        data-apply-padding={appName !== 'hylte' && moduleName !== 'product_grid' && shouldApplyPadding}
                    >
                        <ContentModule content={data.data} {...rest} />
                    </PaddingBox>
                </ErrorCatcher>
            );
        }

        console.error(`ContentModule ${type} not found.`);

        return null;
    }

    console.error(`Module ${type} not found.`);

    return null;
});

ContentModule.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    products: PropTypes.shape({
        showVat: PropTypes.bool,
    }),
};

const ContentModules = ({ modules, ...rest }) => {
    if (!modules) {
        return null;
    }

    return modules.map((module, index) => <ContentModule key={index} data={module} {...rest} />);
};

ContentModules.propTypes = {
    modules: PropTypes.array.isRequired,
};

export { ContentModule, ContentModules };
