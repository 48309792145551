import GTM from '@grebban/gtm';
import decodeHTMLEntities from 'core-web/util/decodeHTMLEntities';
import getPageProductCategories from 'core-web/util/getPageProductCategories';
import { getUUID } from 'core-web/util/getUUID';
import tracking from 'core-web/components/Tracking';

export default async data => {
    const { application, page } = data;

    const algoliaUserToken = getUUID();

    const pageId = page.data.id;
    const pageTitle = page.data.title ? page.data.title : page.data.default_name ? page.data.default_name : '404';

    const salesforceId = tracking.getSalesforceTrackingKey();

    const categoryIds = await getPageProductCategories(page);

    GTM.addDataLayerEvent('page_view', {
        algoliaUserToken,
        currency: application.currency.code,
        // site_id: application.applicationId,
        'page_id': pageId || '',
        'page_title': pageTitle,
        'page_title_decoded': decodeHTMLEntities(pageTitle),
        'page_type': page.type,
        'page_template': page.template,
        subscriberID: salesforceId ? salesforceId : '',
        categoryIds: categoryIds.join(','),
    });
};
