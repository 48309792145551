import React, { Component } from 'react';
import { keyframes } from 'react-emotion';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { injectModel } from 'core-web/state';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';

const DURATION = 200;

const animateIn = keyframes`
    0%   { opacity: 0; }
    100% { opacity: 1; }
`;

const animateOut = keyframes`
    0%   { opacity: 1; }
    100% { opacity: 0; }
`;

const Overlay = styled('div')`
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.lightGrey};

    &.animate-in {
        animation: ${animateIn} ${DURATION}ms ease;
    }

    &.animate-out {
        animation: ${animateOut} ${DURATION}ms ease forwards;
    }
`;

class PageTransitionOverlay extends Component {
    state = {
        animate: null,
        isInitialFetch: true,
    };

    static propTypes = {
        location: PropTypes.object.isRequired,
        page: PropTypes.object.isRequired,
    };

    static getDerivedStateFromProps(props, state) {
        const animate = !(props.location.state && props.location.state.pageTransition === false);

        if (props.page.isFetching && animate && state.isInitialFetch) {
            return { isInitialFetch: false };
        }

        if (!state.isInitialFetch && animate) {
            if (props.page.isFetching && state.animate === null) {
                return { animate: 'in' };
            }

            if (!props.page.isFetching && state.animate === 'loading') {
                return { animate: 'out' };
            }
        }

        return state;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.animate !== this.state.animate) {
            return true;
        }

        return nextProps.page.isFetching !== this.props.page.isFetching;
    }

    handleAnimationEnd = () => {
        if (!this.props.page.isFetching && this.state.animate === 'in') {
            this.setState({ animate: 'out' });
        }

        if (this.props.page.isFetching && this.state.animate === 'in') {
            this.setState({ animate: 'loading' });
        }

        if (this.state.animate === 'out') {
            this.setState({ animate: null });
        }
    };

    render() {
        const { animate } = this.state;

        if (!animate) {
            return null;
        }

        return <Overlay className={`animate-${animate}`} onAnimationEnd={this.handleAnimationEnd} />;
    }
}

export default withRouter(injectModel('page')(PageTransitionOverlay));
