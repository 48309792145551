import React from 'react';
import PropTypes from 'prop-types';

const PromisesStateContext = React.createContext();
const PromisesDispatchContext = React.createContext();

const promisesReducer = (state, action) => {
    // 5 min in milliseconds
    const time = 100 * 60 * 5;

    switch (action.type) {
        case 'promise':
            const newPromises = {};

            if (state.promises) {
                Object.entries(state.promises).forEach(([key, values]) => {
                    if (action.timestamp - values.timestamp < time || values.path === action.path) {
                        newPromises[key] = values;
                    }
                });
            }

            return {
                ...state,
                promises: {
                    ...newPromises,
                    [action.uuid]: {
                        promise: action.promise,
                        timestamp: action.timestamp,
                        path: action.path,
                    },
                },
            };
        case 'response':
            const newResponses = {};

            if (state.promises) {
                Object.entries(state.responses).forEach(([key, values]) => {
                    if (action.timestamp - values.timestamp < time || values.path === action.path) {
                        newResponses[key] = values;
                    }
                });
            }

            return {
                ...state,
                responses: {
                    ...newResponses,
                    [action.uuid]: {
                        response: action.response,
                        timestamp: action.timestamp,
                        path: action.path,
                    },
                },
            };
        case 'error':
            throw new Error(action.error);
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

const usePromisesState = () => {
    const context = React.useContext(PromisesStateContext);
    if (context === undefined) {
        throw new Error('usePromisesState must be used within a PromisesProvider');
    }
    return context;
};

const usePromisesDispatch = () => {
    const context = React.useContext(PromisesDispatchContext);
    if (context === undefined) {
        throw new Error('usePromisesDispatch must be used within a PromisesProvider');
    }
    return context;
};

const usePromises = () => {
    const state = usePromisesState();
    const dispatch = usePromisesDispatch();
    return [state, dispatch];
};

const PromisesProvider = ({ children, value, callback }) => {
    const [state, dispatch] = React.useReducer(promisesReducer, { ...value, callback });
    return (
        <PromisesStateContext.Provider value={state}>
            <PromisesDispatchContext.Provider value={dispatch}>{children}</PromisesDispatchContext.Provider>
        </PromisesStateContext.Provider>
    );
};

PromisesProvider.propTypes = {
    callback: PropTypes.func,
    children: PropTypes.node.isRequired,
    value: PropTypes.object.isRequired,
};

PromisesProvider.defaultProps = {
    callback: null,
};

const PromisesConsumer = ({ children }) => {
    const [state, dispatch] = usePromises();
    return children({ state, dispatch });
};

const addPromise = async (uuid, promise, path) => {
    const [state, dispatch] = usePromises();
    const { callback } = state;

    const timestamp = Date.now();

    dispatch({
        type: 'promise',
        promise,
        uuid,
        timestamp,
        path,
    });

    // this callback we use in ssr for feeding ssr with data
    callback &&
        callback('promises', {
            [uuid]: {
                promise,
                timestamp,
                path,
            },
        });

    try {
        const response = await promise;

        dispatch({
            type: 'response',
            response,
            uuid,
            timestamp,
            path,
        });
    } catch (e) {
        dispatch({ type: 'error', error: e });
    }
};

export { usePromises, PromisesProvider, PromisesConsumer, addPromise };
