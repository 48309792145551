import { SALE_PRODUCT_NO, SALE_PRODUCT_CODE } from 'theme/config/constants';

interface Info {
    code: string;
    value: string;
}

interface Item {
    price: number;
    part_no: string;
    info: Info[];
}

export default (items: Item[]) => {
    const saleProduct = items.find(i => i.part_no === SALE_PRODUCT_NO);

    if (!saleProduct) {
        return;
    }

    const values = saleProduct.info.find(i => i.code === SALE_PRODUCT_CODE);

    if (!values) {
        return;
    }

    return {
        ...JSON.parse(values.value),
        totalDiscount: saleProduct.price,
    };
};
