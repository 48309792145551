const uniqueArrayValues = (array: (string | number)[]): (string | number)[] => {
    const length = array.length;
    const seen = {};
    const unique: (string | number)[] = [];
    for (let i = 0; i < length; i++) {
        const item = array[i];
        if (seen[item] !== 1) {
            seen[item] = 1;
            unique.push(item);
        }
    }
    return unique;
};

export { uniqueArrayValues };
