import { connect } from 'react-redux';
import models from './models';
import store from './store';

const _inject = (model_name, state) => {
    const props = state[model_name];

    for (const i in models[model_name]) {
        if (models[model_name].hasOwnProperty(i)) {
            props[i] = models[model_name][i];
        }
    }

    return props;
};

export const getModel = model_name => {
    if (!models[model_name]) {
        return null;
    }

    return _inject(model_name, store.getState());
};

export const injectModel = model_name => {
    return Component => {
        const mapStateToProps = (state, ownProps) => {
            const props = {};

            props[model_name] = _inject(model_name, state);

            return props;
        };

        return connect(mapStateToProps)(Component);
    };
};

export const injectModels = model_names => {
    return Component => {
        const mapStateToProps = (state, ownProps) => {
            const props = {};
            model_names.forEach(model_name => {
                props[model_name] = _inject(model_name, state);
            });

            return props;
        };

        return connect(mapStateToProps)(Component);
    };
};

export const getState = (model = null) => {
    const state = store.getState();

    if (model && state[model]) {
        return state[model];
    }

    return state;
};

const locks = {};

export const acquireMutex = mutex => {
    if (typeof mutex === 'string') {
        // It's a single lock.
        let mutexResolver = null;
        const mutexPromise = new Promise(resolve => {
            mutexResolver = resolve;
        });

        if (!locks[mutex]) {
            locks[mutex] = [];
        }

        locks[mutex].push(mutexPromise);

        if (locks[mutex].length > 1) {
            // Mutex exist.
            return locks[mutex][locks[mutex].length - 2].then(() => mutexResolver);
        }

        return mutexResolver;
    } else if (typeof mutex === 'object' && locks.length && locks.length > 0) {
        // It's an array.
        // @todo: Implement multiple mutexes.
    }
};
