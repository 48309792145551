import { get } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async (basketID, returnDeliveryMethods = false, returnDeliveryPoints = false) => {
    return await get(`/frontend/checkout/id/${basketID}`, {
        application_id: getModel('application').applicationId,
        return_delivery_methods: returnDeliveryMethods,
        return_delivery_points: returnDeliveryPoints,
    });
};
