export const getLanguages = (languages = [], currentLocale) => {
    const productionEnabledLocales = process.env.REACT_APP_PRODUCTION_ENABLED_LOCALES || '';
    const currentSiteIsLive = productionEnabledLocales.indexOf(currentLocale) !== -1;

    if (!currentSiteIsLive) {
        return languages;
    }

    return languages.filter(item => productionEnabledLocales.indexOf(item.locale) !== -1);
};

export const formatDomain = uri => {
    if (uri.lastIndexOf('/') === uri.length - 1) {
        return uri.slice(0, uri.length - 1);
    }

    return uri;
};

export const getGenericLink = (locale, languages) => {
    let uri;
    uri = languages?.find(i => i.locale === locale)?.uri;

    if (!uri) {
        return '';
    }

    if (uri?.toLowerCase().indexOf('//') === 0) {
        uri = `https:${uri}`;
    }
    return uri;
};

export const getLinkWithSlug = (locale, hreflangs, languages) => {
    let uri;
    const formattedLocale = locale.split('_').join('').toLowerCase();
    const localeExistsAsHref = hreflangs.some(i => i.locale === locale || i.locale === formattedLocale);

    if (!localeExistsAsHref || (languages && languages.locale === locale)) {
        uri = getGenericLink(locale, languages);
    } else {
        const language = hreflangs.find(i => i.locale === locale || i.locale === formattedLocale);

        uri = language.uri;

        if (!uri) {
            return '';
        }
        if (uri.toLowerCase().indexOf('//') === 0) {
            uri = `https:${uri}`;
        }
    }

    return uri;
};

export const getHreflangs = (alternateHrefs, languages = [], currentPage) => {
    if (!alternateHrefs) {
        return null;
    }

    return alternateHrefs
        .map(item => {
            let language;
            if (currentPage.type === 'product' || !item.locale) {
                language = languages.find(l => l.id === item.application_id);

                if (!language) {
                    return null;
                }

                return {
                    ...item,
                    uri: `${formatDomain(language.uri)}${item.slug}`,
                    id: item.external_id,
                    locale: language.locale,
                };
            }

            language = languages.find(l => l.locale.substr(0, 2) === item.locale.substr(0, 2));

            if (!language) {
                return item;
            }

            return {
                ...item,
                uri: `${formatDomain(language.uri)}${item.permalink}`,
                id: item.post_id,
            };
        })
        .filter(i => i);
};

const SHOULD_FORMAT_POSTALCODE_COUNTRIES = ['Sverige', 'Sweden'];
// Postalcode formatting
// SE: 123 45
// NO & DK: 1234
// FI: 12345
export const formatPostalCodes = (postalCode, country) => {
    if (!postalCode.length) {
        return '';
    } else if (SHOULD_FORMAT_POSTALCODE_COUNTRIES.includes(country)) {
        return postalCode.substring(0, 3) + ' ' + postalCode.substring(3);
    }
    return postalCode;
};

export const countryToIsoMap = {
    'Sweden': 'SE',
    'Sverige': 'SE',
    'Norway': 'NO',
    'Norge': 'NO',
    'Denmark': 'DK',
    'Danmark': 'DK',
    'Finland': 'FI',
    'Suomi': 'FI',
    'Åland': 'AX',
    'Åland Islands': 'AX',
};
