import React from 'react';
import { Global, css } from '@emotion/core';
import { MediaProvider } from 'react-media-universal';
import Site from 'theme/components/Site';
import { fontString } from 'theme/assets/fonts';
import { resetStyle } from 'theme/config/reset';
import { baseStyle } from 'theme/config/baseStyle';
import sanitize from 'sanitize.css';

const App = () => {
    return (
        <>
            <Global
                styles={css`
                    ${sanitize}
                    ${fontString}
                    ${resetStyle}
                    ${baseStyle}
                `}
            />
            <MediaProvider>
                <Site />
            </MediaProvider>
        </>
    );
};

export default App;
