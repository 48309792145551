import Cookies from 'js-cookie';
import { getFlag } from 'core-web/util/getFlag';
import {
    FREIGHT_PRODUCT_TYPE,
    MOLNUS_SIM_CODE,
    MOLNUS_SIM_TYPES,
    SALE_PRODUCT_NO,
    SIM_CODE,
    SIM_TYPES,
} from 'theme/config/constants';

// @TODO THIS FILE SHOULD BE REMOVED AND THIS FUNCTIONALITY SHOULD BE ADDED INTO THE REDUX, RIGHT NOW IT IS BEING RE-RENDERED SEVERAL TIMES WHICH IS NOT GOOD.

export default (basketItems, setCookie = true, shouldOverwriteTextComments = true, basket = {}) => {
    const tree = [];
    const basketItemsCopy = JSON.parse(JSON.stringify(basketItems));
    basketItemsCopy
        .filter(item => item.type !== FREIGHT_PRODUCT_TYPE && item.part_no !== SALE_PRODUCT_NO)
        .forEach(item => {
            item.isSim = SIM_TYPES.indexOf(item.type) !== -1 && !!getFlag(SIM_CODE, item?.format?.flags);
            item.isMolnusSim =
                MOLNUS_SIM_TYPES.indexOf(item.type) !== -1 && !!getFlag(MOLNUS_SIM_CODE, item?.format?.flags);

            if (!item.sub_products) {
                item.sub_products = [];
            }

            // Build sub_products (package items)
            if (item.parent_line_no && item.applied_promotions.length === 0) {
                basketItemsCopy.forEach(parent => {
                    if (parent.line_no === item.parent_line_no) {
                        if (!parent.sub_products) {
                            parent.sub_products = [];
                        }

                        if (parent.sub_products.indexOf(item) === -1) {
                            parent.sub_products.push(item);
                        }
                    }
                });
            } else if (item.parent_line_no) {
                const parentItem = tree.find(basketItem => basketItem.line_no === item.parent_line_no);
                tree.splice(tree.indexOf(parentItem) + 1, 0, item);
            } else {
                const oldItem = basket?.items?.find(old => old.line_no === item.line_no);
                const oldTextComment = !!item?.format.text_comment?.length
                    ? item.format.text_comment
                    : oldItem?.format.text_comment;
                tree.push(
                    shouldOverwriteTextComments && !oldTextComment
                        ? item
                        : { ...item, format: { ...item.format, text_comment: oldTextComment || [] } }
                );
            }
        });

    const basketItemsCount = tree.reduce((total, item) => total + (item.quantity || 0), 0);
    if (setCookie) {
        Cookies.set('basket_items_amount', basketItemsCount, { expires: 30 });
    }

    return tree;
};
