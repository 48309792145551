import Cookies from 'js-cookie';
import Events from 'core-web/libs/Events';
import { CustomerEvents } from 'core-web/libs/Events/constants';
import DeleteBasketItem from 'core-web/libs/GrebbCommerceAPI/Basket/DeleteBasketItem';
import GetStormPrices from 'core-web/libs/GrebbCommerceAPI/Products/GetStormPrices';
import { getState } from 'core-web/state';
import productsState from 'core-web/state/models/products';
import getCustomerSalesAuth from 'core-web/util/getCustomerSalesAuth';
import { SALE_PRODUCT_NO } from 'theme/config/constants';
import {
    LOGOUT,
    LOGOUT_SUCCESS,
    SET_INIT_USER_SUCCESS,
    SET_USER,
    SET_USER_SUCCESS,
    UPDATE_CUSTOMER,
    UPDATE_CUSTOMER_SUCCESS,
} from './constants';

// TODO: make util?
const formatPricelistSeed = (ids = []) => {
    if (!ids || ids.length === 0) {
        return '';
    }

    ids = ids
        .filter((v, i) => ids.indexOf(v) === i)
        .sort((a, b) => a * 1 - b * 1)
        .join(',');

    return ids;
};

export const initCustomer = () => dispatch => {
    dispatch({ type: SET_INIT_USER_SUCCESS });
};

export const setCustomer = customer => (dispatch, getState) => {
    if (!customer) {
        return;
    }

    const { showVat } = getState().products;
    const { toggleVat } = productsState;

    const cookieCompany = Cookies.get('companyId');
    const cookieCustomer = Cookies.get('customerId');

    dispatch({ type: SET_USER });

    const {
        account: { roles },
        code,
        companies,
        email,
        first_name: firstName,
        id,
        last_name: lastName,
        pricelist_ids: pricelistIds,
    } = customer;

    const isCustomerSalesAuth = getCustomerSalesAuth(roles);
    const customerCompanies = companies || [];
    let selectedCompany = {};
    let pricelistSeed = '';

    if (customerCompanies.length) {
        if (!cookieCompany && !isCustomerSalesAuth) {
            selectedCompany = companies[0];
        } else if (cookieCompany && cookieCompany !== 'none') {
            selectedCompany = customerCompanies.find(c => c.id.toString() === cookieCompany) || {};
        }

        if (selectedCompany.id && cookieCompany !== selectedCompany.id) {
            Cookies.set('companyId', selectedCompany.id, { expires: 30 });
        }

        pricelistSeed = formatPricelistSeed([...pricelistIds, ...(selectedCompany['pricelist_ids'] || [])]);
    } else {
        if (cookieCompany && cookieCompany !== 'none') {
            Cookies.set('companyId', 'none', { expires: 30 });
        }

        pricelistSeed = formatPricelistSeed(pricelistIds);
    }

    if (selectedCompany.id && showVat) {
        toggleVat();
    } else if (customerCompanies.length > 0 && !selectedCompany.id && !showVat) {
        toggleVat();
    }

    const customerId = cookieCustomer || (isCustomerSalesAuth ? '' : id);

    if (!isCustomerSalesAuth && !cookieCustomer) {
        Cookies.set('customerId', customerId, { expires: 30 });
    }

    const customerData = {
        auth: true,
        code,
        companies: customerCompanies,
        companyId: selectedCompany.id,
        customerId,
        displayName: firstName ? `${firstName} ${lastName || ''}` : email,
        email,
        firstName,
        id,
        lastName,
        pricelistIds,
        pricelistSeed,
        roles,
    };

    dispatch({
        type: SET_USER_SUCCESS,
        ...customerData,
    });

    Events.trigger(CustomerEvents.UPDATED, { customer: customerData, event: CustomerEvents.UPDATED });

    return customerData;
};

export const logout = () => async dispatch => {
    dispatch({ type: LOGOUT, isFetching: true });

    Cookies.remove('companyId');
    Cookies.remove('customerId');

    const basket = getState('basket');

    if (basket.basketId) {
        const apiBasket = await basket.resetBasketBuyer(basket.basketId);

        if (SALE_PRODUCT_NO && apiBasket) {
            const saleItem = apiBasket.items?.find(item => item.part_no === SALE_PRODUCT_NO);

            if (saleItem) {
                await DeleteBasketItem(basket.basketId, saleItem.line_no);
                await basket.getBasket();
            }
        }
    }

    // @todo: should we update basket items after removal of discount

    dispatch({ type: LOGOUT_SUCCESS, isFetching: false });
};

export const updateCustomer =
    (companyId, customerId = '') =>
    async dispatch => {
        const { auth, companies, pricelistIds, ...customer } = getState('customer');
        const { showVat, toggleVat } = getState('products');
        const isCustomerSalesAuth = getCustomerSalesAuth();

        if (!isCustomerSalesAuth) {
            customerId = customer.customerId;
        }

        if (!customerId) {
            companyId = '';
        }

        dispatch({ type: UPDATE_CUSTOMER });

        const selectedCompany = companies.find(company => parseInt(company.id, 10) === parseInt(companyId, 10)) || {};

        const pricelistSeed = formatPricelistSeed([...pricelistIds, ...(selectedCompany['pricelist_ids'] || [])]);

        Cookies.set('customerId', customerId, { expires: 30 });
        Cookies.set('companyId', companyId || 'none', { expires: 30 });

        if (selectedCompany.id && showVat) {
            toggleVat();
        } else if (!selectedCompany.id && !showVat) {
            toggleVat();
        }

        Events.trigger(CustomerEvents.COMPANY_UPDATED, {
            customer: { ...customer, pricelistSeed, customerId, companyId },
            event: CustomerEvents.COMPANY_UPDATED,
        });

        dispatch({ type: UPDATE_CUSTOMER_SUCCESS, pricelistSeed, companyId, customerId });
    };

export const getProductPrices = products => async dispatch => {
    const customer = getState('customer');

    const { pricelistSeed } = customer;
    let allProducts = products;

    if (pricelistSeed) {
        const partNos = products.map(product => product.objectID);

        try {
            const response = await GetStormPrices(partNos, pricelistSeed);

            if (response?.data) {
                allProducts = products.map(product => {
                    const pricelistProduct = response.data.find(resProduct => resProduct.part_no === product.objectID);

                    if (!pricelistProduct) {
                        return product;
                    }

                    return {
                        ...product,
                        pricing: {
                            ...product.pricing,
                            pricelists: {
                                web: {
                                    exVat: pricelistProduct.price,
                                    incVat: pricelistProduct.price * pricelistProduct.vat_rate,
                                },
                            },
                            recommended: {
                                exVat: pricelistProduct.price_recommended,
                                incVat: pricelistProduct.price_recommended * pricelistProduct.vat_rate,
                            },
                            historical: {
                                exVat: pricelistProduct.best_historical_price.best_history_price,
                                incVat:
                                    pricelistProduct.best_historical_price.best_history_price *
                                    pricelistProduct.vat_rate,
                            },
                        },
                    };
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    return allProducts;
};
