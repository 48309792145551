import PropTypes from 'prop-types';
import useLazyLoadScript from 'core-web/hooks/useLazyScriptLoad';

const LazyLoadScript = ({ on, options, src }) => {
    useLazyLoadScript(src, on, options);

    return null;
};

LazyLoadScript.propTypes = {
    on: PropTypes.oneOf(['load', 'scroll', 'timeout']).isRequired,
    options: PropTypes.shape({
        preload: PropTypes.bool,
        scrollOffset: PropTypes.number,
        timeout: PropTypes.number,
    }),
    src: PropTypes.string.isRequired,
};

export default LazyLoadScript;
