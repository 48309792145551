import Cookies from 'js-cookie';

// Strictly Necessary Cookies: "C0001"
// Performance Cookies: "C0002"
// Functional Cookies: "C0003"
// Targeting Cookies: "C0004"
// Social Media Cookies: "C0005"
const checkScriptConsent = groups => ({
    necessaryConsent: true,
    ad_storage: groups.includes('C0004'),
    analytics_storage: groups.includes('C0002'),
    functional_storage: groups.includes('C0003'),
    personalization_storage: groups.includes('C0004'),
    ad_user_data: groups.includes('C0004'),
    ad_personalization: groups.includes('C0004'),
});

const consentCategories = groups => ({
    performanceConsent: groups.includes('C0002'),
    functionalStorage: groups.includes('C0003'),
    targetingConsent: groups.includes('C0004'),
});

const getConsentGroups = () => {
    const ONETRUST_HYMA_COOKIE = process.env.REACT_APP_ONETRUST_HYMA_COOKIE;
    const oneTrustCookie = Cookies.get(ONETRUST_HYMA_COOKIE || '');

    let consentGroups = oneTrustCookie || [];
    if (!oneTrustCookie) {
        consentGroups = window?.OnetrustActiveGroups?.split(',') || [];
    }

    return consentGroups;
};

export const getConsentCategories = () => {
    const consentGroups = getConsentGroups();

    return consentCategories(consentGroups);
};

export const getOneTrustConsent = () => {
    const consentGroups = getConsentGroups();

    return checkScriptConsent(consentGroups);
};
