import semiBoldEot from './600.eot';
import semiBoldWoff from './600.woff';
import semiBoldWoff2 from './600.woff2';

export default {
    semiBold: {
        eot: semiBoldEot,
        woff: semiBoldWoff,
        woff2: semiBoldWoff2,
    },
};
