import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GetProductPriceQtyBreak, GetProductRelations } from 'core-web/libs/GrebbCommerceAPI/Products';
import { buildQueryString } from 'core-web/libs/grebbcommerce-api/util';
import { injectModels } from 'core-web/state';
import { getProductsByObjectId, getSparePartsByObjectId } from 'core-web/state/models/products/actions';
import get from 'core-web/util/get';
import getQueryParams from 'core-web/util/getQueryParams';
import getReplaceProduct from 'core-web/util/getReplaceProduct';
import objectIsEmpty from 'core-web/util/objectIsEmpty';
import { ProductMeta } from 'core-web/components/Meta';
import { inServer } from 'core-web/constants';
import {
    ACCESSORIES,
    LICENSE_CODE,
    MOLNUS_SIM_CODE,
    MOLNUS_SIM_TYPES,
    PACKAGE_TYPE_ID,
    PRODUCT_BACKORDER,
    PRODUCT_EXPIRED,
    PRODUCT_OUT_FOR_SEASON,
    PRODUCT_PAGE_TIPS,
    PRODUCT_PREORDER,
    PRODUCT_REPLACED_PARAMETRIC,
    RELATED_PACKAGES,
    SIM_CODE,
    SIM_TYPES,
    STORM_PACKAGE_TYPES,
} from 'theme/config/constants';

const initialState = {
    isLicense: false,
    isPackage: false,
    isSim: false,
    isMolnusSim: false,
    product: null,
    qtyBreaks: [],
    quantity: 1,
    relatedAccessoriesIds: [],
    relatedPackageIds: [],
    relatedProductIds: [],
    relatedUpsellIds: [],
    relatedUpsells: [],
    relations: [], // 1000305981, 1000305979, 1000305972, 1000289009
    selectedProduct: null,
    selectedProductVariants: {},
    selectedPromotionVariants: {},
    selectedUpsells: {},
    simNumber: '',
    molnusSimNumber: '',
};

export const getFlag = (code, product = {}) => {
    const findFlag = flag => {
        if (Array.isArray(code)) {
            return code.some(c => c.toLowerCase() === flag.code?.toLowerCase());
        }

        return flag.code?.toLowerCase() === code?.toLowerCase();
    };
    return get(product, 'format.flags', []).find(findFlag) || false;
};

export const getParametric = (code, product = {}) => {
    const parametrics = product.parametrics || [];
    const variantParametrics = product.variant_parametrics || [];

    const findParametric = parametric => {
        if (Array.isArray(code)) {
            return code.indexOf(parametric.code) !== -1 && parametric?.value?.length > 0;
        }
        return parametric.value?.length > 0 && parametric.code === code;
    };

    return parametrics.find(findParametric) || variantParametrics.find(findParametric);
};

const queryParamsIE = obj => {
    const ownProps = Object.keys(obj);
    let i = ownProps.length;
    const resArray = new Array(i);
    while (i--) {
        resArray[i] = [ownProps[i], obj[ownProps[i]]];
    }
    const filterOutProductVariant = resArray.filter(item => !item.includes('p'));
    return filterOutProductVariant.map(item => `${item[0]}=${item[1]}`).join('&');
};

const isLicenseProduct = product => getFlag(LICENSE_CODE, product);
const isPackageProduct = product => STORM_PACKAGE_TYPES.indexOf(product.type) !== -1;
const isMolnusSimProduct = product =>
    !!(MOLNUS_SIM_TYPES.indexOf(product.type) !== -1 && getFlag(MOLNUS_SIM_CODE, product));
const isSimProduct = product => !!(SIM_TYPES.indexOf(product.type) !== -1 && getFlag(SIM_CODE, product));
const isVariantProduct = product => (product.variants || []).length > 0;

class ProductPageContainer extends Component {
    static propTypes = {
        loading: PropTypes.func,
        overlay: PropTypes.object.isRequired,
        page: PropTypes.object.isRequired,
        render: PropTypes.func.isRequired,
        shouldFetchRelations: PropTypes.bool,
    };

    static defaultProps = {
        loading: null,
        shouldFetchRelations: true,
    };

    state = this.resetState(get(this.props.page, 'data.product', null));
    componentDidMount() {
        this.getProductRelations();
        this.getQtyBreaks();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const product = get(nextProps, 'page.data.product', null);

        if (JSON.stringify(nextState) !== JSON.stringify(this.state)) {
            return true;
        }

        if (
            (product || {}).part_no !== (this.state.product || {}).part_no ||
            (product || {}).price !== (this.state.product || {}).price ||
            (product || {}).price_list_id !== (this.state.product || {}).price_list_id
        ) {
            this.setState(this.resetState(product), () => {
                this.getProductRelations();
                this.getQtyBreaks();
            });
        }

        return false;
    }

    componentWillUnmount() {
        const { overlay } = this.props;
        if (overlay.current === 'gallery_zoom_overlay') {
            overlay.hide();
        }
    }

    resetState(product) {
        const newState = { ...initialState };
        const search = this.props.page.search;
        const query = getQueryParams({ search });
        if (!product) {
            return newState;
        }

        const hasPartNo = product.variants.some(p => p.part_no === query.p);

        // Remove queryParam from URL if  query.id doesnt exists in product.variants
        if (!hasPartNo && !inServer) {
            const location = window.location;
            let queryParams = '';
            if (!objectIsEmpty(query)) {
                if (query.hasOwnProperty('p')) {
                    delete query['p'];
                }
                queryParams = `?${buildQueryString(query)}`;
            }
            window.history.replaceState(null, null, `${location.pathname}${queryParams}`);
        }

        const isLicense = isLicenseProduct(product);
        const isPackage = isPackageProduct(product);
        const isSim = isSimProduct(product);
        const isMolnusSim = isMolnusSimProduct(product);
        const isVariant = isVariantProduct(product);

        const selectedProductVariants = {};
        if (isPackage && (product.included_products || []).length) {
            product.included_products.forEach(subProduct => {
                if ((subProduct.variants || []).length) {
                    selectedProductVariants[subProduct.id] = null;
                } else {
                    selectedProductVariants[subProduct.id] = subProduct;
                }
            });
        } else if (isMolnusSim || isSim || (isVariant && !hasPartNo)) {
            selectedProductVariants[product.id] = null;
        } else if (isVariant && hasPartNo) {
            const selectedVariant = product.variants.find(p => p.part_no === product.part_no);
            selectedProductVariants[product.id] = selectedVariant;
        } else {
            selectedProductVariants[product.id] = product;
        }

        const selectedVariant = selectedProductVariants[product.id] || {};
        const selectedProduct = this.getSelectedProductData(selectedVariant, product);

        return {
            ...newState,
            isLicense,
            isPackage,
            isSim,
            isMolnusSim,
            product,
            quantity: product.recommended_quantity ? product.recommended_quantity : 1,
            selectedProduct,
            selectedProductVariants,
        };
    }

    getSelectedProductData(selectedVariant, product) {
        if (!inServer && product.variants.length && selectedVariant.part_no) {
            const isIE = !!(typeof document !== 'undefined' && document.documentMode);
            const search = this.props.page.search;
            const query = getQueryParams({ search });
            let queryParams = '';

            if (!isIE) {
                for (const key in query) {
                    if (query.hasOwnProperty(key) && key !== 'p') {
                        queryParams += `&${key}=${query[key]}`;
                    }
                }
            } else if (isIE) {
                queryParams += `&${queryParamsIE(query)}}`;
            }

            const location = window.location;
            window.history.replaceState(null, null, `${location.pathname}?p=${selectedVariant.part_no}${queryParams}`);
        }

        const selectedProduct = Object.keys(selectedVariant || {}).length ? selectedVariant : product;
        const isPackage = isPackageProduct(product);
        const isSim = isSimProduct(product);
        const isMolnusSim = isMolnusSimProduct(product);
        const isVariant = isVariantProduct(product);
        const productImages = product.files || [];
        const variantImages = isVariant ? selectedVariant.files || [] : [];

        const packageImages = isPackage
            ? (product.included_products || []).reduce((images, incProduct) => {
                  if (incProduct.format?.image) {
                      return [
                          ...images,
                          {
                              name: incProduct.name,
                              format: incProduct.format,
                              type: 1,
                              metaTags: incProduct.meta_tags || incProduct.name,
                          },
                      ];
                  }
                  return images;
              }, [])
            : [];
        const files = [...productImages, ...variantImages, ...packageImages];

        // Check if product is replaced
        const replacedParametric = getParametric(PRODUCT_REPLACED_PARAMETRIC, selectedProduct);
        const replacedById = replacedParametric ? replacedParametric.value : null;

        this.getReplacedByProduct(replacedById);

        // fake onHand for SIM products
        const simOnHand = {
            value: 99999,
            'leadtime_day_count': null,
            'next_delivery_date': null,
        };

        const hasPreOrderFlag = !!getFlag(PRODUCT_PREORDER, selectedProduct);
        const hasBackOrderFlag = !!getFlag(PRODUCT_BACKORDER, selectedProduct);
        const isBookable = hasPreOrderFlag || hasBackOrderFlag;

        return {
            recommended_quantity: selectedVariant?.recommended_quantity ?? null,
            quantity: selectedVariant?.recommended_quantity ? selectedVariant.recommended_quantity : 1,
            categories: selectedVariant.categories || product.categories || [],
            description: selectedVariant.description || product.description,
            subDescription: selectedVariant?.sub_description || product?.sub_description,
            eanCode: selectedVariant.ean_code || product.ean_code,
            files,
            format: selectedVariant.format || product.format || {},
            hasBackOrderFlag,
            hasPreOrderFlag,
            isBookable,
            metaTags: selectedVariant?.meta_tags,
            isExpired: !!getFlag(PRODUCT_EXPIRED, selectedProduct) && !isBookable,
            isOutForSeason: !!getFlag(PRODUCT_OUT_FOR_SEASON, selectedProduct) && !isBookable,
            manufacturer: selectedVariant.manufacturer || product.manufacturer,
            onHand: isSim || isMolnusSim ? simOnHand : selectedVariant.on_hand || product.on_hand,
            onHandStore: selectedVariant.on_hand_store || product.on_hand_store,
            parametrics: !isPackage
                ? product.parametrics
                : (product.included_products || [])
                      .map(p => ({ name: p.name, parametrics: p.parametrics, metaTags: p.meta_tags }))
                      .filter(p => p.parametrics.length),
            partNo: selectedProduct.part_no,
            manufacturerPartNo: selectedProduct.manufacturer?.part_no || product.manufacturer?.part_no,
            replacedById: isBookable ? null : replacedById,
            priceExVat: selectedProduct.price || product.price,
            historicalPriceExVat: selectedProduct.best_historical_price || product.best_historical_price,
            recommendedPriceExVat: selectedProduct.price_recommended || product.price_recommended,
            vatRate: selectedProduct.vat_rate || product.vat_rate,
            currentVariantParametrics: selectedProduct.parametrics,
            'variant_parametrics': !isPackage
                ? product.variant_parametrics
                : (product.included_products || [])
                      .map(p => ({ name: p.name, 'variant_parametrics': p.variant_parametrics }))
                      .filter(p => p.variant_parametrics.length),
        };
    }

    // ---------- GETTERS

    // @todo: We only do this because storm has a bug and we won't get category (we need category to build product url) name from relations.
    // What we would like to do is fetch all realtions and use storm data instead.
    // Now we fetch relations, map out all ids and fetch again to algolia.
    // TLDR; When storm gives us category name from relations, use this data and remove every fetch in Related* component.
    getProductRelations = async () => {
        const productId = this.state.product && this.state.product.id;

        if (productId && this.props.shouldFetchRelations) {
            try {
                const related = await GetProductRelations(productId, { 'as_variants': true });
                const relations = [...related.data];

                const relatedAccessories = relations.filter(({ code }) => ACCESSORIES.indexOf(code) !== -1);

                const relatedAccessoriesIds = get(relatedAccessories, '0.relations.items', []).map(
                    item => item.part_no
                );

                const upsellRelation = relations.filter(({ code }) => code === 'du_rec_accessories');

                const relatedUpsells = get(upsellRelation, '0.relations.items', []);
                const relatedUpsellIds = relatedUpsells.map(item => item.part_no);

                const relatedProducts = relations.filter(({ code }) => PRODUCT_PAGE_TIPS.indexOf(code) !== -1);

                const relatedPackageIds = [];
                const relatedProductIds = [];

                get(relatedProducts, '0.relations.items', []).forEach(({ type, part_no: partNo }) => {
                    if (type === PACKAGE_TYPE_ID) {
                        relatedPackageIds.push(partNo);
                    } else {
                        relatedProductIds.push(partNo);
                    }
                });
                const relevantPackages =
                    relations.filter(({ code }) => RELATED_PACKAGES.indexOf(code) !== -1)[0]?.relations?.items || [];

                const relevantPackagesIds = relevantPackages.map(p => p.part_no);

                if (relations) {
                    this.setState({
                        relatedAccessoriesIds,
                        relatedPackageIds,
                        relatedProductIds,
                        relatedUpsellIds,
                        relatedUpsells,
                        relations,
                        relevantPackagesIds,
                    });
                }
            } catch (e) {
                console.error(e);
            }
        }
    };

    async getReplacedByProduct(id) {
        let replacedByProduct = null;
        const appName = process.env.REACT_APP_NAME || '';

        if (id) {
            if (appName === 'duab') {
                const [sparePartProduct, product] = await Promise.all([
                    getSparePartsByObjectId([id]),
                    getProductsByObjectId([id]),
                ]);
                if (product.results.filter(Boolean).length > 0) {
                    replacedByProduct = product.results[0];
                } else if (sparePartProduct.results.filter(Boolean).length > 0) {
                    replacedByProduct = sparePartProduct.results[0];
                } else {
                    replacedByProduct = null;
                }
            } else {
                const [product1, product2] = await Promise.all([
                    getReplaceProduct(id, 'spare_parts'),
                    getReplaceProduct(id, 'products'),
                ]);
                replacedByProduct = product1 || product2;
            }
        }

        this.setState({ replacedByProduct });
    }

    getPricingOfProducts = products => {
        const { quantity = 1 } = this.state;

        const price = products.reduce(
            (price, mainProduct) => {
                if (mainProduct) {
                    let product = mainProduct;

                    if (!!product.variants?.length) {
                        product = mainProduct.variants
                            .filter(pVariant => pVariant.format.flags.every(flag => flag.code !== PRODUCT_EXPIRED))
                            .reduce(function (prev, curr) {
                                return prev.price < curr.price ? prev : curr;
                            }, {});

                        if (!Object.keys(product).length) {
                            product = mainProduct;
                        }
                    }
                    // We place a fallback to quantity "1" if it does not exist.
                    const productQuantity = product.quantity || 1;

                    const bestHistoricalPrice =
                        typeof product?.best_historical_price === 'object'
                            ? product?.best_historical_price?.best_history_price
                            : product?.best_historical_price || 0;

                    return {
                        ...price,
                        priceExVat: price.priceExVat + product.price * productQuantity,
                        priceIncVat: price.priceIncVat + product?.price_inc_vat * productQuantity,
                        recommendedPriceExVat:
                            price.recommendedPriceExVat + product.price_recommended * productQuantity,
                        recommendedPriceIncVat:
                            price.recommendedPriceIncVat +
                            product.price_recommended * product.vat_rate * productQuantity,
                        historicalPriceExVat: price.historicalPriceExVat + bestHistoricalPrice * productQuantity,
                        historicalPriceIncVat:
                            price.historicalPriceIncVat + bestHistoricalPrice * product.vat_rate * productQuantity,
                    };
                }

                return price;
            },
            {
                priceIncVat: 0,
                priceExVat: 0,
                recommendedPriceExVat: 0,
                recommendedPriceIncVat: 0,
                historicalPriceExVat: 0,
                historicalPriceIncVat: 0,
                vatRate: 0,
                quantityOfPackage: quantity > 0 ? quantity : 1,
            }
        );

        price.vatRate = price.priceIncVat / price.priceExVat;

        return price;
    };

    getStockStatus = () => {
        const { isPackage, isSim, isMolnusSim, product, selectedProductVariants } = this.state;

        if (isSim || isMolnusSim) {
            return true;
        }

        const mainProduct = selectedProductVariants[product.id] || product;
        const mainProductStock = mainProduct['on_hand']?.value + mainProduct['on_hand_store']?.value;

        if (isPackage) {
            let allIncludedProductsHaveStock = true;

            product.included_products.forEach(incProduct => {
                const selectedPackageVariant = selectedProductVariants[incProduct.id] || incProduct;
                const onHandSum =
                    selectedPackageVariant['on_hand']?.value + selectedPackageVariant['on_hand_store']?.value;

                const incProductStock = Math.floor(onHandSum / selectedPackageVariant.quantity);
                if (incProductStock < 1) {
                    allIncludedProductsHaveStock = false;
                }
            });

            return allIncludedProductsHaveStock;
        }
        return mainProductStock > 0;
    };

    getQtyBreaks = async () => {
        const { product } = this.state;

        if (product && product.id) {
            try {
                const response = await GetProductPriceQtyBreak(product.id);
                if (response.data && response.data.length > 1) {
                    const filteredResponse = response.data.filter(
                        qtyProduct => qtyProduct.part_no === product.part_no && qtyProduct.qty_break > 1
                    );

                    this.setState({
                        qtyBreaks: filteredResponse,
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    // ---------- CALLBACKS

    setQuantity = value => {
        let quantity = value;

        if (typeof quantity === 'string') {
            quantity = +value;
        } else if (isNaN(quantity) || quantity === null || quantity === undefined) {
            quantity = 1;
        }

        this.setState({ quantity });
    };

    setSimNumber = (number, valid, isVariant = null) => {
        const { selectedProductVariants, isSim, product } = this.state;
        if (isSim) {
            if (valid && !isVariant) {
                selectedProductVariants[product.id] = product;
            } else if (!isVariant) {
                selectedProductVariants[product.id] = null;
            }
            this.setState({
                simNumber: number,
                selectedProductVariants: { ...selectedProductVariants },
            });
        }
    };

    setMolnusSimNumber = (number, valid, isVariant = null) => {
        const { selectedProductVariants, isMolnusSim, product } = this.state;
        if (isMolnusSim) {
            if (valid && !isVariant) {
                selectedProductVariants[product.id] = product;
            } else if (!isVariant) {
                selectedProductVariants[product.id] = null;
            }
            this.setState({
                molnusSimNumber: number,
                selectedProductVariants: { ...selectedProductVariants },
            });
        }
    };

    setVariant = (pId, pVariant, promotion = false) => {
        const stateKey = promotion ? 'selectedPromotionVariants' : 'selectedProductVariants';
        // Get the current selectedProductVariants from the state (as a new object)
        const selectedVariants = { ...this.state[stateKey] };

        // If the productId is not the same as the new productVariant we update the state
        if (selectedVariants[pId] !== pVariant) {
            if (promotion && pVariant === undefined) {
                delete selectedVariants[pId];
            } else {
                selectedVariants[pId] = pVariant;
            }

            const newState = { [stateKey]: selectedVariants };

            // if new productVariant is set, update selectedProduct
            if (pId === this.state.product.id) {
                const selectedProductData = this.getSelectedProductData(pVariant || {}, this.state.product);
                newState['selectedProduct'] = selectedProductData;
                newState['quantity'] = selectedProductData.quantity;
            }

            this.setState(newState);
        }
    };

    // @todo: See getProductRelation comment. But what we want to do is to skip find and get product as argument.
    setUpsells = objectId => {
        const selectedUpsells = { ...this.state.selectedUpsells };

        if (selectedUpsells[objectId]) {
            delete selectedUpsells[objectId];
        } else {
            const product = this.state.relatedUpsells.find(p => p.part_no === objectId);

            if (product) {
                selectedUpsells[objectId] = product;
            }
        }

        this.setState({ selectedUpsells });
    };

    render() {
        const {
            isLicense,
            isPackage,
            isSim,
            isMolnusSim,
            product,
            qtyBreaks,
            quantity,
            relatedAccessoriesIds,
            relatedPackageIds,
            relatedProductIds,
            relatedUpsellIds,
            relevantPackagesIds,
            replacedByProduct,
            selectedProduct,
            selectedProductVariants,
            selectedPromotionVariants,
            selectedUpsells,
            simNumber,
            molnusSimNumber,
        } = this.state;

        const { loading: Loading, page, render: Render } = this.props;

        if (!product && Loading) {
            return <Loading />;
        }

        let totalPricing = {};

        if (isPackage) {
            totalPricing = this.getPricingOfProducts([product, ...Object.values(selectedPromotionVariants)]);
        } else if (!selectedProductVariants[product.id]) {
            totalPricing = this.getPricingOfProducts([product, ...Object.values(selectedPromotionVariants)]);
        } else {
            totalPricing = this.getPricingOfProducts([
                ...Object.values(selectedProductVariants),
                ...Object.values(selectedPromotionVariants),
                ...Object.values(selectedUpsells),
            ]);
        }

        const isInStock = !!this.getStockStatus();
        const isExpired =
            !isInStock && (selectedProduct.isExpired || (selectedProduct.replacedById && !replacedByProduct));

        return (
            <>
                <ProductMeta
                    isInStock={isInStock}
                    product={product}
                    type={page.type}
                    selectedVariant={selectedProductVariants[product.id]}
                />
                <Render
                    isBookable={!isInStock && selectedProduct.isBookable}
                    isExpired={isExpired}
                    isInStock={isInStock}
                    isOutForSeason={!isInStock && selectedProduct.isOutForSeason}
                    isPackage={isPackage}
                    isSim={isSim}
                    isMolnusSim={isMolnusSim}
                    isVariant={isVariantProduct(product)}
                    licenseFlag={isLicense || null}
                    pageData={{
                        alternates: page.data?.alternates,
                        defaultName: page.data?.default_name,
                        defaultUniqueUrlName: page.data?.default_unique_url_name,
                        id: page.data?.id,
                        partNo: page.data?.part_no,
                        variantPartNos: page.data?.variant_part_nos,
                    }}
                    product={product}
                    qtyBreaks={qtyBreaks}
                    quantity={quantity}
                    relatedAccessoriesIds={relatedAccessoriesIds}
                    relatedPackageIds={relatedPackageIds}
                    relatedProductIds={relatedProductIds}
                    relatedUpsellIds={relatedUpsellIds}
                    replacedByProduct={isInStock ? null : replacedByProduct}
                    relevantPackagesIds={relevantPackagesIds}
                    selectedProduct={selectedProduct}
                    selectedProductVariants={selectedProductVariants}
                    selectedPromotionVariants={selectedPromotionVariants}
                    selectedUpsells={selectedUpsells}
                    simNumber={simNumber}
                    molnusSimNumber={molnusSimNumber}
                    totalPricing={totalPricing}
                    onQuantityChange={this.setQuantity}
                    onSimChange={this.setSimNumber}
                    onMolnusSimChange={this.setMolnusSimNumber}
                    onUpsellChange={this.setUpsells}
                    onVariantChange={this.setVariant}
                />
            </>
        );
    }
}

export default injectModels(['overlay', 'page'])(ProductPageContainer);
