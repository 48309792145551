import { post } from 'core-web/libs/grebbcommerce-api/util/authClient';
import { getModel } from 'core-web/state';
import { auth } from 'core-web/util/firebase';

interface Item {
    id: string;
    percentage?: string;
    price?: string;
}

interface Data {
    percentage?: string;
    price?: string;
    items?: Item[];
}

export default async (basketId: string, data: Data) => {
    const token = await auth.currentUser.getIdToken();

    const response = await post(
        `/frontend/secret/${basketId}/update`,
        {
            'application_id': getModel('application').applicationId,
            ...data,
        },
        { 'Authorization': `Bearer ${token}` }
    );

    return await response.body();
};
