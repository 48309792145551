import store from 'core-web/state/store';
import {
    resolveApp,
    setCurrentHref,
    setCurrentPathname,
    setOrigin,
    setPathBeforeStore,
    setProtocol,
    setState,
} from './actions';

const getApplicationSlug = () => {
    const { application } = store.getState();
    return (application && application.path) || null;
};

export default {
    setPathBeforeStore: pathBeforeStore => {
        store.dispatch(setPathBeforeStore(pathBeforeStore));
    },
    getPathBeforeStore: () => {
        return store.getState().application.pathBeforeStore;
    },
    getOrigin: () => {
        let returnValue = '';
        if (typeof window !== 'undefined') {
            returnValue = window.location.origin;
        } else {
            returnValue = store.getState().application.origin;
        }
        return returnValue;
    },
    setOrigin: origin => {
        store.dispatch(setOrigin(origin));
    },
    setProtocol: protocol => {
        store.dispatch(setProtocol(protocol));
    },
    getCurrentHref: () => {
        let returnValue = '';
        if (typeof window !== 'undefined') {
            returnValue = window.location.href;
        } else {
            returnValue = store.getState().application.currentHref;
        }
        return returnValue;
    },
    setCurrentHref: href => {
        store.dispatch(setCurrentHref(href));
    },
    getCurrentPathname: () => {
        let returnValue = '';
        if (typeof window !== 'undefined') {
            returnValue = window.location.pathname;
        } else {
            returnValue = store.getState().application.currentPathname;
        }
        return returnValue;
    },
    setCurrentPathname: pathname => {
        store.dispatch(setCurrentPathname(pathname));
    },
    resolveApp: (url = '', parameters = {}, origin) => {
        return store.dispatch(resolveApp(url, parameters, origin));
    },
    excludeApplicationSlug: url => {
        const applicationSlug = getApplicationSlug();
        if (applicationSlug) {
            // This is based on the assumption that the application path
            // is ending in a slash, like / or /no/.
            return url.replace(applicationSlug, '/');
        }
        return url;
    },
    includeApplicationSlug: url => {
        const applicationSlug = getApplicationSlug();
        if (applicationSlug) {
            // This is based on the assumption that the application path
            // is ending in a slash, like / or /no/.
            const firstSlash = url.indexOf('/');
            if (firstSlash === 0) {
                return applicationSlug + url.substring(1);
            }
        }
        return url;
    },
    containsApplicationSlug: url => {
        const applicationSlug = getApplicationSlug();

        return applicationSlug !== null && url.indexOf(applicationSlug) === 0;
    },

    getApplicationSlug,

    getQuickMenu: () => {
        return store.getState().application.config.header.quick_menu;
    },

    getUrlQuery: (url = null) => {
        let fallbackQuery = null;
        if (typeof window === 'undefined') {
            const windowUrl = store.getState().application.currentHref;
            const questionIndex = windowUrl.indexOf('?');
            if (questionIndex !== -1) {
                fallbackQuery = windowUrl.slice(questionIndex + 1);
            }
        } else {
            fallbackQuery = window.location.search.slice(1);
        }
        // get query string from url (optional) or window
        let queryString = url ? url.split('?')[1] : fallbackQuery;
        const obj = {};

        // if query string exists
        if (queryString) {
            queryString = queryString.split('#')[0];
            const arr = queryString.split('&');

            const replaceFunction = v => {
                const paramNum = v;
                paramNum.slice(1, -1);
                return '';
            };

            for (let i = 0; i < arr.length; i++) {
                const a = arr[i].split('=');
                const paramNum = undefined;
                const paramName = a[0].replace(/\[\d*\]/, replaceFunction);

                const paramValue = typeof a[1] === 'undefined' ? true : a[1];

                if (obj[paramName]) {
                    if (typeof obj[paramName] === 'string') {
                        obj[paramName] = [obj[paramName]];
                    }
                    if (typeof paramNum === 'undefined') {
                        obj[paramName].push(paramValue);
                    } else {
                        obj[paramName][paramNum] = paramValue;
                    }
                } else {
                    obj[paramName] = paramValue;
                }
            }
        }

        return obj;
    },
    setState: state => {
        return store.dispatch(setState(state));
    },
};
