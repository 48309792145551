import GTM from '@grebban/gtm';
import { getState } from 'core-web/state';
import chunkArrayInGroups from 'core-web/util/chunkArrayInGroups';
import tracking from 'core-web/components/Tracking';
import { getImpression } from './utils/impression';

export default ({ products, list, offset }) => {
    const isProductCategoryPage = getState('page').type === 'product_category';
    const salesforceId = tracking.getSalesforceTrackingKey();

    const impressions = (products || [])
        .filter(i => i)
        .map((item, index) => {
            if (list) {
                item.list = list;
            }
            const itemPosition = index + (offset || 0);
            return getImpression(item, itemPosition);
        });

    // chunk products array into groups of 24 to not exceed maximum payload size
    const newProducts = chunkArrayInGroups(impressions, 24);

    newProducts.forEach(impressions => {
        GTM.addDataLayerEvent('impressions', {
            ecommerce: {
                impressions,
            },
            subscriberID: salesforceId && isProductCategoryPage ? salesforceId : '',
        });
    });
};
