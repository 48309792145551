export const getModuleTypeOnShowVat = (content, showVat) => {
    const moduleDisplaySetting = content?.activation?.custom_module_display;
    if (!moduleDisplaySetting) {
        return true;
    }

    const isCustomer = moduleDisplaySetting === 'customer';
    const isCompany = moduleDisplaySetting === 'company';
    const isDefault = moduleDisplaySetting === 'default';

    let showModule = false;
    if (isCustomer && showVat) {
        showModule = true;
    }
    if (isCompany && !showVat) {
        showModule = true;
    }
    if (isDefault) {
        showModule = true;
    }
    return showModule;
};
