const views = {
    // Add the views that exists in core-web/views directly in here.
    // We don't want to dynamically import them as that would case circular references.
};

try {
    const themeViews = require('theme/views').default;
    for (const i in themeViews) {
        const currentView = themeViews[i];
        if (views[i] === undefined) {
            views[i] = currentView;
        }
    }
} catch (e) {
    // Do nothing.
}

export default views;
