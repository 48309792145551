export const getBasketInfo = (item, code) => {
    try {
        const info = item.info.find(info => info.code === code && info.value !== null);
        return info ? info.value : '';
    } catch (e) {
        return false;
    }
    // if(info) {
    //     const str = info.value.replace('"', '\"');
    //     let jsonParsedValue = JSON.parse(str);
    //     return info; // { original: xxx, sale: "xxx" }
    // } else {
    //     return false;
    // }
};

export const getBasketItemInfo = (item, code) => getBasketInfo(item, code);
