import Cookies from 'js-cookie';
import { GetByObjectsIds, GetSparePartsByObjectsIds, MultiQuery, Search } from 'core-web/libs/Algolia';
import Events from 'core-web/libs/Events';
import { ProductEvents } from 'core-web/libs/Events/constants';
import GetCategoriesTree from 'core-web/libs/GrebbCommerceAPI/Categories/GetCategoriesTree';
import { getModel } from 'core-web/state';
import contentCheck from 'core-web/util/contentCheck';
// for page dependencies
import convertSelectionToFilter from 'core-web/util/convertSelectionToFilter';
import getQueryParams from 'core-web/util/getQueryParams';
import { defaultProps as productsContainerDefaultProps, search as productsContainerSearch } from 'theme/containers/ProductsContainer/utils';
import { // Page dependencies
RESOLVE_PAGE_DEPENDENCIES, RESOLVE_PAGE_DEPENDENCIES_ERROR, RESOLVE_PAGE_DEPENDENCIES_SUCCESS, // Search.
SEARCH_PRODUCTS, SEARCH_PRODUCTS_ERROR, SEARCH_PRODUCTS_SUCCESS, // Set All Products
SET_ALL_PRODUCTS, SET_ALL_PRODUCTS_ERROR, SET_ALL_PRODUCTS_SUCCESS, // Set Category Tree
SET_CATEGORY_TREE, SET_CATEGORY_TREE_ERROR, SET_CATEGORY_TREE_SUCCESS, // Set Compare Products
SET_COMPARE_PRODUCTS, SET_COMPARE_PRODUCTS_ERROR, SET_COMPARE_PRODUCTS_SUCCESS, // Set Last Compare Product
SET_COMPARE_PRODUCT_LAST, SET_COMPARE_PRODUCT_LAST_ERROR, SET_COMPARE_PRODUCT_LAST_SUCCESS, SET_NEXT_FETCH_MODE, // VAT
SHOW_VAT } from './constants';


export const search =
    (locale, query, parameters = {}, paginationState = {}) =>
    async dispatch => {
        dispatch({ type: SEARCH_PRODUCTS });

        try {
            const response = await Search('products', query, parameters, `_${locale}`, true);
            if (response) {
                dispatch({
                    type: SEARCH_PRODUCTS_SUCCESS,
                    products: response.hits,
                    pagination: {
                        page: response.page || paginationState.page,
                        pageSize: response.pageSize || paginationState.pageSize,
                        pageCount: response.pageCount || paginationState.pageCount,
                        hitsCount: response.hitsCount,
                        hasMore: response.hasMore,
                    },
                });
                return response;
            }
            throw new Error('Something went wrong with the Algolia search.');
        } catch (e) {
            dispatch({ type: SEARCH_PRODUCTS_ERROR });
            throw e;
        }
    };

const FILTER_TYPES = {
    facet: 'facet',
    numeric: 'numeric',
    not: 'not',
    products: 'products',
};

const getOptionalProductString = products => {
    if (products.length === 0) {
        return '';
    }
    let definedProductsFilter = '(';
    products.forEach((product, index) => {
        if (index > 0) {
            definedProductsFilter = `${definedProductsFilter} OR `;
        }
        definedProductsFilter = `${definedProductsFilter}objectID: ${product}`;
    });
    definedProductsFilter = `${definedProductsFilter})`;
    return definedProductsFilter;
};

const getOptionalProductArray = products => {
    const result = [];
    products.forEach(product => {
        result.push(`objectID:${product}`);
    });
    return result;
};

const mapClause = (clause, optional = false) => {
    if (!clause) {
        return undefined;
    }

    const result = {
        attribute: clause.attribute,
        // type: clause.type
    };

    const statements = clause.values.map(value => `${clause.attribute}:'${value}'`);
    const minDefined = typeof clause.min === 'number' && !isNaN(clause.min);
    const maxDefined = typeof clause.max === 'number' && !isNaN(clause.max);

    switch (clause.type) {
        case FILTER_TYPES.facet:
            result.clause = `(${statements.join(' OR ')})`;
            break;
        case FILTER_TYPES.numeric:
            if (minDefined && maxDefined) {
                result.clause = `(${clause.attribute}:${clause.min} TO ${clause.max})`;
            } else if (minDefined) {
                result.clause = `(${clause.attribute} >= ${clause.min})`;
            } else if (maxDefined) {
                result.clause = `(${clause.attribute} <= ${clause.max})`;
            }
            break;
        case FILTER_TYPES.not:
            result.clause = `(NOT ${clause.attribute}: ${clause.value})`;
            break;
        case FILTER_TYPES.products:
            if (optional) {
                result.clause = getOptionalProductArray(clause.values);
            } else {
                result.clause = getOptionalProductString(clause.values);
            }
            break;
        default:
    }

    return result.clause && result;
};

const transformCMSQueryToAlgoliaQueries = ({
    query = '',
    filters = [],
    facetFilters = [],
    facets = [],
    facetNames = [],
    optionalFilters = [],
    sort = '',
    page = 0,
    hitsPerPage = 21,
}) => {
    const PREFIX = process.env.REACT_APP_ALGOLIA_PREFIX || '';

    // @todo: The _se part should not be in here. It's temporary.
    const getIndexName = (name, sort = null, suffix = null) =>
        `${PREFIX ? `${PREFIX}` : ''}${name}${suffix ? `_${suffix}` : ''}${sort ? `_${sort}` : ''}`;

    // @todo: What should happen if the index requested does not exist?
    const getIndex = (type, sort, suffix = null) => {
        if (!suffix) {
            const application = getModel('application');
            suffix = application.locale.toLowerCase();
        }
        if (sort && (sort === 'default' || sort[0] === 'default')) {
            sort = null;
        }

        // Build the index name.
        return getIndexName(type, sort, suffix);
    };

    // TODO: index names should be loaded dynamically
    const productsIndexName = getIndex('products', sort);
    const facetsIndexName = `${PREFIX}product_facets`;

    // Apply conditions
    const filteredFacets = facets.filter(
        facet => !facet.requiredFilter || facetFilters.some(filter => filter.attribute === facet.requiredFilter)
    );

    // We must use AND as the logical connective at the top level to conform with Algolia's filter standard
    const andSeparator = ' AND ';
    const orSeparator = ' OR ';

    // Map the filters to clauses for Algolia filter string
    const filterClauses = filters.map(mapClause).filter(f => f);

    const facetFilterClauses = facetFilters.map(mapClause).filter(f => f);

    // Start creating filter strings
    const filterString = filterClauses.map(c => c.clause).join(andSeparator);

    const mainFilterString = [filterString]
        .filter(f => f)
        .concat(facetFilterClauses.map(c => c.clause))
        .join(andSeparator);

    // Creating optionFilters
    const optionalFilterClauses = optionalFilters.map(filter => mapClause(filter, true)).filter(f => f);
    const optionalFiltersArray = optionalFilterClauses.map(c => c.clause);

    // Calculate facets for the main query
    const mainFacets = filteredFacets.map(facet => facet.attribute);
    // If we have facet filters we need to handle such facets in separate queries
    const facetQueries = facetFilterClauses.map((clause, i) => {
        // Get all clauses except the current one
        const otherClauses = facetFilterClauses.filter((_, j) => j !== i);

        const facetFilterString = [filterString]
            .concat(otherClauses.map(c => c.clause))
            .filter(x => x !== '')
            .join(andSeparator);

        return {
            indexName: productsIndexName,
            query,
            params: {
                facets: [clause.attribute],
                filters: facetFilterString,
                page,
                hitsPerPage,
            },
        };
    });

    const mainQuery = {
        indexName: productsIndexName,
        query,
        clickAnalytics: true,
        params: {
            facets: mainFacets,
            filters: mainFilterString,
            optionalFilters: optionalFiltersArray,
            page,
            hitsPerPage,
        },
    };

    let infoQueries = [];
    if (facets.length) {
        infoQueries = [
            {
                indexName: facetsIndexName,
                query: '',
                params: {
                    // todo language support
                    filters: `language:${getModel('application').languages[0].locale} AND ${facetNames
                        .map(facet => `parametric:${facet}`)
                        .join(orSeparator)}`,
                },
            },
        ];
    }

    return [mainQuery].concat(facetQueries).concat(infoQueries);
};

const mapFacets = (facets, facetNames) => {
    const data = {};
    if (facets && facets[0]) {
        Object.keys(facets[0].facets).forEach(index => {
            data[index] = {};
            data[index].values = facets[0].facets[index];
            if (facets[0].facets_stats && facets[0].facets_stats[index]) {
                data[index].stats = facets[0].facets_stats[index];
            }
        });
    }

    // Check if filter is active. Then use the active filter values
    if (Object.keys(facets).length > 1) {
        Object.keys(facets).forEach(index => {
            if (index > 0) {
                Object.keys(facets[index].facets).forEach(facetIndex => {
                    data[facetIndex] = {};
                    data[facetIndex].values = facets[index].facets[facetIndex];
                    if (facets[index].facets_stats && facets[index].facets_stats[facetIndex]) {
                        data[facetIndex].stats = facets[index].facets_stats[facetIndex];
                    }
                });
            }
        });
    }

    for (let i = 0; i < data.length; i++) {
        data &&
            Object.keys(data).forEach(key => {
                // if(data[key] === undefined) {
                //     data[key] = {};
                // }

                // override with client facets
                if (i > 0 && i < data.length - 1) {
                    data[key] = { ...data.facets[key] };
                }
            });
    }
    //
    Object.keys(data).forEach(key => {
        const application = getModel('application');

        // todo move config
        const config = {
            'sv_SE': {
                'pricing.pricelists.web.incVat': {
                    name: 'Pris',
                    description: 'description',
                },
                'pricing.pricelists.web.exVat': {
                    name: 'Pris',
                    description: 'description',
                },
                'categories.code': {
                    name: 'Categories',
                    description: 'description',
                },
            },
        };

        // set facetName
        const index = facetNames.find(facet => `parametrics.${facet.parametric}.value.value` === key);
        data[key].name = index ? index.name : config[application.languages[0].locale][key].name;
        data[key].description = index ? index.description : config[application.languages[0].locale][key].description;

        if (data[key].stats) {
            data[key].min = data[key].stats.min;
            data[key].max = data[key].stats.max;
            delete data[key].stats;
            delete data[key].values;
        }
    });
    return data;
};

export const getProductsWithFacets = (indexName, query) => async dispatch => {
    dispatch({ type: SEARCH_PRODUCTS });

    try {
        const algoliaQueries = transformCMSQueryToAlgoliaQueries(indexName, query);
        const results = await MultiQuery(algoliaQueries);
        if (results) {
            results.facets = mapFacets(results.facets, results.facetNames);
            dispatch({
                type: SEARCH_PRODUCTS_SUCCESS,
                products: results.products,
                pagination: {
                    page: results.pagination,
                    pageSize: results.page_size,
                    pageCount: results.pages,
                    hitsCount: results.product_amount,
                    hasMore: results.hasMore,
                },
            });
            return results;
        }
        throw new Error('Something went wrong with the Algolia search.');
    } catch (e) {
        dispatch({ type: SEARCH_PRODUCTS_ERROR });
        throw e;
    }
};

export const getCmsProductsQuery = algoliaQuery => {
    if (contentCheck(algoliaQuery.settings, 'products')) {
        if (algoliaQuery.products && algoliaQuery.products.length > 0) {
            return algoliaQuery.products.map(product => {
                return product.object_i_d;
            });
        }
    }
    return [];
};

const prepFilter = wpData => {
    return wpData
        ? wpData.map(data => {
              switch (data.layout) {
                  case 'product':
                      return {
                          type: 'facet',
                          attribute: `objectID`,
                          values: data.values.map(c => c.object_i_d),
                      };
                  case 'parametric':
                      return {
                          type: 'facet',
                          attribute: `parametrics.${data.parametric.code}.value.code`,
                          values: data.values.map(c => c.value),
                      };
                  case 'categories':
                      return {
                          type: 'facet',
                          attribute: 'categories.code',
                          values: data.categories.map(c => c.code),
                      };
                  case 'manufacturer':
                      return {
                          type: 'facet',
                          attribute: 'manufacturer.code',
                          values: data.manufacturers.map(c => c.code),
                      };
                  case 'numeric':
                      return {
                          type: 'numeric',
                          attribute: data.parameter,
                          max: parseFloat(data.max),
                          min: parseFloat(data.min),
                      };
                  default:
                      return false;
              }
          })
        : [];
};

const prepFacetFilter = wpData => {
    return wpData
        ? wpData.map(data => {
              switch (data.layout) {
                  case 'parametric':
                      return {
                          attribute: `parametrics.${data.parametric.code}.value.value`,
                      };
                  case 'numeric':
                      return {
                          attribute: data.parameter.value,
                      };
                  default:
                      return false;
              }
          })
        : [];
};

const prepFacetNames = wpData => {
    return wpData ? wpData.map(d => (d.parametric ? d.parametric.code : d.parameter.value)) : [];
};

export const getCmsFilterQuery = algoliaQuery => {
    const filter =
        contentCheck(algoliaQuery.settings, 'filters') && algoliaQuery.filter && algoliaQuery.filter.length > 0
            ? algoliaQuery.filter
            : algoliaQuery.products && algoliaQuery.products.length > 0
            ? [
                  {
                      layout: 'product',
                      values: algoliaQuery.products,
                  },
              ]
            : [];

    if (contentCheck(algoliaQuery.settings, 'filters')) {
        return prepFilter(filter);
    }

    return [];
};

export const getCmsFacetFilterQuery = filter => {
    if (filter) {
        return {
            facets: prepFacetFilter(filter),
            facetNames: prepFacetNames(filter),
        };
    }
    return {
        facets: [],
        facetNames: [],
    };
};

export const getCmsQuery = (cmsData, extra = {}) => {
    const filter =
        contentCheck(cmsData.algolia_query.settings, 'filters') &&
        cmsData.algolia_query.filter &&
        cmsData.algolia_query.filter.length > 0
            ? cmsData.algolia_query.filter
            : cmsData.algolia_query.products && cmsData.algolia_query.products.length > 0
            ? [
                  {
                      layout: 'product',
                      values: cmsData.algolia_query.products,
                  },
              ]
            : [];
    const query = {
        filters: prepFilter(filter),
        facetFilters: [], // Client filter
        facets: [], // all facets values
        facetNames: prepFacetNames(cmsData.filter),
        sort: cmsData.algolia_query.sort,
        page: 0,
        // hitsPerPage: 21
    };

    if (cmsData.limit) {
        query.hitsPerPage = cmsData.limit;
    }

    if (
        contentCheck(cmsData.algolia_query.settings, 'filters') &&
        cmsData.algolia_query.products &&
        cmsData.algolia_query.products.length > 0
    ) {
        query.optionalFilters = prepFilter([
            {
                layout: 'product',
                values: cmsData.algolia_query.products,
            },
        ]);
    }

    if (cmsData.filter) {
        query.facets = prepFacetFilter(cmsData.filter);
    }

    Object.keys(extra).forEach(key => {
        query[key] = extra[key];
    });

    return query;
};

const setCompareProductsCookie = compareProducts => {
    Cookies.set('compare_products', JSON.stringify(compareProducts), {
        expires: 28,
    });
};

const clearCompareProductsCookie = () => {
    Cookies.remove('compare_products');
};

const updateLastCompareProduct = lastCompareProduct => dispatch => {
    dispatch({ type: SET_COMPARE_PRODUCT_LAST });
    try {
        dispatch({
            type: SET_COMPARE_PRODUCT_LAST_SUCCESS,
            lastCompareProduct: { product: lastCompareProduct, time: new Date() },
        });

        return true;
    } catch (e) {
        dispatch({ type: SET_COMPARE_PRODUCT_LAST_ERROR });
        throw e;
    }
};

export const updateCompareProducts = compareProducts => dispatch => {
    dispatch({ type: SET_COMPARE_PRODUCTS });

    try {
        dispatch({ type: SET_COMPARE_PRODUCTS_SUCCESS, compareProducts });

        return true;
    } catch (e) {
        dispatch({ type: SET_COMPARE_PRODUCTS_ERROR });

        throw e;
    }
};

export const addCompareProduct = objectId => (dispatch, getState) => {
    dispatch(updateLastCompareProduct(objectId));

    const compareProducts = [...getState().products.compareProducts];

    // const compareProductsCopy = [...compareProducts];

    if (compareProducts.includes(objectId)) {
        // If product exist in compare first we remove it and then we re-add it
        // This is needed to trigger the add to compare notification
        // compareProducts.splice(compareProducts.indexOf(objectId), 1);
        // dispatch(updateCompareProducts(compareProducts));

        return false;
    }

    const newCompareProducts = [...compareProducts, objectId];

    setCompareProductsCookie(newCompareProducts);
    dispatch(updateCompareProducts(newCompareProducts));
};

export const removeCompareProduct = objectId => (dispatch, getState) => {
    const compareProducts = getState().products.compareProducts;

    if (!compareProducts.includes(objectId)) {
        return false;
    }

    dispatch(updateLastCompareProduct(objectId));

    compareProducts.splice(compareProducts.indexOf(objectId), 1);

    if (compareProducts.length === 0) {
        clearCompareProductsCookie();
    } else {
        setCompareProductsCookie(compareProducts);
    }

    dispatch(updateCompareProducts(compareProducts));
};

export const getCompareProducts = () => async (dispatch, getState) => {
    const compareProducts = getState().products.compareProducts;

    return await GetByObjectsIds(compareProducts).catch(error => {
        console.error(error);
        return false;
    });
};

export const removeAllComparedProducts = () => (dispatch, getState) => {
    const compareProducts = getState().products.compareProducts;

    if (compareProducts.length === 0) {
        return null;
    }

    clearCompareProductsCookie();
    dispatch(updateCompareProducts([]));
    dispatch(updateLastCompareProduct(null));
};

export const getProductsByObjectId = async filters => {
    return await GetByObjectsIds(filters).catch(error => {
        console.error(error);
    });
};

export const getSparePartsByObjectId = async filters => {
    return await GetSparePartsByObjectsIds(filters).catch(error => {
        console.error(error);
    });
};

export const setVat = () => (dispatch, getState) => {
    const showVat = Cookies.get('show_vat') ? Cookies.get('show_vat') === 'true' : true;
    const currentVatState = getState().products.showVat;

    if (showVat !== currentVatState) {
        dispatch({
            type: SHOW_VAT,
            showVat,
        });

        Events.trigger(ProductEvents.TOGGLE_VAT, showVat);
    }
};

export const toggleVat = () => (dispatch, getState) => {
    try {
        const showVat = !getState().products.showVat;
        Cookies.set('show_vat', showVat, { expires: 365 });

        dispatch({
            type: SHOW_VAT,
            showVat,
        });

        Events.trigger(ProductEvents.TOGGLE_VAT);

        return true;
    } catch (e) {
        console.error(e);
    }
};

export const setShowVat = showVat => dispatch => {
    try {
        Cookies.set('show_vat', showVat, { expires: 365 });

        dispatch({ type: SHOW_VAT, showVat });
    } catch (e) {
        console.error(e);
    }
};

const resolveProductCategoryDependencies = async (data, location, getState) => {
    try {
        const gridData = data.data.page_content?.find(page => page.layout === 'product_grid');
        if (!gridData) {
            return {};
        }

        const { product_selection: productSelection } = gridData.data;
        const filters = convertSelectionToFilter(productSelection);
        const application = getState().application;
        const query = getQueryParams(location);
        const pagination = query.page ? query.page : productsContainerDefaultProps.pagination;

        const searchProps = {
            ...productsContainerDefaultProps,
            application,
            filters: {
                default: filters,
                selected: [],
            },
        };

        const searchState = {
            promise: null,
            hitCount: null,
            pageSize: productsContainerDefaultProps.pageSize,
            pagination,
        };

        const algoliaSearchResult = await productsContainerSearch(searchProps, searchState);

        return { 'product_selection': algoliaSearchResult };
    } catch (e) {
        console.error(e);
        throw e;
    }
};

const categoryTreeCleanup = categoryTree => {
    return categoryTree.map(category => {
        const { code, id, level, title } = category;
        let { children } = category;

        if (children?.length) {
            children = categoryTreeCleanup(children);
        }

        return {
            children,
            code,
            id,
            level,
            title,
        };
    });
};

export const setCategoryTree =
    (withDispatch = true, applicationId, url) =>
    async (dispatch, getState) => {
        const currentState = getState().products.categoryTree;
        if (currentState?.length) {
            return currentState;
        }
        if (withDispatch) {
            dispatch({ type: SET_CATEGORY_TREE });
        }

        try {
            if (!applicationId) {
                applicationId = getState().application.applicationId;
            }
            const response = await GetCategoriesTree(applicationId, url);

            let categoryTree = [];
            if (response?.data?.length) {
                categoryTree = categoryTreeCleanup(response.data);
            }

            if (withDispatch) {
                dispatch({ type: SET_CATEGORY_TREE_SUCCESS, categoryTree });
            }

            return categoryTree;
        } catch (e) {
            if (withDispatch) {
                dispatch({ type: SET_CATEGORY_TREE_ERROR });
            }
        }
    };

export const resolvePageDependencies = (pageData, location, applicationId, url) => async (dispatch, getState) => {
    try {
        dispatch({ type: RESOLVE_PAGE_DEPENDENCIES });

        let data = {};
        let categoryTree = [];

        if (pageData.type === 'product_category') {
            data = await resolveProductCategoryDependencies(pageData, location, getState);
        } else if (pageData.type === 'manufacturer' || pageData.template === 'search') {
            data = await resolveProductCategoryDependencies(pageData, location, getState);
            categoryTree = await setCategoryTree(false, applicationId, url)(dispatch, getState);
        }

        dispatch({
            type: RESOLVE_PAGE_DEPENDENCIES_SUCCESS,
            data,
            categoryTree,
        });
    } catch (e) {
        dispatch({ type: RESOLVE_PAGE_DEPENDENCIES_ERROR });
        throw e;
    }
};

export const setAllProducts = products => dispatch => {
    dispatch({ type: SET_ALL_PRODUCTS });

    try {
        dispatch({ type: SET_ALL_PRODUCTS_SUCCESS, products });
    } catch (e) {
        dispatch({ type: SET_ALL_PRODUCTS_ERROR });
    }
};

// option can be either add or replace
export const setNextFetchMode = mode => dispatch => {
    dispatch({ type: SET_NEXT_FETCH_MODE, mode });
};