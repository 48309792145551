import store from 'core-web/state/store';
import { show, hide } from './actions';

export default {
    show: (overlay, data = {}, wrapper = null) => {
        store.dispatch(show(overlay, data, wrapper));
    },

    hide: () => {
        store.dispatch(hide());
    },
};
