import lightEot from './300.eot';
import lightWoff from './300.woff';
import lightWoff2 from './300.woff2';
import regularEot from './400.eot';
import regularWoff from './400.woff';
import regularWoff2 from './400.woff2';
import boldEot from './700.eot';
import boldWoff from './700.woff';
import boldWoff2 from './700.woff2';

export default {
    light: {
        eot: lightEot,
        woff: lightWoff,
        woff2: lightWoff2,
    },
    regular: {
        eot: regularEot,
        woff: regularWoff,
        woff2: regularWoff2,
    },
    bold: {
        eot: boldEot,
        woff: boldWoff,
        woff2: boldWoff2,
    },
};
