const objectIsEmpty = obj => {
    // If IE - Object.entries does not exist
    if (!Object.entries) {
        const ownProps = Object.keys(obj);
        let i = ownProps.length;
        const resArray = new Array(i);

        while (i--) {
            resArray[i] = [ownProps[i], obj[ownProps[i]]];
        }
        if (resArray.length) {
            return false;
        }
    } else {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
    }

    return true;
};

export default objectIsEmpty;
