import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import decodeHTMLEntities from 'core-web/util/decodeHTMLEntities';
import { getAvailabilitySchemaUrl, getProductAvailability } from 'core-web/util/getProductAvailability';
import { getFlag, getParametric } from 'core-web/containers/ProductPageContainer';
import {
    APP_ORGANIZATION,
    HY_INT_FRAKT_FRI,
    HY_SE_FRAKT_FRI,
    INTERNATIONAL_APP_ORGANIZATION,
} from 'theme/config/constants';

const ProductStructuredData = ({
    brand,
    breadcrumbsData,
    currency,
    description,
    ean,
    hasDecimalPrice,
    images,
    isInternationalMarket,
    mpn,
    name,
    price,
    product,
    productAvailability,
    sku,
    toFixedCurrency,
    url,
    variants,
}) => {
    const { t } = useTranslation();
    const { origin, protocol = 'https://', locale } = useSelector(({ application }) => application);
    const appOrganization =
        isInternationalMarket && INTERNATIONAL_APP_ORGANIZATION ? INTERNATIONAL_APP_ORGANIZATION : APP_ORGANIZATION;
    const productAvailabilityValue = getAvailabilitySchemaUrl(productAvailability);
    const energyEfficiencyParametric = getParametric('hy_energi_21', product)?.value;
    const freeShippingFlagSE = getFlag(HY_SE_FRAKT_FRI, product);
    const freeShippingFlag = getFlag(HY_INT_FRAKT_FRI, product);
    const countryLocale = locale.split('_')[1].toUpperCase();
    const isSE = locale === 'sv_SE';

    const schema = {
        '@context': 'http://schema.org',
        '@type': 'Product',
        name,
        image: images,
        description,
        sku,
        productID: ean,
        gtin13: ean,
        mpn,
        brand: {
            '@type': 'Brand',
            name: brand,
        },
        offers: {
            '@type': 'Offer',
            url,
            priceCurrency: currency,
            price,
            priceSpecification: {
                price,
                priceCurrency: currency,
            },
            itemCondition: 'http://schema.org/NewCondition',
            availability: productAvailabilityValue,
            seller: {
                '@type': 'Organization',
                name: appOrganization,
            },
        },
    };

    if ((isSE && freeShippingFlagSE) || (!isSE && freeShippingFlag)) {
        schema['offers']['shippingDetails'] = {
            '@type': 'OfferShippingDetails',
            'shippingRate': {
                '@type': 'MonetaryAmount',
                'value': '0',
                'currency': currency,
            },
            'shippingDestination': [
                {
                    '@type': 'DefinedRegion',
                    'addressCountry': countryLocale,
                },
            ],
        };
    }

    if (energyEfficiencyParametric) {
        schema['hasEnergyConsumptionDetails'] = {
            '@type': 'EnergyConsumptionDetails',
            'hasEnergyEfficiencyCategory': `https://schema.org/EUEnergyEfficiencyCategory${energyEfficiencyParametric}`,
            'energyEfficiencyScaleMin': 'https://schema.org/EUEnergyEfficiencyCategoryG',
            'energyEfficiencyScaleMax': 'https://schema.org/EUEnergyEfficiencyCategoryA',
        };
    }

    if (productAvailability.nextDelivery) {
        schema['offers']['availabilityStarts'] = productAvailability.nextDelivery;
    }

    if (variants.length) {
        const lowestPriceProduct = variants.reduce((res, obj) => {
            return obj.price < res.price ? obj : res;
        });

        const highestPriceProduct = variants.reduce((res, obj) => {
            return obj.price > res.price ? obj : res;
        });

        schema['offers'] = {
            '@type': 'AggregateOffer',
            url,
            lowPrice: lowestPriceProduct.price_inc_vat,
            highPrice: highestPriceProduct.price_inc_vat,
            priceCurrency: currency,
            offerCount: variants.length,
        };

        const productVariations = variants.map(variation => {
            const variationAvailability = getProductAvailability(variation);
            const variationAvailabilityValue = getAvailabilitySchemaUrl(variationAvailability);

            const variationColor = getParametric('color', variation)?.value;
            const variationSize = getParametric('size_list', variation)?.value;
            const variationName = variation.variant_name || name;
            const variationImage =
                (variation.format || {}).image || (variation.files[0] && variation.files[0].format.image);
            const variationSku = mpn;
            const variationEan = variation.ean_code;

            const variationPrice = hasDecimalPrice
                ? toFixedCurrency(Math.round(variation.price_inc_vat * 100) / 100, 2, false)
                : Math.round(variation.price_inc_vat).toFixed(2);

            const variationSchema = {
                '@context': 'http://schema.org',
                '@type': 'ProductModel',
                name: variationName,
                image: variationImage,
                description,
                sku: variationSku,
                productID: variationEan,
                gtin13: variationEan,
                mpn,
                brand: {
                    '@type': 'Brand',
                    name: brand,
                },
                offers: {
                    '@type': 'Offer',
                    url: `${url}?p=${variation.part_no}`,
                    price: variationPrice,
                    priceCurrency: currency,
                    itemCondition: 'http://schema.org/NewCondition',
                    availability: variationAvailabilityValue,
                    seller: {
                        '@type': 'Organization',
                        name: appOrganization,
                    },
                    priceSpecification: {
                        price: variationPrice,
                        priceCurrency: currency,
                    },
                },
                // TODO: Reviews are not avalible at the moment, add in later stage
                // aggregateRating: null,
                // review: null,
            };

            if (variationColor) {
                variationSchema['color'] = variationColor;
            }

            if (variationSize) {
                variationSchema['size'] = variationSize;
            }

            if (variationAvailability.nextDelivery) {
                variationSchema['offers']['availabilityStarts'] = variationAvailability.nextDelivery;
            }

            return variationSchema;
        });

        schema['model'] = productVariations;
    }

    const breadcrumbs = [];

    if (breadcrumbsData) {
        breadcrumbsData.forEach((item, index) => {
            breadcrumbs.push({
                '@type': 'ListItem',
                position: index + 1,
                name: decodeHTMLEntities(item.title),
                item: protocol + origin + item.slug,
            });
        });
    }

    breadcrumbs.push({
        '@type': 'ListItem',
        position: breadcrumbs.length + 1,
        name: decodeHTMLEntities(name),
    });

    const breadcrumbList = {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        'itemListElement': breadcrumbs,
    };

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(schema, null, 2)}</script>
            {!!breadcrumbs.length && (
                <script type="application/ld+json">{JSON.stringify(breadcrumbList, null, 2)}</script>
            )}
        </Helmet>
    );
};

ProductStructuredData.propTypes = {
    brand: PropTypes.string.isRequired,
    breadcrumbsData: PropTypes.array.isRequired,
    currency: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    ean: PropTypes.string.isRequired,
    hasDecimalPrice: PropTypes.bool.isRequired,
    images: PropTypes.array.isRequired,
    isInternationalMarket: PropTypes.bool.isRequired,
    mpn: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    product: PropTypes.object.isRequired,
    productAvailability: PropTypes.shape({
        inStock: PropTypes.bool,
        preOrder: PropTypes.bool,
        nextDelivery: PropTypes.string,
    }).isRequired,
    sku: PropTypes.string.isRequired,
    toFixedCurrency: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    variants: PropTypes.array.isRequired,
};

export default ProductStructuredData;
