import colors from 'theme/config/colors';

export const baseStyle = `
    html {
        height: 100%;
        font-family: 'Akkurat';
        color: ${colors.darkGrey};
    }

    body {
        min-height: 100%;
        padding: 0;
        margin: 0;
        font-size: 16px;
        line-height: 1em;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow-x: hidden;

        &.prevent-scroll {
            overflow: hidden;

            @supports (-webkit-overflow-scrolling: touch) {
                position: fixed;
                width: 100%;
            }
        }
    }

    #root {
        height: 100%;
    }

	#ot-sdk-btn-floating.ot-floating-button:hover::before {
		transform: translate(-4em, -50%) scale(1) !important;
		
	}
		
	#ot-sdk-btn-floating.ot-floating-button::before { 
		border:5px solid transparent !important;
		border-right-width: 0px !important;
		border-left-color: #333 !important;
	} 
		
	#ot-sdk-btn-floating.ot-floating-button:hover::after { 
		transform: translate(-15.9em,-50%) scale(1) !important;
	}

	#ot-sdk-btn-floating.ot-floating-button {
		animation: customFloatingBtnIntro 800ms ease 0ms 1 forwards !important;
	}

	@keyframes customFloatingBtnIntro { 
		0% {
		opacity: 0;
		right: -75px;
		}
	100% {
		opacity: 1;
		right: 1%
		}
	}
`;
