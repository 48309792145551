import Cookies from 'js-cookie';
import Events from 'core-web/libs/Events';
import { BasketEvents, LogEvents } from 'core-web/libs/Events/constants';
import {
    AddBasketInformation,
    AddBasketItems,
    AddBasketPaymentFields,
    AddGiftCard,
    AddPackageToBasket,
    AddToBasket,
    AddVoucher,
    CheckGiftCard,
    CreateBasket,
    DeleteBasketItem,
    EmptyBasket,
    GetAbandonedBasket,
    GetBasket,
    GetBasketPayment,
    GetBasketPaymentCallback,
    GetCheckout,
    GetServicePoints,
    RemoveVoucher,
    ResetDeliveryMethod,
    RollbackGiftCard,
    UpdateBasket,
    UpdateBasketItem,
    UpdateBasketItemPrice,
    UpdateBasketItems,
    UpdateBasketPackage,
    UpdateBasketSecret,
    UpdateDeliveryMethod,
    UpdateItemDiscounts,
    UpdatePaymentMethod,
} from 'core-web/libs/GrebbCommerceAPI/Basket';
import { ResetBasketBuyer, SetBasketBuyer, SetSecretBasketBuyer } from 'core-web/libs/GrebbCommerceAPI/Checkout';
import { acquireMutex } from 'core-web/state';
import { checkIfHandleProductManually, shouldUpdateManualOrderInfo } from 'core-web/util/checkIfHandleProductManually';
import {
    checkOnhandStatus,
    getOnhandUpdateData,
    handleRemoveBasketItems,
    sendOnhandQuantityEvent,
} from 'core-web/util/checkOnHandStatus';
import { getBasketInfo } from 'core-web/util/checkoutUtils';
import { auth } from 'core-web/util/firebase';
import formatServicePointData from 'core-web/util/formatServicePointData';
import get from 'core-web/util/get';
import resolveSequentially from 'core-web/util/resolveSequentially';
import { shouldAddSalesRepInfo } from 'core-web/util/salesRepInfo';
import { BRING_SERVICE_POINT_CODE, POSTNORD_SERVICE_POINT_CODE } from 'theme/config/constants';
import {
    ADD_BASKET_ITEMS,
    ADD_BASKET_ITEMS_ERROR,
    ADD_BASKET_ITEMS_SUCCESS,
    ADD_BASKET_PAYMENT_FIELDS,
    ADD_BASKET_PAYMENT_FIELDS_ERROR,
    ADD_BASKET_PAYMENT_FIELDS_SUCCESS,
    ADD_GIFT_CARD,
    ADD_GIFT_CARD_ERROR,
    ADD_GIFT_CARD_SUCCESS,
    ADD_PACKAGE_TO_BASKET,
    ADD_PACKAGE_TO_BASKET_ERROR,
    ADD_PACKAGE_TO_BASKET_SUCCESS,
    ADD_TO_BASKET,
    ADD_TO_BASKET_ERROR,
    ADD_TO_BASKET_SUCCESS,
    ADD_VOUCHER,
    ADD_VOUCHER_ERROR,
    ADD_VOUCHER_SUCCESS,
    CLOSE_BASKET,
    CREATE_BASKET,
    CREATE_BASKET_ERROR,
    CREATE_BASKET_SUCCESS,
    EMPTY_BASKET,
    EMPTY_BASKET_ERROR,
    EMPTY_BASKET_SUCCESS,
    GET_ABANDONED_BASKET,
    GET_ABANDONED_BASKET_ERROR,
    GET_ABANDONED_BASKET_SUCCESS,
    GET_BASKET,
    GET_BASKET_ERROR,
    GET_BASKET_PAYMENT,
    GET_BASKET_PAYMENT_ERROR,
    GET_BASKET_PAYMENT_SUCCESS,
    GET_BASKET_SUCCESS,
    GET_CHECKOUT,
    GET_CHECKOUT_ERROR,
    GET_CHECKOUT_SUCCESS,
    GET_SERVICE_POINTS,
    GET_SERVICE_POINTS_ERROR,
    GET_SERVICE_POINTS_SUCCESS,
    OPEN_BASKET,
    REMOVE_BASKET,
    REMOVE_BASKET_ERROR,
    REMOVE_BASKET_SUCCESS,
    REMOVE_VOUCHER,
    REMOVE_VOUCHER_ERROR,
    REMOVE_VOUCHER_SUCCESS,
    RESET_BASKET_BUYER,
    RESET_BASKET_BUYER_ERROR,
    RESET_BASKET_BUYER_SUCCESS,
    RESET_DELIVERY_METHOD,
    RESET_DELIVERY_METHOD_ERROR,
    RESET_DELIVERY_METHOD_SUCCESS,
    ROLLBACK_GIFT_CARD,
    ROLLBACK_GIFT_CARD_ERROR,
    ROLLBACK_GIFT_CARD_SUCCESS,
    SET_BASKET,
    SET_BASKET_ERROR,
    SET_BASKET_SUCCESS,
    TOGGLE_BASKET,
    UPDATE_BASKET,
    UPDATE_BASKET_BUYER,
    UPDATE_BASKET_BUYER_ERROR,
    UPDATE_BASKET_BUYER_SUCCESS,
    UPDATE_BASKET_ERROR,
    UPDATE_BASKET_ITEM,
    UPDATE_BASKET_ITEMS,
    UPDATE_BASKET_ITEMS_ERROR,
    UPDATE_BASKET_ITEMS_SUCCESS,
    UPDATE_BASKET_ITEM_ERROR,
    UPDATE_BASKET_ITEM_SUCCESS,
    UPDATE_BASKET_PACKAGE_ITEM,
    UPDATE_BASKET_PACKAGE_ITEM_ERROR,
    UPDATE_BASKET_PACKAGE_ITEM_SUCCESS,
    UPDATE_BASKET_SECRET_ERROR,
    UPDATE_BASKET_SECRET_SUCCESS,
    UPDATE_BASKET_SUCCESS,
    UPDATE_DELIVERY_METHOD,
    UPDATE_DELIVERY_METHOD_ERROR,
    UPDATE_DELIVERY_METHOD_SUCCESS,
    UPDATE_ITEM_DISCOUNTS,
    UPDATE_ITEM_DISCOUNTS_ERROR,
    UPDATE_ITEM_DISCOUNTS_SUCCESS,
    UPDATE_ITEM_PRICE,
    UPDATE_ITEM_PRICE_ERROR,
    UPDATE_ITEM_PRICE_SUCCESS,
    UPDATE_PAYMENT_METHOD,
    UPDATE_PAYMENT_METHOD_ERROR,
    UPDATE_PAYMENT_METHOD_SUCCESS,
    UPDATE_SECRET_BASKET,
    UPDATE_SECRET_BASKET_BUYER,
    UPDATE_SECRET_BASKET_BUYER_ERROR,
    UPDATE_SECRET_BASKET_BUYER_SUCCESS,
    UPDATE_SERVICE_POINT,
    UPDATE_SERVICE_POINT_ERROR,
    UPDATE_SERVICE_POINT_SUCCESS,
} from './constants';

export const getBasketIdCookie = () => {
    // Set the basketId for future usage.
    const cookieValue = Cookies.get('basket_id');

    if (!cookieValue || cookieValue === 'undefined') {
        return false;
    }

    return cookieValue;
};

export const setBasketIdCookie = (basketId, application) => {
    // Set the basketId for future usage.
    const lifespan = application && get(application, 'config.header.basket.lifespan');

    Cookies.set('basket_id', basketId, {
        expires: lifespan ? Number(lifespan) : 28,
    });
};

const clearBasketIdCookie = () => Cookies.remove('basket_id');

export const removeBasket = () => async dispatch => {
    const mutexLock = await acquireMutex('basket.getBasket');
    dispatch({ type: REMOVE_BASKET });
    try {
        Cookies.remove('giftcard_balance');
        Cookies.remove('basket_id');

        dispatch({ type: REMOVE_BASKET_SUCCESS });

        Events.trigger(BasketEvents.UPDATED);

        mutexLock();
    } catch (e) {
        dispatch({ type: REMOVE_BASKET_ERROR });

        mutexLock();

        throw e;
    }
};

const getItemByLineNo = (lineNo, items) => {
    return items.filter(item => {
        if (item.line_no === lineNo) {
            return item;
        }
        return false;
    })[0];
};

const getItemById = (id, items) => {
    return items.filter(item => {
        if (item.id === id) {
            return item;
        }
        return false;
    })[0];
};

const getDiscountType = value => {
    return typeof value === 'string' && value.indexOf('%') !== -1 ? 'percentage' : 'price';
};

// This function might not always return the correct item because there might be several lines with the same partNo,
// ex. packages might contain product X and then we add product X outside the package, this function will return the first.
const getItemByPartNo = (id, items) => {
    const foundItems = items.filter(item => parseInt(item.part_no, 10) === parseInt(id, 10));
    return foundItems[foundItems.length - 1];
};

export const setBasketId = basketId => dispatch => {
    dispatch({ type: SET_BASKET });
    try {
        dispatch({
            type: SET_BASKET_SUCCESS,
            basketId,
        });

        Events.trigger(BasketEvents.SET);
        return true;
    } catch (e) {
        dispatch({ type: SET_BASKET_ERROR });
        throw e;
    }
};

export const getBasketId = () => async (dispatch, getState) => {
    let basketId = getBasketIdCookie() || getState().basket.basketId || null;
    if (!basketId || basketId === 'undefined') {
        const response = await createBasket()(dispatch, getState);
        basketId = response?.data?.id;
    }
    return basketId;
};

export const maybeUpdateSiteVatSetting = (basket, getState) => {
    // Toggle showVat on site if basket is_company flag differs from site setting
    const products = getState().products;
    const siteIsCompany = !products.showVat;
    const basketIsCompany = !!parseInt(getBasketInfo(basket, 'is_company'), 10);

    if (siteIsCompany !== basketIsCompany && !auth.currentUser) {
        products.toggleVat();
    }
};

export const getBasket = () => async (dispatch, getState) => {
    let basketId = await getBasketId()(dispatch, getState);
    const mutexLock = await acquireMutex('basket.getBasket');
    let response;
    dispatch({ type: GET_BASKET });

    try {
        response = await GetBasket(basketId);
        if (response?.data) {
            if (response.data.is_editable === false) {
                mutexLock();
                await removeBasket()(dispatch, getState);
                Events.trigger(LogEvents.LOG, { event: 'error', type: 'Storm uneditable basket', message: basketId });
                return;
            }

            maybeUpdateSiteVatSetting(response.data, getState);

            dispatch({
                type: GET_BASKET_SUCCESS,
                basketId,
                items: response.data.items || [],
                currency: response.data.currency_id || null,
                summary: response.data.format.summary || [],
                comment: response.data.comment,
                textComments: response.data.format.text_comment || [],
                promotions: response.data.applied_promotions,
                payments: response.data.payments,
                info: response.data.info || [],
                customerId: response.data.customer_id || '',
                companyId: response.data.company_id || '',
            });
        } else {
            // This basket was not found.
            // Create a new one.
            basketId = null;
            clearBasketIdCookie();
            dispatch({ type: GET_BASKET_ERROR });
            const createBasketResponse = await createBasket()(dispatch, getState);
            basketId = createBasketResponse.data.id;
            response = await GetBasket(basketId);
        }

        mutexLock();
    } catch (e) {
        dispatch({ type: GET_BASKET_ERROR });
        removeBasket()(dispatch, getState);
        mutexLock();
        throw e;
    }

    if (response && response.data.company_id) {
        const customer = getState().customer;
        // If basket has another company id set than customer state has we change customer´s
        if (customer.auth && response.data.company_id !== customer.companyId) {
            customer.updateCustomer(response.data.company_id);
        }
    }

    return response;
};

export const removeOutOfStockItems =
    (sendTrackingEvent = false) =>
    async (dispatch, getState) => {
        const { basket } = getState();
        const onHandChangeData = checkOnhandStatus(basket.items);
        if (onHandChangeData.length) {
            const { productsToUpdate, packagesToUpdate, productsToRemove } = getOnhandUpdateData(
                basket,
                onHandChangeData
            );
            if (productsToRemove.length) {
                await handleRemoveBasketItems(basket, productsToRemove);
                if (sendTrackingEvent) {
                    productsToRemove.forEach(item => {
                        sendOnhandQuantityEvent(item.part_no);
                    });
                }
            }
            if (productsToUpdate.length) {
                await basket.updateBasketItems(productsToUpdate);
                if (sendTrackingEvent) {
                    productsToUpdate.forEach(item => {
                        sendOnhandQuantityEvent(item.part_no);
                    });
                }
            }
            if (packagesToUpdate.length) {
                await resolveSequentially(packagesToUpdate);
            }
        }
    };

export const getAbandonedBasket = basketId => async (dispatch, getState) => {
    const mutexLock = await acquireMutex('basket.getBasket');
    const { basket } = getState();
    let response;

    dispatch({ type: GET_ABANDONED_BASKET });

    try {
        response = await GetAbandonedBasket(basketId);

        // If basket isn't editable, it's probably checked out already.
        if (response.data.is_editable === false) {
            if (basket.basketId && basket.basketId.indexOf(basketId) !== -1) {
                await removeBasket()(dispatch, getState);
            }
            Events.trigger(LogEvents.LOG, { event: 'error', type: 'Storm uneditable basket', message: basketId });
            mutexLock();
            return;
        }

        setBasketIdCookie(response.data.id, getState('application'));

        dispatch({
            type: GET_ABANDONED_BASKET_SUCCESS,
            basketId: response.data.id,
            items: response.data.items || [],
            currency: response.data.currency_id || null,
            summary: response.data.format.summary || [],
            comment: response.data.comment,
            textComments: response.data.format.text_comment || [],
            payments: response.data.payments,
            promotions: response.data.applied_promotions,
            info: response.data.info || [],
            customerId: response.data.customer_id || '',
            companyId: response.data.company_id || '',
            shouldOverwriteTextComments: false,
        });

        mutexLock();
    } catch (e) {
        dispatch({ type: GET_ABANDONED_BASKET_ERROR });
        mutexLock();
        throw e;
    }

    // Check for out of stock items
    try {
        await removeOutOfStockItems()(dispatch, getState);
    } catch (error) {
        dispatch({ type: SET_BASKET_ERROR });
        throw error;
    }

    if (response && response?.data?.company_id) {
        const { auth, companyId, updateCustomer } = getState().customer || {};

        // If basket has another company id set than customer state has we change customer´s
        if (auth && response.data.company_id !== companyId) {
            updateCustomer(response.data.company_id);
        }
    }

    return response;
};

export const createBasket = () => async (dispatch, getState) => {
    const mutexLock = await acquireMutex('basket.getBasket');
    const {
        application,
        basket: { isFetching },
    } = getState();
    if (isFetching) {
        return;
    }

    // These are hardcoded for now. Should be read from state later.
    dispatch({ type: CREATE_BASKET });

    Cookies.remove('checkoutId');
    Cookies.remove('paymentReference');

    try {
        // todo: customer pricelists should be added here when user is logged in
        const response = await CreateBasket('127.0.0.1');

        if (!response?.data) {
            throw 'Could not create basket';
        }

        setBasketIdCookie(response.data.id, application);

        dispatch({
            type: CREATE_BASKET_SUCCESS,
            basketId: response.data.id,
            currency: response.data.currency_id || null,
            info: response.data.info || [],
            customerId: response.data.customer_id || '',
            companyId: response.data.company_id || '',
        });

        Events.trigger(BasketEvents.CREATED);
        shouldAddSalesRepInfo();

        if (Cookies.get('show_vat') === 'false') {
            const companyInfo = {
                infos: [
                    {
                        code: 'is_company',
                        value: 1,
                    },
                ],
            };
            const basket = getState().basket;
            basket.updateBasket(response.data.id, companyInfo, true);
        }

        mutexLock();

        return response;
    } catch (e) {
        dispatch({ type: CREATE_BASKET_ERROR });

        mutexLock();

        throw e;
    }
};

export const getOrCreateBasket = () => async (dispatch, getState) => {
    const basket = getState().basket;
    const basketId = basket.basketId || getBasketIdCookie() || null;
    const isFetched = basket.currency;

    if (isFetched) {
        return;
    }

    if (basketId) {
        return await getBasket()(dispatch, getState);
    }

    return await createBasket()(dispatch, getState);
};

export const addToBasket =
    (basketId, partNo, quantity, pricelistId = null, infos = [], comment = '') =>
    async (dispatch, getState) => {
        const mutexLock = await acquireMutex('basket.getBasket');
        dispatch({ type: ADD_TO_BASKET });

        try {
            const response = await AddToBasket(basketId, partNo, quantity, pricelistId, infos, comment);
            const items = response.data.items || [];
            const basket = getState().basket;

            if (shouldUpdateManualOrderInfo(basket, items)) {
                basket.updateBasket(basketId, checkIfHandleProductManually(response.data.items));
            }

            dispatch({
                type: ADD_TO_BASKET_SUCCESS,
                items,
                info: response.data.info || [],
                summary: response.data.format.summary,
                promotions: response.data.applied_promotions,
                shouldOverwriteTextComments: false,
            });

            Events.trigger(BasketEvents.UPDATED);
            Events.trigger(BasketEvents.PRODUCT_ADDED, {
                basketId,
                // This function might not always return the correct item, see function comment for more details
                item: { ...getItemByPartNo(partNo, items), quantity },
            });

            mutexLock();

            return response;
        } catch (e) {
            dispatch({ type: ADD_TO_BASKET_ERROR });

            mutexLock();

            throw e;
        }
    };

export const addPackageToBasket = (basketId, packageItems, mainProduct) => async dispatch => {
    // @TODO Check if mainProduct can be removed.
    const mutexLock = await acquireMutex('basket.getBasket');
    dispatch({ type: ADD_PACKAGE_TO_BASKET });

    try {
        const response = await AddPackageToBasket(basketId, packageItems);

        dispatch({
            type: ADD_PACKAGE_TO_BASKET_SUCCESS,
            items: response.data.items || [],
            summary: response.data.format.summary,
            promotions: response.data.applied_promotions,
            shouldOverwriteTextComments: false,
        });

        Events.trigger(BasketEvents.UPDATED);
        Events.trigger(BasketEvents.PRODUCT_ADDED, { basketId, item: mainProduct }); // @TODO Check if mainProduct can be swapped out to packageItems[0] (which should be the main product(?). Maybe we should just send all items?

        mutexLock();

        return response;
    } catch (e) {
        dispatch({ type: ADD_PACKAGE_TO_BASKET_ERROR });

        mutexLock();

        throw e;
    }
};

export const addBasketItems = (basketId, basketItems) => async dispatch => {
    const mutexLock = await acquireMutex('basket.getBasket');
    dispatch({ type: ADD_BASKET_ITEMS });

    try {
        const response = await AddBasketItems(basketId, basketItems);
        const items = response.data.items || [];
        dispatch({
            type: ADD_BASKET_ITEMS_SUCCESS,
            items,
            info: response.data.info || [],
            summary: response.data.format.summary,
            promotions: response.data.applied_promotions,
            shouldOverwriteTextComments: false,
        });

        Events.trigger(BasketEvents.UPDATED);
        basketItems.forEach(basketItem => {
            // This function might not always return the correct item, see function comment for more details
            const item = { ...getItemByPartNo(basketItem['part_no'], items), quantity: basketItem.quantity };
            Events.trigger(BasketEvents.PRODUCT_ADDED, {
                basketId,
                item,
            });
        });

        mutexLock();

        return response;
    } catch (e) {
        dispatch({ type: ADD_BASKET_ITEMS_ERROR });

        mutexLock();

        throw e;
    }
};

export const updateBasketItemPrice = (basketId, basketItemId, lineNo, price, percantage) => async dispatch => {
    const mutexLock = await acquireMutex('basket.getBasket');
    dispatch({ type: UPDATE_ITEM_PRICE });
    try {
        const response = await UpdateBasketItemPrice(basketId, basketItemId, lineNo, price, percantage);

        dispatch({
            type: UPDATE_ITEM_PRICE_SUCCESS,
            items: response.data.items || [],
            summary: response.data.format.summary,
            shouldOverwriteTextComments: false,
        });

        Events.trigger(BasketEvents.UPDATED);

        mutexLock();
    } catch (e) {
        dispatch({ type: UPDATE_ITEM_PRICE_ERROR });
        mutexLock();
        throw e;
    }
};

export const deleteBasketItem = (basketId, lineNo) => async (dispatch, getState) => {
    const mutexLock = await acquireMutex('basket.getBasket');
    dispatch({ type: UPDATE_BASKET_ITEM });
    try {
        const basket = getState().basket;
        const trackRemovedItem = getItemByLineNo(lineNo, basket.items);

        let response = await DeleteBasketItem(basketId, lineNo);
        if (basket.discounts?.totalDiscount) {
            const formData = {
                [getDiscountType(basket.discounts.basket)]: basket.discounts.basket,
                items: Object.entries(basket.discounts.items).map(([id, value]) => ({
                    id,
                    [getDiscountType(value)]: value,
                })),
            };

            response = await UpdateItemDiscounts(basketId, formData);
        }
        const items = response.data.items || [];
        if (shouldUpdateManualOrderInfo(basket, items)) {
            basket.updateBasket(basketId, checkIfHandleProductManually(response.data.items));
        }

        dispatch({
            type: UPDATE_BASKET_ITEM_SUCCESS,
            items,
            info: response.data.info || [],
            summary: response.data.format.summary,
            promotions: response.data.applied_promotions,
        });

        Events.trigger(BasketEvents.UPDATED);
        Events.trigger(BasketEvents.PRODUCT_REMOVED, { item: trackRemovedItem });

        mutexLock();
        return response;
    } catch (e) {
        dispatch({ type: UPDATE_BASKET_ITEM_ERROR });
        mutexLock();
        throw e;
    }
};

export const updateBasketItem =
    (basketId, partNo, basketItemId, quantity, pricelistId, source) => async (dispatch, getState) => {
        // Should this even be possible if we haven't already fetched the Basket prior to this?
        const mutexLock = await acquireMutex('basket.getBasket');

        dispatch({ type: UPDATE_BASKET_ITEM });
        try {
            const basket = getState().basket;
            const trackedItem = getItemById(basketItemId, basket.items);

            if (source === 'remove') {
                trackedItem.quantity = trackedItem.quantity - quantity;
            }
            if (source === 'add') {
                trackedItem.quantity = quantity - trackedItem.quantity;
            }

            let response = await UpdateBasketItem(basketId, partNo, basketItemId, quantity, pricelistId);
            if (basket.discounts?.totalDiscount) {
                const formData = {
                    [getDiscountType(basket.discounts.basket)]: basket.discounts.basket,
                    items: Object.entries(basket.discounts.items).map(([id, value]) => ({
                        id,
                        [getDiscountType(value)]: value,
                    })),
                };

                response = await UpdateItemDiscounts(basketId, formData);
            }

            dispatch({
                type: UPDATE_BASKET_ITEM_SUCCESS,
                items: response.data.items || [],
                info: response.data.info || [],
                summary: response.data.format.summary,
                promotions: response.data.applied_promotions,
            });

            Events.trigger(BasketEvents.UPDATED);

            if (source === 'add') {
                Events.trigger(BasketEvents.PRODUCT_ADDED, {
                    basketId,
                    item: { ...trackedItem },
                });
            } else if (source === 'remove') {
                Events.trigger(BasketEvents.PRODUCT_REMOVED, {
                    item: { ...trackedItem },
                });
            }

            mutexLock();

            return response;
        } catch (e) {
            dispatch({ type: UPDATE_BASKET_ITEM_ERROR });

            mutexLock();

            throw e;
        }
    };

export const updateBasketItems = (basketId, basketItems) => async dispatch => {
    // Should this even be possible if we haven't already fetched the Basket prior to this?
    const mutexLock = await acquireMutex('basket.getBasket');
    dispatch({ type: UPDATE_BASKET_ITEMS });
    try {
        const response = await UpdateBasketItems(basketId, basketItems);
        const items = response.data.items || [];
        dispatch({
            type: UPDATE_BASKET_ITEMS_SUCCESS,
            items,
            info: response.data.info || [],
            summary: response.data.format.summary,
            promotions: response.data.applied_promotions,
            shouldOverwriteTextComments: false,
        });

        Events.trigger(BasketEvents.UPDATED);
        basketItems.forEach(basketItem => {
            // This function might not always return the correct item, see function comment for more details
            const item = { ...getItemByPartNo(basketItem['part_no'], items), quantity: basketItem.quantity };
            Events.trigger(BasketEvents.PRODUCT_ADDED, {
                basketId,
                item,
            });
        });

        mutexLock();
        return response;
    } catch (e) {
        dispatch({ type: UPDATE_BASKET_ITEMS_ERROR });
        mutexLock();
        throw e;
    }
};

export const updateBasketPackageItem = (basketId, item, quantity, source) => async (dispatch, getState) => {
    const mutexLock = await acquireMutex('basket.getBasket');
    const lineNo = item.line_no;

    dispatch({ type: UPDATE_BASKET_PACKAGE_ITEM });
    try {
        const discounts = getState().basket.discounts;
        let response = await UpdateBasketPackage(basketId, lineNo, quantity);
        if (discounts?.totalDiscount) {
            const formData = {
                [getDiscountType(discounts.basket)]: discounts.basket,
                items: Object.entries(discounts.items).map(([id, value]) => ({
                    id,
                    [getDiscountType(value)]: value,
                })),
            };

            response = await UpdateItemDiscounts(basketId, formData);
        }

        dispatch({
            type: UPDATE_BASKET_PACKAGE_ITEM_SUCCESS,
            items: response.data.items || [],
            info: response.data.info,
            summary: response.data.format.summary,
            promotions: response.data.applied_promotions,
        });
        Events.trigger(BasketEvents.UPDATED);

        if (source === 'add') {
            item.quantity = quantity - item.quantity;
            Events.trigger(BasketEvents.PRODUCT_ADDED, { basketId, item });
        } else if (source === 'remove') {
            item.quantity = item.quantity - quantity;
            Events.trigger(BasketEvents.PRODUCT_REMOVED, { item });
        }

        mutexLock();
        return response;
    } catch (e) {
        dispatch({ type: UPDATE_BASKET_PACKAGE_ITEM_ERROR });
        mutexLock();
        throw e;
    }
};

// @todo: fix this method.. so it works.. just started on it..
export const emptyBasket = () => async (dispatch, getState) => {
    const basketId = await getBasketId()(dispatch, getState);
    const mutexLock = await acquireMutex('basket.getBasket');
    dispatch({ type: EMPTY_BASKET });

    try {
        const response = await EmptyBasket(basketId);
        dispatch({ type: EMPTY_BASKET_SUCCESS });
        Events.trigger(BasketEvents.EMPTIED, response.data);

        mutexLock();

        return response;
    } catch (e) {
        dispatch({ type: EMPTY_BASKET_ERROR });

        mutexLock();

        throw e;
    }
};

export const openBasket = () => dispatch => {
    dispatch({ type: OPEN_BASKET });
    Events.trigger(BasketEvents.OPENED);
};

export const closeBasket = () => dispatch => {
    dispatch({ type: CLOSE_BASKET });
    Events.trigger(BasketEvents.CLOSED);
};

export const toggleBasket = () => dispatch => {
    dispatch({ type: TOGGLE_BASKET });
    Events.trigger(BasketEvents.TOGGLED);
};

export const checkGiftCard = (cardNo, cvc) => async () => {
    return await CheckGiftCard(cardNo, cvc);
};

export const addGiftCard = (cardNo, cvc) => async (dispatch, getState) => {
    const { basketId } = getState().basket;
    const mutexLock = await acquireMutex('basket.getBasket');

    dispatch({ type: ADD_GIFT_CARD });
    try {
        const response = await AddGiftCard(basketId, cardNo, cvc);

        const paymentCode = response.data.payment_code;

        if (paymentCode !== null) {
            dispatch({
                type: ADD_GIFT_CARD_SUCCESS,
            });
            Events.trigger(BasketEvents.GIFT_CARD_ADDED);
        } else {
            throw 'Too low reservation amount';
        }

        response['paymentCode'] = paymentCode;

        mutexLock();

        return response;
    } catch (e) {
        dispatch({ type: ADD_GIFT_CARD_ERROR });

        mutexLock();
        console.error(e);
    }
};

export const rollbackGiftCard = paymentCode => async (dispatch, getState) => {
    const { basketId } = getState().basket;
    const mutexLock = await acquireMutex('basket.getBasket');

    dispatch({ type: ROLLBACK_GIFT_CARD });
    try {
        const response = await RollbackGiftCard(basketId, paymentCode);

        dispatch({
            type: ROLLBACK_GIFT_CARD_SUCCESS,
        });

        Events.trigger(BasketEvents.GIFT_CARD_ROLLBACK);

        mutexLock();

        return response;
    } catch (e) {
        dispatch({ type: ROLLBACK_GIFT_CARD_ERROR });

        mutexLock();

        console.error(e);

        return null;
    }
};

export const addVoucher = voucherId => async (dispatch, getState) => {
    const basketId = await getBasketId()(dispatch, getState);
    const mutexLock = await acquireMutex('basket.getBasket');
    dispatch({ type: ADD_VOUCHER });

    try {
        const response = await AddVoucher(basketId, voucherId);

        dispatch({
            type: ADD_VOUCHER_SUCCESS,
            items: response.data.items || [],
            summary: response.data.format.summary || [],
            textComments: response.data.format.text_comment || [],
            promotions: response.data.applied_promotions || [],
            shouldOverwriteTextComments: false,
        });

        const valid = response.data.applied_promotions.map(promotion => promotion.discount_code).includes(voucherId);
        response['valid'] = valid;

        if (valid) {
            Events.trigger(BasketEvents.VOUCHER_ADDED);
        }

        mutexLock();

        return response;
    } catch (e) {
        dispatch({ type: ADD_VOUCHER_ERROR });

        mutexLock();

        throw e;
    }
};

export const removeVoucher = voucherId => async (dispatch, getState) => {
    // Should this even be possible if we haven't already fetched the Basket prior to this?
    const basketId = await getBasketId()(dispatch, getState);
    const mutexLock = await acquireMutex('basket.getBasket');

    dispatch({ type: REMOVE_VOUCHER });
    try {
        const response = await RemoveVoucher(basketId, voucherId);

        // @todo: Fix here.. what do we want to dispatch when we receive a success
        // from the add voucher call, what is returned from the api?... how will the state be modified?
        dispatch({
            type: REMOVE_VOUCHER_SUCCESS,
            items: response.data.items || [],
            summary: response.data.format.summary || [],
            textComments: response.data.format.text_comment || [],
            promotions: response.data.applied_promotions || [],
            shouldOverwriteTextComments: false,
        });

        Events.trigger(BasketEvents.VOUCHER_REMOVED);

        mutexLock();

        return response;
    } catch (e) {
        dispatch({ type: REMOVE_VOUCHER_ERROR });

        mutexLock();

        throw e;
    }
};

export const getBasketPayment = (paymentParameters, provider) => async (dispatch, getState) => {
    const basketId = await getBasketId()(dispatch, getState);
    const basket = getState().basket;
    const mutexLock = await acquireMutex('basket.getBasket');

    dispatch({ type: GET_BASKET_PAYMENT });
    try {
        const response = await GetBasketPayment(basketId, paymentParameters, provider);
        dispatch({
            type: GET_BASKET_PAYMENT_SUCCESS,
            payment: response.data,
        });

        const retain24 = parseInt(process.env.REACT_APP_STORM_PAYMENT_METHOD_RETAIN24, 10);
        const giftCards = Array.isArray(basket.payments)
            ? basket.payments.filter(payment => payment.payment_method_id === retain24)
            : [];

        if (giftCards.length > 0) {
            basket.getCheckout();
        }

        mutexLock();
        return response;
    } catch (e) {
        dispatch({ type: GET_BASKET_PAYMENT_ERROR });
        mutexLock();
        throw e;
    }
};

export const getBasketPaymentCallback = checkoutId => async () => {
    const mutexLock = await acquireMutex('basket.getBasket');
    try {
        const response = await GetBasketPaymentCallback(checkoutId);
        mutexLock();
        return response;
    } catch (e) {
        mutexLock();
        throw e;
    }
};

export const getCheckout =
    (returnDeliveryMethods = false, returnDeliveryPoints = false) =>
    async (dispatch, getState) => {
        const basketId = await getBasketId()(dispatch, getState);
        const mutexLock = await acquireMutex('basket.getBasket');

        dispatch({ type: GET_CHECKOUT });
        try {
            const response = await GetCheckout(basketId, returnDeliveryMethods, returnDeliveryPoints);

            dispatch({
                type: GET_CHECKOUT_SUCCESS,
                paymentMethods: response.data.payment_methods,
                payments: response.data.payments,
                summary: response.data.basket.format.summary || [],
                deliveryMethods: response.data.delivery_methods || [],
                returnDeliveryMethods,
                shouldOverwriteTextComments: returnDeliveryMethods,
            });
            mutexLock();
            return response;
        } catch (e) {
            dispatch({ type: GET_CHECKOUT_ERROR });
            mutexLock();
            throw e;
        }
    };

export const updatePaymentMethod =
    (paymentMethodId, returnDeliveryMethods = false, returnDeliveryPoints = false, isAuth = false) =>
    async (dispatch, getState) => {
        const basketId = await getBasketId()(dispatch, getState);
        const mutexLock = await acquireMutex('basket.getBasket');

        dispatch({ type: UPDATE_PAYMENT_METHOD });
        try {
            const response = await UpdatePaymentMethod(
                basketId,
                paymentMethodId,
                returnDeliveryMethods,
                returnDeliveryPoints
            );

            dispatch({
                type: UPDATE_PAYMENT_METHOD_SUCCESS,
                isAuth,
                items: response.data.basket.items || [],
                deliveryMethods: response.data.delivery_methods,
                paymentMethods: response.data.payment_methods,
                payments: response.data.payments,
                summary: response.data.basket.format.summary || [],
                textComments: response.data.basket.format.text_comment || [],
                shouldOverwriteTextComments: returnDeliveryMethods,
            });

            mutexLock();

            return response;
        } catch (e) {
            dispatch({ type: UPDATE_PAYMENT_METHOD_ERROR });

            mutexLock();

            throw e;
        }
    };

export const getServicePoints = (deliveryPostCode, methodCode) => async (dispatch, getState) => {
    const mutexLock = await acquireMutex('basket.getBasket');
    const application = getState('application');

    const lifespan = application && get(application, 'config.header.basket.lifespan');
    let shippingProvider;
    let shippingPointCode;
    if (methodCode === BRING_SERVICE_POINT_CODE) {
        shippingProvider = 'bring';
        shippingPointCode = BRING_SERVICE_POINT_CODE;
    } else {
        shippingProvider = 'post_nord';
        shippingPointCode = POSTNORD_SERVICE_POINT_CODE;
    }

    dispatch({ type: GET_SERVICE_POINTS });
    try {
        const response = await GetServicePoints(deliveryPostCode, shippingProvider);
        const servicePoints = Array.isArray(response?.data) ? response.data : response?.data?.service_points;

        const filteredPoints = (servicePoints || []).reduce(
            (result, item) =>
                result.some(({ service_point_id: id }) => id === item.service_point_id) ? result : [...result, item],
            []
        ); // .slice(0, 5)

        dispatch({
            type: GET_SERVICE_POINTS_SUCCESS,
            customerSupportPhone: response.data.customer_support_phone_no,
            servicePoints: filteredPoints,
            deliveryPostCode,
            methodCode: shippingPointCode,
        });

        Cookies.set('delivery_postcode', deliveryPostCode, {
            expires: lifespan ? Number(lifespan) : 28,
        });

        mutexLock();

        return response;
    } catch (e) {
        dispatch({ type: GET_SERVICE_POINTS_ERROR });

        mutexLock();

        throw e;
    }
};

export const updateServicePoint = id => async (dispatch, getState) => {
    const basket = getState().basket;
    const basketId = basket.basketId;
    const mutexLock = await acquireMutex('basket.getBasket');
    const application = getState('application');

    const lifespan = application && get(application, 'config.header.basket.lifespan');

    const shippingInfo =
        id && basket.servicePoints
            ? formatServicePointData(basket.servicePoints.find(i => i.service_point_id === id))
            : '';

    const shippingMethodCode = basket.deliveryMethods?.find(i => i.is_selected)?.code || '';

    const data = {
        infos: [
            {
                code: 'shipping_service_point',
                value: id,
            },
            {
                code: 'shipping_point_info',
                value: shippingInfo,
            },
            {
                code: 'shipping_method_code',
                value: shippingMethodCode,
            },
        ],
    };

    dispatch({ type: UPDATE_SERVICE_POINT });

    try {
        const response = await UpdateBasket(basketId, data);

        if (!response?.data) {
            throw 'Could not update basket';
        }

        const servicePointObject = response.data.info.find(item => item.code === 'shipping_service_point');
        const servicePoint = servicePointObject?.value;

        dispatch({
            type: UPDATE_SERVICE_POINT_SUCCESS,
            servicePoint,
            info: response.data.info,
        });

        if (servicePoint) {
            Cookies.set('shipping_service_point', servicePoint, {
                expires: lifespan ? Number(lifespan) : 28,
            });
        }

        mutexLock();

        return response;
    } catch (e) {
        dispatch({ type: UPDATE_SERVICE_POINT_ERROR });

        mutexLock();

        throw e;
    }
};

export const updateDeliveryMethod =
    (data, returnDeliveryMethods = false, returnDeliveryPoints = false) =>
    async (dispatch, getState) => {
        const basketId = await getBasketId()(dispatch, getState);
        const mutexLock = await acquireMutex('basket.getBasket');
        const application = getState().application;
        const basket = getState().basket;
        const deliveryPostCode = basket.deliveryPostCode || Cookies.get('delivery_postcode');
        const lifespan = application && get(application, 'config.header.basket.lifespan');

        dispatch({ type: UPDATE_DELIVERY_METHOD });
        try {
            const response = await UpdateDeliveryMethod(basketId, data, returnDeliveryMethods, returnDeliveryPoints);

            const formatedDeliveryMethods = response.data.delivery_methods
                ? response.data.delivery_methods
                : basket.deliveryMethods.map(method =>
                      Object.assign(method, { is_selected: method.code === data.code })
                  );

            dispatch({
                type: UPDATE_DELIVERY_METHOD_SUCCESS,
                deliveryMethods: formatedDeliveryMethods,
                items: response.data.basket.items || [],
                paymentMethods: response.data.payment_methods,
                summary: response.data.basket.format.summary || [],
                postcode: data.postal_code || deliveryPostCode,
                methodCode: data.code,
                shouldOverwriteTextComments: returnDeliveryMethods,
            });

            Cookies.set('delivery_postcode', data.postal_code, {
                expires: lifespan ? Number(lifespan) : 28,
            });

            Cookies.set('delivery_method', data.code, {
                expires: lifespan ? Number(lifespan) : 28,
            });

            mutexLock();

            return response;
        } catch (e) {
            dispatch({ type: UPDATE_DELIVERY_METHOD_ERROR });

            mutexLock();

            throw e;
        }
    };

export const updateBasket = (basketId, data, skipEvent) => async dispatch => {
    const mutexLock = await acquireMutex('basket.getBasket');
    dispatch({ type: UPDATE_BASKET });
    try {
        const response = await UpdateBasket(basketId, data);

        if (!response?.data) {
            throw 'Could not update basket';
        }

        dispatch({
            type: UPDATE_BASKET_SUCCESS,
            items: response.data.items || [],
            info: response.data.info,
            comment: response.data.comment,
            summary: response.data.format.summary,
            shouldOverwriteTextComments: false,
        });

        if (!skipEvent) {
            Events.trigger(BasketEvents.UPDATED);
        }
        mutexLock();
    } catch (e) {
        dispatch({ type: UPDATE_BASKET_ERROR });
        mutexLock();
        throw e;
    }
};

// TODO: REMOVE UNUSED STUFF HERE WHEN API READY
export const updateBasketSecret = (basketId, data) => async dispatch => {
    const mutexLock = await acquireMutex('basket.getBasket');
    dispatch({ type: UPDATE_SECRET_BASKET });
    try {
        const response = await UpdateBasketSecret(basketId, data);

        dispatch({
            type: UPDATE_BASKET_SECRET_SUCCESS,
            items: response.data.items || [],
            // price: response.data.price,
            // percantage: response.data.percantage,
            info: response.data.info,
            comment: response.data.comment,
            summary: response.data.format.summary,
            shouldOverwriteTextComments: false,
        });
        Events.trigger(BasketEvents.UPDATED);
        mutexLock();
    } catch (e) {
        dispatch({ type: UPDATE_BASKET_SECRET_ERROR });
        mutexLock();
        throw e;
    }
};

export const updateBasketBuyer =
    (basketId, customerId, companyId, returnDeliveryMethods = false, returnDeliveryPoints = false) =>
    async (dispatch, getState) => {
        if (!basketId) {
            return;
        }

        dispatch({ type: UPDATE_BASKET_BUYER });

        const { updateCustomer } = getState().customer || {};

        try {
            const response = await SetBasketBuyer(
                basketId,
                customerId,
                companyId,
                returnDeliveryMethods,
                returnDeliveryPoints
            );

            const {
                basket: {
                    format: { summary },
                    items,
                },
            } = response.data;

            const newItems = items || [];

            dispatch({
                type: UPDATE_BASKET_BUYER_SUCCESS,
                items: newItems,
                summary,
                customerId: customerId || '',
                companyId: companyId || '',
                shouldOverwriteTextComments: returnDeliveryMethods,
            });

            updateCustomer(companyId);

            // Events.trigger(BasketEvents.SET);
            return true;
        } catch (e) {
            dispatch({ type: UPDATE_BASKET_BUYER_ERROR });
            throw e;
        }
    };

export const resetBasketBuyer =
    (basketId, returnDeliveryMethods = false, returnDeliveryPoints = false) =>
    async dispatch => {
        dispatch({ type: RESET_BASKET_BUYER });

        try {
            const response = await ResetBasketBuyer(basketId, returnDeliveryMethods, returnDeliveryPoints);

            const {
                basket: {
                    format: { summary },
                    items,
                },
            } = response.data;

            const newItems = items || [];

            dispatch({
                type: RESET_BASKET_BUYER_SUCCESS,
                items: newItems,
                summary,
                shouldOverwriteTextComments: returnDeliveryMethods,
            });

            return response.data.basket;
        } catch (e) {
            dispatch({ type: RESET_BASKET_BUYER_ERROR });
        }
    };

export const updateSecretBasketBuyer = (basketId, customerId, companyId) => async (dispatch, getState) => {
    dispatch({ type: UPDATE_SECRET_BASKET_BUYER });
    const { updateCustomer } = getState().customer || {};

    try {
        const response = await SetSecretBasketBuyer(basketId, customerId, companyId);

        const {
            basket: {
                company_id: resCompanyId,
                customer_id: resCustomerId,
                format: { summary },
                items,
            },
        } = response.data;

        const newItems = items || [];

        dispatch({
            type: UPDATE_SECRET_BASKET_BUYER_SUCCESS,
            customerId: resCustomerId || '',
            companyId: resCompanyId || '',
            items: newItems,
            summary,
            shouldOverwriteTextComments: false,
        });

        updateCustomer(companyId, customerId);

        return true;
    } catch (e) {
        dispatch({ type: UPDATE_SECRET_BASKET_BUYER_ERROR });
        throw e;
    }
};

export const addBasketInformation = data => async (dispatch, getState) => {
    const basketId = await getBasketId()(dispatch, getState);
    const mutexLock = await acquireMutex('basket.getBasket');

    try {
        await AddBasketInformation(basketId, data);
        // return response;
        mutexLock();
    } catch (e) {
        mutexLock();
        throw e;
    }
};

export const addBasketPaymentFields = data => async (dispatch, getState) => {
    const mutexLock = await acquireMutex('basket.getBasket');
    const basketId = await getBasketId()(dispatch, getState);
    dispatch({ type: ADD_BASKET_PAYMENT_FIELDS });
    try {
        const response = await AddBasketPaymentFields(basketId, data);
        dispatch({ type: ADD_BASKET_PAYMENT_FIELDS_SUCCESS });

        mutexLock();

        return response;
    } catch (e) {
        dispatch({ type: ADD_BASKET_PAYMENT_FIELDS_ERROR });

        mutexLock();

        throw e;
    }
};

export const isEmpty = () => (dispatch, getState) => {
    const { items } = getState().basket;
    return !items || items.length === 0;
};

export const updateBasketCompanyInfo = skipEvent => async (dispatch, getState) => {
    const basketId = await getBasketId()(dispatch, getState);
    if (!basketId) {
        return;
    }

    const basket = getState().basket;
    const siteIsCompany = !getState().products.showVat;
    const basketIsCompany = Array.isArray(basket.items) ? !!parseInt(getBasketInfo(basket, 'is_company'), 10) : false;

    // Update basket's is_company info type if site settings differ from basket
    if (siteIsCompany !== basketIsCompany) {
        const companyInfo = {
            infos: [
                {
                    code: 'is_company',
                    value: siteIsCompany ? 1 : '',
                },
            ],
        };
        await basket.updateBasket(basketId, companyInfo, skipEvent);
        await basket.deliveryReset(true);
    }

    return;
};

export const deliveryReset =
    (returnDeliveryMethods = false, returnDeliveryPoints = false) =>
    async (dispatch, getState) => {
        const basketId = await getBasketId()(dispatch, getState);
        const mutexLock = await acquireMutex('basket.getBasket');
        dispatch({ type: RESET_DELIVERY_METHOD });
        try {
            const response = await ResetDeliveryMethod(basketId, returnDeliveryMethods, returnDeliveryPoints);
            dispatch({
                type: RESET_DELIVERY_METHOD_SUCCESS,
                deliveryMethods: response.data.delivery_methods,
                paymentMethods: response.data.payment_methods,
            });
            mutexLock();
            return response;
        } catch (e) {
            dispatch({ type: RESET_DELIVERY_METHOD_ERROR });
            mutexLock();
            throw e;
        }
    };

export const updateItemDiscounts = (basketId, data) => async (dispatch, getState) => {
    const mutexLock = await acquireMutex('basket.getBasket');

    dispatch({ type: UPDATE_ITEM_DISCOUNTS });

    try {
        const discounts = getState().basket.discounts;

        const discountsCopy = JSON.parse(JSON.stringify(discounts));

        if (data.id) {
            if (discountsCopy.items[data.id] && !parseInt(data.value, 10)) {
                delete discountsCopy.items[data.id];
            } else {
                discountsCopy.items[data.id] = data.value;
            }
        } else {
            discountsCopy.basket = data.value;
        }

        const formData = {
            [getDiscountType(discountsCopy.basket)]: discountsCopy.basket,
            items: Object.entries(discountsCopy.items).map(([id, value]) => ({
                id,
                [getDiscountType(value)]: value,
            })),
        };

        const response = await UpdateItemDiscounts(basketId, formData);

        dispatch({
            type: UPDATE_ITEM_DISCOUNTS_SUCCESS,
            items: response.data.items || [],
            discounts: discountsCopy,
            summary: response.data.format.summary || [],
        });

        Events.trigger(BasketEvents.UPDATED);

        mutexLock();
    } catch (error) {
        dispatch({ type: UPDATE_ITEM_DISCOUNTS_ERROR });
        mutexLock();
        throw error;
    }
};
