import React from 'react';
import PropTypes from 'prop-types';
import ScrollRestore from 'core-web/components/ScrollRestore';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';

const Wrapper = styled('div')`
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    background: ${colors.bgGrey};
`;

const SiteWrapper = ({ children }) => (
    <Wrapper>
        <ScrollRestore />
        {children}
    </Wrapper>
);

SiteWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SiteWrapper;
