import { post } from 'core-web/libs/grebbcommerce-api/util/authClient';
import { getModel } from 'core-web/state';
import { auth } from 'core-web/util/firebase';

export default async (basketId, data) => {
    const formData = {
        'application_id': getModel('application').applicationId,
    };

    if (data.comment) {
        formData.comment = data.comment;
    }
    if (data.price) {
        formData.price = data.price;
    }
    if (data.percentage) {
        formData.percentage = data.percentage;
    }
    if (data.price === 0) {
        formData.price = data.price;
    }
    if (data.percentage === 0) {
        formData.percentage = data.percentage;
    }

    const token = await auth.currentUser.getIdToken();

    const response = await post(`/frontend/secret/${basketId}`, formData, { 'Authorization': `Bearer ${token}` });

    return await response.body();
};
