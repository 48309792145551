import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import Button, { Sizes, Theme } from 'core-web/components/Button';
import colors from 'theme/config/colors';

const propTypes = {
    borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    fontFamily: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    isOutlined: PropTypes.bool,
    letterSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    theme: PropTypes.oneOf(['green', 'cyan', 'black']).isRequired,
    size: PropTypes.oneOfType([PropTypes.oneOf(['small', 'medium', 'large']), PropTypes.array]),
};

type Props = InferProps<typeof propTypes>;

interface Themes {
    contained: {
        [key: string]: Theme;
    };
    outlined: {
        [key: string]: Theme;
    };
}

const THEMES: Themes = {
    contained: {
        'green': {
            color: colors.white,
            backgroundColor: colors.green,
            borderColor: colors.green,
            hover: {
                backgroundColor: colors.lightGreen,
                duration: '300ms',
                timingFunction: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
            },
            disabled: {
                color: colors.textGrey,
                backgroundColor: colors.grey,
                borderColor: colors.grey,
            },
        },
        'cyan': {
            color: colors.white,
            backgroundColor: colors.cyan,
            borderColor: colors.cyan,
            hover: {
                backgroundColor: colors.lightCyan,
                duration: '300ms',
                timingFunction: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
            },
            disabled: {
                color: colors.textGrey,
                backgroundColor: colors.grey,
                borderColor: colors.grey,
            },
        },
        'black': {
            color: colors.white,
            backgroundColor: colors.black,
            borderColor: colors.black,
            hover: {
                backgroundColor: colors.darkGrey,
                duration: '300ms',
                timingFunction: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
            },
            disabled: {
                color: colors.textGrey,
                backgroundColor: colors.grey,
                borderColor: colors.grey,
            },
        },
    },
    outlined: {
        'green': {
            color: colors.green,
            backgroundColor: 'transparent',
            borderColor: colors.green,
        },
        'cyan': {
            color: colors.cyan,
            backgroundColor: 'transparent',
            borderColor: colors.cyan,
        },
    },
};

const SIZES: Sizes = {
    small: {
        height: '28px',
        fontSize: '12px',
        padding: '0 12px',
    },
    medium: {
        height: '38px',
        fontSize: '14px',
        padding: '0 12px',
    },
    large: {
        height: '46px',
        fontSize: '14px',
        padding: '0 16px',
    },
};

const RoundedButton: React.FC<Props> = ({ isOutlined, theme, ...rest }) => {
    const btnTheme = THEMES[isOutlined ? 'outlined' : 'contained'][theme] || {};

    return <Button theme={btnTheme} sizes={SIZES} {...rest} />;
};

RoundedButton.propTypes = propTypes;

RoundedButton.defaultProps = {
    borderRadius: '54px',
    fontFamily: 'Akkurat',
    fontWeight: '700',
    isOutlined: false,
    letterSpacing: '0.02em',
    lineHeight: '1em',
    theme: 'green',
    size: ['small', null, 'large'],
};

export default RoundedButton;
