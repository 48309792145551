export default {
    // Grey
    grey: '#DCDCDC',
    bgGrey: '#EEEEEE',
    textGrey: '#BEBEBE',
    lightGrey: '#EAEAEA',
    darkGrey: '#3A3F44',
    hoverDarkGrey: '#2c2c2c',
    darkGreyTint: '#303030',
    // Green
    green: '#81A434',
    lightGreen: '#8bb038',
    easyPeasyGreen: '#19B14B',
    reviewsGreen: '#00B67A',
    // Orange
    orange: '#e88300',
    lightOrange: '#FB701A',
    darkOrange: '#C75B20',
    // Other
    black: '#000000',
    white: '#FFFFFF',
    cyan: '#3F9EAB',
    lightCyan: '#44AAB8',
    // Core shared colors
    inStock: '#00BA2C',
    isBookable: '#FFF200',
    outOfStock: '#FA6000',
};
