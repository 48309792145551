import algoliasearch from 'algoliasearch';
import { getModel } from 'core-web/state';

const APPLICATION_ID = process.env.REACT_APP_ALGOLIA_APPLICATION_ID;
const API_KEY = process.env.REACT_APP_ALGOLIA_API_KEY;
const PREFIX = process.env.REACT_APP_ALGOLIA_PREFIX || '';

const getClient = () => algoliasearch(APPLICATION_ID, API_KEY);
const indices = {};

// @todo: The _se part should not be in here. It's temporary.
const getIndexName = (name, suffix) => `${PREFIX ? `${PREFIX}` : ''}${name}${suffix ? `${suffix}` : ''}`;

// @todo: What should happen if the index requested does not exist?
const getIndex = (type, suffix = null) => {
    if (suffix === null) {
        const application = getModel('application');
        suffix = `_${application.locale}`;
    }

    // Build the index name.
    const indexName = getIndexName(type, suffix.toLowerCase());

    // Check if the index already exists in indices.
    if (indexName in indices) {
        return indices[indexName];
    }

    const client = getClient();
    const index = client.initIndex(indexName);

    // Push it in indices.
    indices[indexName] = index;

    return index;
};

export const MultiQuery = async queries => {
    const searchQueries = [];
    queries.forEach(query => {
        // prep filters for algolia
        if (query.params && query.params.filters && query.params.filters.length > 0) {
            query.params.filters = query.params.filters.map(filter => `(${filter.join(' OR ')})`).join(' AND ');
        } else {
            query.params && query.params.filters && delete query.params.filters;
        }

        // algolia start pages on 0, we expect components start on page 1
        query.params && query.params.page--;

        searchQueries.push(query);
    });

    return new Promise((resolve, reject) => {
        try {
            const client = getClient();

            client.search(searchQueries, (error, result) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(
                        result.results.map(r => {
                            let hasMore = false;
                            if (r.page !== undefined) {
                                hasMore = (r.page + 1) * r.hitsPerPage < r.nbHits;
                            } else {
                                hasMore = r.offset + r.length < r.nbHits;
                            }

                            return {
                                index: r.index,
                                filters: r.facets,
                                filter_stats: r.facets_stats,
                                hits: r.hits,
                                hitsCount: r.nbHits,
                                pageSize: r.hitsPerPage,
                                pageCount: r.nbPages,
                                page: r.page,
                                hasMore,
                            };
                        })
                    );
                }
            });
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
};

// @todo: The case when the index is not available must be handled.
export const Search = async (type, query, parameters = [], suffix = null) => {
    const index = getIndex(type, suffix);

    return new Promise((resolve, reject) => {
        const myParameters = parameters;
        if (myParameters && myParameters.filters && myParameters.filters.length > 0) {
            myParameters.filters = myParameters.filters.map(filter => `(${filter.join(' OR ')})`).join(' AND ');
        } else {
            delete myParameters.filters;
        }

        if (myParameters && myParameters.pagination) {
            if (typeof myParameters.pagination.skip !== 'undefined') {
                myParameters.offset = myParameters.pagination.skip;
                myParameters.length = myParameters.pagination.length > 1000 ? 1000 : myParameters.pagination.length;
            } else {
                myParameters.page = (myParameters.pagination.page && myParameters.pagination.page - 1) || 0;
                myParameters.hitsPerPage = myParameters.pagination.pageSize;
            }

            delete myParameters.pagination;
        }

        try {
            index.search(
                {
                    query,
                    clickAnalytics: true,
                    ...myParameters,
                },
                (error, content) => {
                    if (error) {
                        reject(error);
                    } else {
                        let hasMore = false;
                        if (content.page !== undefined) {
                            hasMore = (content.page + 1) * content.hitsPerPage < content.nbHits;
                        } else {
                            hasMore = content.offset + content.length < content.nbHits;
                        }

                        resolve({
                            filters: content.facets,
                            filter_stats: content.facets_stats,
                            hits: content.hits,
                            hitsCount: content.nbHits,
                            pageSize: content.hitsPerPage,
                            pageCount: content.nbPages,
                            page: content.page,
                            hasMore,
                            facets: content.facets || {},
                            facetsStats: content.facets_stats || {},
                        });
                    }
                }
            );
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
};

/* export const Browse = async (type, options = {}, suffix = null) => {
    const index = getIndex(type, suffix);
}; */

// @todo: This isn't perfect right now? Haven't tested it enough, or at all? Lol.
export const GetCategoryProducts = (categoryId, parameters = {}, suffix = null) => {
    if (!suffix) {
        const application = getModel('application');
        suffix = `_${application.locale}`;
    }

    if (parameters && parameters.filters && parameters.filters.length > 0) {
        parameters.filters = parameters.filters.concat([[`category:"${categoryId}"`]]);
    } else {
        parameters.filters = [[`category:"${categoryId}"`]];
    }

    return Search('products', '', parameters, suffix);
};

export const GetSparePartsByObjectsIds = async objectIds => {
    const index = getIndex('spare_parts');

    return new Promise((resolve, reject) => {
        try {
            index.getObjects(objectIds, (error, content) => {
                if (error) {
                    reject(error);
                } else {
                    resolve({
                        hits: content.results.length,
                        results: content.results,
                    });
                }
            });
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
};

export const GetByObjectsIds = async objectIds => {
    const index = getIndex('products');

    return new Promise((resolve, reject) => {
        try {
            index.getObjects(objectIds, (error, content) => {
                if (error) {
                    reject(error);
                } else {
                    resolve({
                        hits: content.results.length,
                        results: content.results,
                    });
                }
            });
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
};

// const mapFacets = (results, facetNames) => {
//     let facets = {};
//     Object.entries(results[0].facets).forEach(value => {
//         facets[value[0]] = {};
//         facets[value[0]].values = value[1];
//         if (results[0].facets_stats[value[0]]) {
//             facets[value[0]].stats = results[0].facets_stats[value[0]];
//         }
//     });
//
//     for (let i = 0; i < results.length; i++) {
//         results[i].facets &&
//             Object.keys(results[i].facets).forEach(key => {
//                 if (!facets[key]) {
//                     facets[key] = {};
//                 }
//
//                 // override with client facets
//                 if (i > 0 && i < results.length - 1) {
//                     facets[key] = { ...results[i].facets[key] };
//                 }
//             });
//     }
//
//     Object.keys(facets).forEach(key => {
//         const application = getModel('application');
//
//         // todo move config
//         let config = {
//             sv_SE: {
//                 'pricing.pricelists.web.incVat': {
//                     name: 'Pris',
//                     description: 'description',
//                 },
//             },
//         };
//
//         // set facetName
//         const index = facetNames.find(facet => 'parametrics.' + facet.parametric + '.value.value' === key);
//         facets[key].name = index ? index.name : config[application.languages[0].locale][key].name;
//         facets[key].code = facets[key].code;
//         facets[key].description = index ? index.description : config[application.languages[0].locale][key].description;
//
//         if (facets[key].stats) {
//             facets[key].min = facets[key].stats.min;
//             facets[key].max = facets[key].stats.max;
//             delete facets[key].stats;
//         } else {
//             facets[key].values = facets[key].values;
//         }
//     });
//
//     return facets;
// };
