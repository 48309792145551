import React from 'react';
import PropTypes from 'prop-types';

import SVG from './base/SVG';

const TwitterIcon = ({ color, ...props }) => (
    <SVG fill="none" viewBox="0 0 32 32" {...props}>
        <g clipPath="url(#twitter)">
            <path
                fill={color}
                fillRule="evenodd"
                d="M28 0a4 4 0 014 4v24a4 4 0 01-4 4H4a4 4 0 01-4-4V4a4 4 0 014-4h24zM12.3 26.5a13 13 0 0013.2-13.1v-.6c.9-.7 1.6-1.5 2.3-2.4-.9.4-1.8.6-2.7.7 1-.5 1.7-1.4 2-2.5-.9.5-1.8.9-2.9 1-.8-.8-2-1.4-3.4-1.4a4.6 4.6 0 00-4.5 5.7c-3.8-.2-7.2-2-9.5-4.8a4.6 4.6 0 001.5 6.1c-.8 0-1.5-.2-2.1-.5 0 2.3 1.6 4.1 3.7 4.6a4.6 4.6 0 01-2.1 0 4.6 4.6 0 004.3 3.2 9.3 9.3 0 01-6.8 2c2 1.3 4.4 2 7 2z"
                clipRule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="twitter">
                <path fill={color} d="M0 0h32v32H0z" />
            </clipPath>
        </defs>
    </SVG>
);

TwitterIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

TwitterIcon.defaultProps = {
    color: 'currentColor',
    height: '32px',
    width: '32px',
};

export default TwitterIcon;
