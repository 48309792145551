import store from 'core-web/state/store';
import { search, clear, queryDefault } from './actions';

export default {
    search: (query, history, location) => {
        store.dispatch(search(query, history, location));
    },

    clear: (history, location) => {
        store.dispatch(clear(history, location));
    },

    queryDefault: location => {
        store.dispatch(queryDefault(location));
    },
};
