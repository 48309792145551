import React from 'react';
import PropTypes from 'prop-types';
import styled from 'theme/libs/styled';
import variables from 'theme/config/variables';
import PageTransitionOverlay from 'core-web/components/PageTransitionOverlay';

const Wrapper = styled('main')`
    width: 100%;
    flex: 1 0 auto;
    height: auto;
    min-height: 100vh;
`;

const ContentWrapper = ({ children, ...props }) => (
    <Wrapper role="main" {...props}>
        <PageTransitionOverlay />
        {children}
    </Wrapper>
);

ContentWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    maxWidth: PropTypes.string,
    mx: PropTypes.string,
};

ContentWrapper.defaultProps = {
    maxWidth: variables.maxWidth,
    mx: 'auto',
};

export default ContentWrapper;
