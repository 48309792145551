import React from 'react';
import PropTypes from 'prop-types';

import SVG from './base/SVG';

const YouTubeIcon = ({ color, ...props }) => (
    <SVG fill="none" viewBox="0 0 32 32" {...props}>
        <g clipPath="url(#youtube)">
            <path
                fill={color}
                fillRule="evenodd"
                d="M28 0a4 4 0 014 4v24a4 4 0 01-4 4H4a4 4 0 01-4-4V4a4 4 0 014-4h24zm-1.2 11c-.3-1-1-1.8-2-2-1.8-.5-8.8-.5-8.8-.5s-7 0-8.8.5c-1 .2-1.7 1-2 2-.5 1.7-.5 5.4-.5 5.4s0 3.7.5 5.4c.3 1 1 1.7 2 2 1.8.5 8.8.5 8.8.5s7 0 8.8-.5c1-.3 1.7-1 2-2 .4-1.7.4-5.4.4-5.4s0-3.7-.4-5.4zm-13 8.8V13l5.8 3.4-5.9 3.4z"
                clipRule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="youtube">
                <path fill={color} d="M0 0h32v32H0z" />
            </clipPath>
        </defs>
    </SVG>
);

YouTubeIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

YouTubeIcon.defaultProps = {
    color: 'currentColor',
    height: '32px',
    width: '32px',
};

export default YouTubeIcon;
