import { buildQueryString, post } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async checkoutId => {
    const data = {
        payment_parameters: [
            {
                'Name': 'checkoutId',
                'Value': checkoutId,
            },
            {
                'Name': 'PaymentService',
                'Value': 'KlarnaCheckoutV3',
            },
        ],
    };
    const built_parameters = buildQueryString({ 'application_id': getModel('application').applicationId });
    return await post(`/frontend/payment/callback${built_parameters && `?${built_parameters}`}`, data);
};
