import { post } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async (basketID, returnDeliveryMethods = false, returnDeliveryPoints = false) => {
    return await post(`/frontend/checkout/${basketID}/delivery/reset`, {
        'application_id': getModel('application').applicationId,
        'return_delivery_methods': returnDeliveryMethods,
        'return_delivery_points': returnDeliveryPoints,
    });
};
