import { NEWSLETTER_SUBSCRIBE, NEWSLETTER_SUBSCRIBE_SUCCESS, NEWSLETTER_SUBSCRIBE_ERROR } from './constants';

import { Subscribe } from 'core-web/libs/GrebbCommerceAPI/Newsletter';

export const subscribe = (listId, data) => async dispatch => {
    // @todo: should we pass anything more back to the model from the
    // response from the subscribe to newsletter request?
    dispatch({ type: NEWSLETTER_SUBSCRIBE });
    try {
        const response = await Subscribe(listId, data);
        dispatch({ type: NEWSLETTER_SUBSCRIBE_SUCCESS });
        return response;
    } catch (e) {
        const error = await e;
        dispatch({
            error: error.message,
            type: NEWSLETTER_SUBSCRIBE_ERROR,
        });
        throw e;
    }
};
