export const resetStyle = `

    /**
     * Change from box-sizing: content-box so that width is not affected by padding or border.
     */
    *,
    *::before,
    *::after {
    box-sizing: border-box; /* 1 */
    }

    /**
     * Remove input clear cross in IE
     */
    ::-ms-clear {
        display: none;
    }

    /**
     * Reset button styles.
     * It takes a bit of work to achieve a neutral look.
     */
    button {
        padding: 0;
        border: none;
        font: inherit;
        color: inherit;
        background-color: transparent;
        cursor: pointer;
        outline: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        &:active, &:focus {
            outline: 0;
        }
    }

    /**
     * Links
     */
    a {
        color: inherit;
        text-decoration: none;
    }

    p, h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }

    ul {
        list-style-type: none;
        margin: 0; padding: 0;
    }
`;
