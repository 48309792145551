import GTM from '@grebban/gtm';
import { getModel } from 'core-web/state';
import { getProduct } from './utils/product';

export default async ({ basketId, item }) => {
    if (!item) {
        return;
    }

    const application = getModel('application');

    GTM.addDataLayerEvent('add_to_cart', {
        ecommerce: {
            basket: { id: basketId },
            add: {
                products: [getProduct(item)],
            },
        },
        currency: application.currency.code,
    });
};
