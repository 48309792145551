// @todo: if this grows... maybe separate the constans into more
// files and use index as a collector import/export etc
export const APP_NODE_NAMESPACE = '___REACT_APP_NAMESPACE_GREBBAN__';
export const EMOTION_IDS_KEY = '__EMOTION_IDS__';
export const INITIAL_STATE_KEY = '__INITIAL_STATE__';
export const INITIAL_PROMISES_CONTEXT = '__INITIAL_PROMISES_CONTEXT__';
export const SERVER_RENDER_BREAKPOINT = '__SERVER_RENDER_BREAKPOINT__';
export const MOBILE_KEY = 'isMobile';
export const SITE_DATA_CACHE_EXPIRATION = 60 * 1000; // 1 minute
export const POST_DATA_CACHE_EXPIRATION = 60 * 1000; // 1 minute
export const BRAND_COUNT_CACHE_EXPIRATION = 60 * 60 * 1000; // 1 hour
export const RESOLVE_URL_DATA_CACHE_EXPIRATION = 60 * 1000; // 1 minute
export const API_ENDPOINT = '/api/v1';

// Boolean to tell if we're in production-environment or not.
export const inProduction = process.env.NODE_ENV === 'production';
// Boolean to tell if we should build for SSR
export const inServer = process.env.BUILD_TARGET === 'server';
export const inBrowser = typeof window !== 'undefined';

export const API_URL =
    (inServer && process.env.REACT_APP_GREBBCOMMERCE_API_URL_SSR) || process.env.REACT_APP_GREBBCOMMERCE_API_URL;

export const FORCE_API_URL = process.env.REACT_APP_FORCE_API_URL === 'true';