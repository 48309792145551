import { get, request } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';
import { API_URL, inServer } from 'core-web/constants';

export default async (partNo, parameters = {}, url = null, includeVariants = true) => {
    const path = includeVariants ? `/frontend/product/no/${partNo}` : `/frontend/product/no/${partNo}/parent`;

    if (!parameters || typeof parameters !== 'object') {
        parameters = {};
    }

    if (!parameters.hasOwnProperty('pricelist_seed')) {
        const pricelistSeed = getModel('customer').pricelistSeed;

        if (pricelistSeed) {
            parameters['pricelist_seed'] = pricelistSeed;
        }
    } else if (!parameters['pricelist_seed']) {
        delete parameters['pricelist_seed'];
    }

    if (
        inServer &&
        process.env.REACT_APP_ALLOWED_DOMAINS &&
        process.env.REACT_APP_ALLOWED_DOMAINS.indexOf(url) === -1 &&
        API_URL
    ) {
        return await request({
            method: 'GET',
            path,
            data: parameters,
            baseUrl: API_URL,
        });
    }

    if (!API_URL && inServer && url) {
        return await request({
            method: 'GET',
            path,
            data: parameters,
            baseUrl: url,
        });
    }

    return await get(path, {
        'application_id': getModel('application').applicationId,
        ...parameters,
    });
};
