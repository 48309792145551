import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

const LanguageLinks = ({ links }) => (
    <Helmet>
        {links.map(({ id, uri, locale }) => {
            if (!uri) {
                return null;
            }

            if (uri.toLowerCase().indexOf('//') === 0) {
                uri = `https:${uri}`;
            }

            const lang = locale.substr(0, 2);

            return <link key={id} rel="alternate" href={uri} hrefLang={lang} />;
        })}
    </Helmet>
);

LanguageLinks.propTypes = {
    links: PropTypes.array.isRequired,
};

export default LanguageLinks;
