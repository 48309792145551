import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import get from 'core-web/util/get';
import { ContentModules } from 'core-web/ContentModules';
import PageContentWrapper from 'theme/components/wrappers/PageContentWrapper';

const StandardHero = loadable(() => import('theme/content-modules/StandardHero'));

const Page = ({ data }) => {
    const heroContent = get(data, 'hero_content.data');
    const pageContent = data.page_content;

    return (
        <PageContentWrapper>
            {heroContent && <StandardHero content={heroContent} />}
            {pageContent && <ContentModules modules={pageContent} />}
        </PageContentWrapper>
    );
};

Page.propTypes = {
    data: PropTypes.object.isRequired,
};

export default Page;

export { default as templates } from './templates';
