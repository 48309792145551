// Page
export const RESOLVE_SLUG = 'RESOLVE_SLUG';
export const RESOLVE_SLUG_SUCCESS = 'RESOLVE_SLUG_SUCCESS';
export const RESOLVE_SLUG_ERROR = 'RESOLVE_SLUG_ERROR';

export const RESOLVE_REDIRECT = 'RESOLVE_REDIRECT';
export const RESOLVE_REDIRECT_SUCCESS = 'RESOLVE_REDIRECT_SUCCESS';
export const RESOLVE_REDIRECT_ERROR = 'RESOLVE_REDIRECT_ERROR';

export const RESOLVE_SEARCH_SUCCESS = 'RESOLVE_SEARCH_SUCCESS';

// Get Product By ID.
export const GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID';
export const GET_PRODUCT_BY_ID_SUCCESS = 'GET_PRODUCT_BY_ID_SUCCESS';
export const GET_PRODUCT_BY_ID_ERROR = 'GET_PRODUCT_BY_ID_ERROR';
