import { post, buildQueryString } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async (basketId, country) => {
    const data = {
        country,
    };
    const built_parameters = buildQueryString({ 'application_id': getModel('application').applicationId });
    return await post(`/frontend/basket/${basketId}/payment-fields${built_parameters && `?${built_parameters}`}`, data);
};
