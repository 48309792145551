import { post } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async (basketId, returnDeliveryMethods = false, returnDeliveryPoints = false) => {
    if (!basketId) {
        return;
    }

    return await post(`/frontend/checkout/${basketId}/buyer/reset`, {
        'application_id': getModel('application').applicationId,
        'return_delivery_methods': returnDeliveryMethods,
        'return_delivery_points': returnDeliveryPoints,
    });
};
