import { del } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async (basketId, paymentCode) => {
    const { applicationId } = getModel('application');

    const data = {
        'application_id': applicationId,
        'payment_parameters': [
            {
                'Name': 'paymentcode',
                'Value': paymentCode,
            },
            {
                'Name': 'PaymentService',
                'Value': 'Retain24',
            },
        ],
    };

    return await del(`/frontend/basket/gift-card/${basketId}/reserve`, data);
};
