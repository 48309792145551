import store from 'core-web/state/store';
import { clear, search } from './actions';

export { default as FilterSearchReducers } from './reducers';

export default {
    search: (query, history, location) => {
        store.dispatch(search(query, history, location));
    },
    clear: (history, location) => {
        store.dispatch(clear(history, location));
    },
};
