import { buildQueryString, get } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';
import tracking from 'core-web/components/Tracking';

export default async basketId => {
    const trackingData = tracking.getTrackingData();

    const data = {
        ...trackingData,
    };

    const parameters = buildQueryString({ 'application_id': getModel('application').applicationId });
    return await get(`/frontend/checkout/${basketId}/confirmation${parameters && `?${parameters}`}`, data);
};
