// Get Products.
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';

// Set All Products.
export const SET_ALL_PRODUCTS = 'SET_ALL_PRODUCTS';
export const SET_ALL_PRODUCTS_SUCCESS = 'SET_ALL_PRODUCTS_SUCCESS';
export const SET_ALL_PRODUCTS_ERROR = 'SET_ALL_PRODUCTS_ERROR';
export const SET_NEXT_FETCH_MODE = 'SET_NEXT_FETCH_MODE';

// Set Compare Products.
export const SET_COMPARE_PRODUCTS = 'SET_COMPARE_PRODUCTS';
export const SET_COMPARE_PRODUCTS_SUCCESS = 'SET_COMPARE_PRODUCTS_SUCCESS';
export const SET_COMPARE_PRODUCTS_ERROR = 'SET_COMPARE_PRODUCTS_ERROR';

// Set Last Compare Product.
export const SET_COMPARE_PRODUCT_LAST = 'SET_COMPARE_PRODUCT_LAST';
export const SET_COMPARE_PRODUCT_LAST_SUCCESS = 'SET_COMPARE_PRODUCT_LAST_SUCCESS';
export const SET_COMPARE_PRODUCT_LAST_ERROR = 'SET_COMPARE_PRODUCT_LAST_ERROR';

// Get Category Products.
export const GET_CATEGORY_PRODUCTS = 'GET_CATEGORY_PRODUCTS';
export const GET_CATEGORY_PRODUCTS_SUCCESS = 'GET_CATEGORY_PRODUCTS_SUCCESS';
export const GET_CATEGORY_PRODUCTS_ERROR = 'GET_CATEGORY_PRODUCTS_ERROR';

// Filters.
export const APPLY_FILTER = 'APPLY_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

// Search.
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const SEARCH_PRODUCTS_SUCCESS = 'SEARCH_PRODUCTS_SUCCESS';
export const SEARCH_PRODUCTS_ERROR = 'SEARCH_PRODUCTS_ERROR';

// Sort
export const APPLY_SORT = 'APPLY_SORT';
export const CLEAR_SORT = 'CLEAR_SORT';

// Show/hide VAT
export const SHOW_VAT = 'SHOW_VAT';

// Page dependencies
export const RESOLVE_PAGE_DEPENDENCIES = 'RESOLVE_PAGE_DEPENDENCIES';
export const RESOLVE_PAGE_DEPENDENCIES_SUCCESS = 'RESOLVE_PAGE_DEPENDENCIES_SUCCESS';
export const RESOLVE_PAGE_DEPENDENCIES_ERROR = 'RESOLVE_PAGE_DEPENDENCIES_ERROR';

// Set category tree
export const SET_CATEGORY_TREE = 'SET_CATEGORY_TREE';
export const SET_CATEGORY_TREE_SUCCESS = 'SET_CATEGORY_TREE_SUCCESS';
export const SET_CATEGORY_TREE_ERROR = 'SET_CATEGORY_TREE_ERROR';
