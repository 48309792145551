import React from 'react';
import PropTypes from 'prop-types';

import SVG from './base/SVG';

const InstagramIcon = ({ color, ...props }) => (
    <SVG fill="none" viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#instagram)">
            <path
                clipRule="evenodd"
                fill={color}
                fillRule="evenodd"
                d="M21.07 0A2.93 2.93 0 0124 2.93v18.14A2.93 2.93 0 0121.07 24H2.93A2.93 2.93 0 010 21.07V2.93A2.93 2.93 0 012.93 0h18.14zM12 3.8c-2.23 0-2.5 0-3.38.05-.88.04-1.47.17-2 .38-.53.2-.99.49-1.45.94-.45.46-.73.92-.94 1.46-.2.52-.34 1.11-.38 1.99-.04.87-.05 1.15-.05 3.38s0 2.5.05 3.38c.04.88.17 1.47.38 2 .2.53.49.99.94 1.45.46.45.92.73 1.46.94.52.2 1.11.34 1.99.38.87.04 1.15.05 3.38.05s2.5 0 3.38-.05a6.02 6.02 0 002-.38c.53-.2.99-.49 1.45-.94.45-.46.73-.92.94-1.46.2-.52.34-1.11.38-1.99.04-.87.05-1.15.05-3.38s0-2.5-.05-3.38a6.02 6.02 0 00-.38-2c-.2-.53-.49-.99-.94-1.45a4.02 4.02 0 00-1.46-.94 6.02 6.02 0 00-1.99-.38c-.87-.04-1.15-.05-3.38-.05zm0 1.47c2.19 0 2.45.01 3.31.05.8.04 1.24.17 1.53.29.38.14.65.32.94.6.29.3.47.57.61.95.12.3.25.73.29 1.53.04.86.05 1.12.05 3.31s-.01 2.45-.05 3.31c-.04.8-.17 1.24-.29 1.53-.14.38-.32.65-.6.94-.3.29-.57.47-.95.61-.3.12-.73.25-1.53.29-.86.04-1.12.05-3.31.05s-2.45-.01-3.31-.05a4.54 4.54 0 01-1.53-.29 2.54 2.54 0 01-.94-.6 2.54 2.54 0 01-.61-.95 4.54 4.54 0 01-.29-1.53c-.04-.86-.05-1.12-.05-3.31s.01-2.45.05-3.31c.04-.8.17-1.24.29-1.53.14-.38.32-.65.6-.94.3-.29.57-.47.95-.61.3-.12.73-.25 1.53-.29.86-.04 1.12-.05 3.31-.05zm0 2.52a4.21 4.21 0 100 8.42 4.21 4.21 0 000-8.42zm0 6.94a2.73 2.73 0 110-5.46 2.73 2.73 0 010 5.46zm5.36-7.1a.98.98 0 11-1.97 0 .98.98 0 011.97 0z"
            />
        </g>
        <defs>
            <clipPath id="instagram">
                <path fill={color} d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </SVG>
);

InstagramIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

InstagramIcon.defaultProps = {
    color: 'currentColor',
    height: '32px',
    width: '32px',
};

export default InstagramIcon;
