import cloneDeep from 'core-web/util/cloneDeep';
import { countryToIsoMap, formatPostalCodes } from './multimarket';
import { trimObjectValues } from './trimObjectValues';

interface Params {
    payer: {
        email: string;
        phone: string | null;
        cell_phone: string | null;
        first_name: string;
        last_name: string;
        invoice_address: {
            zip: string;
            line1: string;
            country: string;
            city: string;
        };
    };
}
export default (orderData: Params) => {
    const trimmedData = trimObjectValues(cloneDeep(orderData.payer));
    const {
        email,
        phone,
        cell_phone: cellPhone,
        first_name: firstName,
        last_name: lastName,
        invoice_address: invoiceAddress,
    } = trimmedData;

    const customerData = { email };
    if (phone || cellPhone) {
        customerData['phone_number'] = phone || cellPhone;
    }
    if (firstName && lastName && invoiceAddress?.country && invoiceAddress?.zip) {
        customerData['address'] = {};
        customerData['address']['first_name'] = firstName;
        customerData['address']['last_name'] = lastName;
        customerData['address']['postal_code'] = formatPostalCodes(invoiceAddress?.zip, invoiceAddress?.country);

        customerData['address']['country'] = countryToIsoMap[invoiceAddress?.country];

        if (invoiceAddress?.line1) {
            customerData['address']['street'] = invoiceAddress.line1;
        }
        if (invoiceAddress?.city) {
            customerData['address']['city'] = invoiceAddress.city;
        }
        // 'region': // Province, state or region, Example "Californaia" or "CA"
    }

    return customerData;
};
