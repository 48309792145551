// Redux
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { INITIAL_STATE_KEY } from 'core-web/constants';
import application from './models/Application/reducers';
import checkout from './models/Checkout/reducers';
import customer from './models/Customer/reducers';
import newsletter from './models/Newsletter/reducers';
import overlay from './models/Overlay/reducers';
import search from './models/Search/reducers';
import basket from './models/basket/reducers';
import filterSearch from './models/filterSearch/reducers';
import page from './models/page/reducers';
import products from './models/products/reducers';

const reducer = combineReducers({
    application,
    basket,
    checkout,
    customer,
    filterSearch,
    newsletter,
    overlay,
    page,
    products,
    search,
});

export const initiateStore = (initialState, middleWares = []) =>
    createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunk, ...middleWares)));

let store = null;

// added so we don't error out when compiling for SSR
if (typeof window !== 'undefined') {
    store = store || initiateStore(window[INITIAL_STATE_KEY]);
    delete window[INITIAL_STATE_KEY];
} else {
    // When we are creating a store for the SSR..
    store = store || initiateStore();
}

export default store;
