// Resolve.
export const RESOLVE_APP = 'RESOLVE_APP';
export const RESOLVE_APP_SUCCESS = 'RESOLVE_APP_SUCCESS';
export const RESOLVE_APP_ERROR = 'RESOLVE_APP_ERROR';

// Set current href/pathname, needed for SSR
export const SET_CURRENT_HREF = 'SET_CURRENT_HREF';
export const SET_ORIGIN = 'SET_ORIGIN';
export const SET_CURRENT_PATHNAME = 'SET_CURRENT_PATHNAME';
export const SET_PROTOCOL = 'SET_PROTOCOL';

// Set href before visiting store
export const SET_PATH_BEFORE_STORE = 'SET_PATH_BEFORE_STORE';

export const SET_STATE = 'SET_STATE';
