import { Search } from 'core-web/libs/Algolia-v2';
import { getModel } from 'core-web/state';

const getReplaceProduct = async (id: string, index: string) => {
    try {
        const locale = getModel('application').locale.toLowerCase();
        const response = await Search(`${index}_${locale}`, id, {
            restrictSearchableAttributes: ['manufacturerPartNo', 'objectID'],
            disableTypoToleranceOnAttributes: ['manufacturerPartNo', 'objectID'],
        });

        return response.results[0].hits.find(
            (hit: { manufacturerPartNo: string; objectID: string }) =>
                hit.manufacturerPartNo === id || hit.objectID === id
        );
    } catch (error) {
        return null;
    }
};

export default getReplaceProduct;
