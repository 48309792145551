import Cookies from 'js-cookie';
import { post } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async (basketId, paymentParameters, provider = 'collector') => {
    const { applicationId, config } = getModel('application');
    const cmsPaths = config.options.paths;

    const termsPath = cmsPaths.terms_conditions;
    const checkoutPath = cmsPaths.checkout;
    const checkoutThanksPath = cmsPaths.thankyou;
    const checkoutTermsPath = cmsPaths.checkout_terms;

    const data = {
        'application_id': applicationId,
        'ip_address': '127.0.0.1',
        'user_agent': 'grebban',
        'payment_parameters': {
            'checkouturl': `${window.location.protocol}//${window.location.host}${checkoutPath}`,
            'termsurl': `${window.location.protocol}//${window.location.host}${checkoutTermsPath}`,
            'confirmationurl': `${window.location.protocol}//${window.location.host}${checkoutThanksPath}?method=${provider}&basketId=${basketId}`,
            'StormCompleteUrl': `${window.location.protocol}//${window.location.host}${checkoutThanksPath}`,
            'ShopCallbackUrl': `${window.location.protocol}//${window.location.host}${checkoutThanksPath}?token={checkout.publictoken}&method=${provider}&basketId=${basketId}`,
            'locale': 'sv-SE',
            checkoutId: Cookies.get('checkoutId') || null,
        },
    };

    if (typeof paymentParameters === 'object') {
        Object.assign(data.payment_parameters, paymentParameters);
    }

    if (termsPath) {
        data['payment_parameters']['ShopTermsUrl'] = `${window.location.protocol}//${window.location.host}${termsPath}`;
    }

    return await post(`/frontend/payment/${basketId}`, data);
};
