import { get } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async (storeId: string, checkoutId: string) => {
    return await get(`/frontend/collector/information`, {
        'application_id': getModel('application').applicationId,
        'store_id': storeId,
        'checkout_id': checkoutId,
    });
};
