import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import Gtm from '@grebban/gtm';
import Cookies from 'js-cookie';
import Events from 'core-web/libs/Events';
import getCountrySpecificVariable from 'core-web/util/getCountrySpecificVariable';
import tracking from 'core-web/components/Tracking';

export default () => {
    const gtmKey = process.env.REACT_APP_GTM_TRACKING_KEY;

    // If there is no GTM Tracking key, we should not load GTM.
    if (!gtmKey) {
        return null;
    }

    // Gather redux values used in gtm package
    const applicationPath = useSelector(({ application }) => application.path || '');
    const applicationLocale = useSelector(({ application }) => application.locale);
    const applicationId = useSelector(({ application }) => application.applicationId);
    const applicationUrl = useSelector(({ application }) => application.getCurrentHref() || '');
    const applicationDomain = useSelector(({ application }) => application.origin);
    const applicationCurrency = useSelector(({ application }) => application.currency.id);

    useEffect(() => {
        const eventsWithHandles = tracking.trackingEvents();

        // Initiate the tracking events
        eventsWithHandles.forEach(event => {
            event.handle = Events.subscribe(event.name, event.callback);
        });

        // Unsubscribe on demount
        return () =>
            eventsWithHandles.forEach(event => {
                Events.unsubscribe(event.name, event.handle);
            });
    }, []);

    const ONETRUST_HYMA_COOKIE = process.env.REACT_APP_ONETRUST_HYMA_COOKIE;

    // Custom eventlistener that saves optanonConsentData for easier access in other files
    useEffect(() => {
        const handler = window.addEventListener('click', e => {
            if (
                e.target.id === 'onetrust-accept-btn-handler' ||
                e.target.id === 'onetrust-recommended-btn-handler' ||
                e.target.id === 'accept-recommended-btn-handler' ||
                (typeof e.target.className === 'string' &&
                    e.target.className.indexOf('save-preference-btn-handler') > -1)
            ) {
                // Get active groups from Onetrust
                const groups = window.OnetrustActiveGroups.split(',').filter(v => v);

                // Save selected cookies for later
                Cookies.set(ONETRUST_HYMA_COOKIE, JSON.stringify(groups), { expires: 365 });

                // Dispatch event to update scripts right away
                window.dispatchEvent(new CustomEvent('optanonConsent', { detail: groups }));
            }
        });

        return () => window.removeEventListener('click', handler);
    }, []);

    // Gather dependency data to pass into GTM package
    const dependencies = [];

    const ONETRUST_SCRIPT_ID = getCountrySpecificVariable('REACT_APP_ONETRUST_SCRIPT_ID', applicationLocale);
    if (ONETRUST_SCRIPT_ID) {
        dependencies.push({
            name: 'OneTrust',
            domainKey: ONETRUST_SCRIPT_ID,
        });
    }

    return (
        <Gtm
            dependencies={dependencies}
            helmetComponent={Helmet}
            trackingKey={gtmKey}
            initialVariables={{
                currency: applicationCurrency,
                locale: applicationLocale,
                site_id: applicationId,
                url: applicationUrl,
                domain: applicationDomain,
                path: applicationPath,
            }}
        />
    );
};
