import {
    GET_PRODUCT_BY_ID,
    GET_PRODUCT_BY_ID_ERROR,
    GET_PRODUCT_BY_ID_SUCCESS,
    RESOLVE_REDIRECT,
    RESOLVE_REDIRECT_ERROR,
    RESOLVE_REDIRECT_SUCCESS,
    RESOLVE_SEARCH_SUCCESS,
    RESOLVE_SLUG,
    RESOLVE_SLUG_ERROR,
    RESOLVE_SLUG_SUCCESS,
} from './constants';

// The initial state of this store.
const initialState = {
    isFetching: null,
    data: null,
    type: null,
    template: null,
    relationships: null,
    search: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        // Resolve slug
        case RESOLVE_SLUG:
            return {
                ...state,
                isFetching: true,
            };
        case RESOLVE_SLUG_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.pageData ? action.pageData : null,
                type: action.pageType ? action.pageType : null,
                template: action.pageTemplate ? action.pageTemplate : null,
                relationships: action.pageRelationships ? action.pageRelationships : null,
                search: action.pageSearch,
            };
        case RESOLVE_SLUG_ERROR:
            return {
                ...state,
                ...initialState,
                isFetching: false,
            };

        // Resolve redirect
        case RESOLVE_REDIRECT:
            return {
                ...state,
                isFetching: true,
            };
        case RESOLVE_REDIRECT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.pageData ? action.pageData : null,
                type: action.pageType ? action.pageType : null,
            };
        case RESOLVE_REDIRECT_ERROR:
            return {
                ...state,
                ...initialState,
                isFetching: false,
            };

        case RESOLVE_SEARCH_SUCCESS:
            return {
                ...state,
                search: action.pageSearch,
            };

        // Productpage
        case GET_PRODUCT_BY_ID: {
            return {
                ...state,
            };
        }
        case GET_PRODUCT_BY_ID_SUCCESS: {
            const newState = {
                ...state,
            };
            newState.data.product = {
                ...action.product,
            };
            if (action.alternates) {
                newState.data.alternates = action.alternates;
            }
            return newState;
        }
        case GET_PRODUCT_BY_ID_ERROR: {
            return {
                ...state,
            };
        }

        default:
            return state;
    }
};
