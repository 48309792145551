import store from 'core-web/state/store';
import { getProductPrices, initCustomer, logout, setCustomer, updateCustomer } from './actions';

export { default as CustomerReducers } from './reducers';
export default {
    initCustomer: customer => {
        return store.dispatch(initCustomer(customer));
    },
    setCustomer: customer => {
        return store.dispatch(setCustomer(customer));
    },
    getProductPrices: products => {
        return store.dispatch(getProductPrices(products));
    },
    updateCustomer: (companyId, customerId) => {
        return store.dispatch(updateCustomer(companyId, customerId));
    },
    logout: () => {
        return store.dispatch(logout());
    },
};
