const toFixedCurrency = (no: number, dec: number, separatorTypeComma = true) => {
    if ((1.005).toFixed(2) == '1.01') {
        return no.toFixed(dec);
    }
    const split = no.toString().split('.');

    if (split[1] && split[1].length === 1) {
        split[1] += '0';
    }

    const number = +(!split[1] ? split[0] : `${split.join('.')}1`);
    const fixedNumber = number.toFixed(dec);
    return separatorTypeComma ? fixedNumber.replace('.', ',') : fixedNumber;
};

export default toFixedCurrency;

/**
 * "pollyfill" for toFixed since it breaks in some JS versions.
 * For exmaple: (14.995).toFixed(2) becomes 14.99 instead of 15.
 * https://www.sitepoint.com/number-tofixed-rounding-errors-broken-but-fixable/
 *
 * @param {number} no - number to be fixed
 * @param {number} dec - number of decimals
 * @param {boolean} separatorTypeComma - if decimal point in number should be replaced by comma.
 */
