import { get, request } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';
import { API_URL, inServer } from 'core-web/constants';

export default async (partNo, parameters = {}, url) => {
    if (process.env.REACT_APP_ALLOWED_DOMAINS && process.env.REACT_APP_ALLOWED_DOMAINS.indexOf(url) === -1 && API_URL) {
        return await request({ method: 'GET', path: url, baseUrl: API_URL });
    }

    if (!API_URL && inServer && url) {
        return await request({
            method: 'GET',
            path: `/frontend/product/no/${partNo}/onhand`,
            data: parameters,
            baseUrl: url,
        });
    }

    return await get(`/frontend/product/no/${partNo}/onhand`, {
        'application_id': getModel('application').applicationId,
        ...parameters,
    });
};
