import GetProductByPartNo from 'core-web/libs/GrebbCommerceAPI/Products/GetProductByPartNo';

export const getPrimaryCategory = categories => (categories || []).find(item => item.format.is_primary);

export const getBreadcrumbs = (categories = []) => {
    const primaryCategory = getPrimaryCategory(categories);
    if (!primaryCategory) {
        return [];
    }
    return (categories || []).filter(c => c.format.root_code === primaryCategory.code && c.format.permalink);
};

export const productBreadcrumbs = (categories = []) => {
    return getBreadcrumbs(categories).map(category => {
        return {
            code: category.code,
            permalink: category.format.permalink,
            title: category.format.name,
        };
    });
};

export const formattedUrl = (categories = [], uniqueName) => {
    try {
        const bredcrumbs = getBreadcrumbs(categories);
        const sortedBreadcrumbs = bredcrumbs.sort((a, b) => b.format.level - a.format.level);
        const baseUrl = sortedBreadcrumbs[0]?.format?.permalink || '/produkt';
        return `${baseUrl}/${uniqueName}`.toLowerCase();
    } catch (error) {
        return '';
    }
};

export const getRelatedProducts = async partNos => {
    try {
        const response = await Promise.all(partNos.map(GetProductByPartNo));
        if (response) {
            const relatedProducts = response.map(({ data }) => data);

            // Remove Variants and just keep 1 with variants-array
            return relatedProducts.reduce((unique, item) => {
                if (!item.variants.length) {
                    unique.push(item);
                    return unique;
                }

                const findDuplicate = unique.find(({ variants }) =>
                    variants.some(variant => variant.part_no === item.part_no)
                );
                if (findDuplicate) {
                    return unique;
                }
                item['isVariant'] = true;
                unique.push(item);
                return unique;
            }, []);
        }
    } catch (e) {
        console.error(e);
    }
    return [];
};
