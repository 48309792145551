import { post } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';
import tracking from 'core-web/components/Tracking';

export default async (basketId, items, pricelistSeed = '') => {
    const trackingData = tracking.getTrackingData();
    const parameters = {
        ...trackingData,
    };

    if (!pricelistSeed) {
        pricelistSeed = getModel('customer').pricelistSeed || '';
    }

    if (pricelistSeed) {
        parameters['pricelist_seed'] = pricelistSeed;
    }

    return await post(`/frontend/basket/${basketId}/package`, {
        'application_id': getModel('application').applicationId,
        items,
        ...parameters,
    });
};
