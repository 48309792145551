import {
    RESOLVE_APP,
    RESOLVE_APP_ERROR,
    RESOLVE_APP_SUCCESS,
    SET_CURRENT_HREF,
    SET_CURRENT_PATHNAME,
    SET_ORIGIN,
    SET_PATH_BEFORE_STORE,
    SET_PROTOCOL,
    SET_STATE,
} from './constants';

// The initial state of this store.
const initialState = {
    isFetching: false,
    applicationId: null,
    currentHref: '',
    origin: '',
    currentPathname: '/',
    pathBeforeStore: '/',
    customer: null,
    applicationName: null,
    path: null,
    config: {},
    priceGroups: [],
    currency: null,
    languages: [],
    locale: null,
    state: null,
    device: null,
};

export { initialState };

export default (state = initialState, action) => {
    switch (action.type) {
        case RESOLVE_APP:
            return {
                ...state,
                isFetching: true,
            };
        case RESOLVE_APP_SUCCESS:
            return {
                ...state,
                isFetching: false,
                applicationId: action.application.id,
                applicationName: action.application.name,
                path: action.application.path,
                config: action.config,
                priceGroups: action.priceGroups,
                currency: action.currency,
                languages: action.languages,
                locale: action.application.locale,
                device: action.device,
                shippingSettings: action.shippingSettings,
            };
        case RESOLVE_APP_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        case SET_CURRENT_HREF:
            return {
                ...state,
                currentHref: action.currentHref,
            };
        case SET_ORIGIN:
            return {
                ...state,
                origin: action.origin,
            };
        case SET_PROTOCOL:
            return {
                ...state,
                protocol: action.protocol,
            };
        case SET_CURRENT_PATHNAME:
            return {
                ...state,
                currentPathname: action.currentPathname,
            };
        case SET_PATH_BEFORE_STORE:
            return {
                ...state,
                pathBeforeStore: action.pathBeforeStore,
            };
        case SET_STATE:
            return {
                ...state,
                state: action.state,
            };
        default:
            return state;
    }
};
