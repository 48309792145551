import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { imgixSrc } from 'core-web/libs/image';
import { injectModels } from 'core-web/state';
import decodeHTMLEntities from 'core-web/util/decodeHTMLEntities';
import get from 'core-web/util/get';
import getQueryParams from 'core-web/util/getQueryParams';
import { getHreflangs, getLanguages } from 'core-web/util/multimarket';
import LanguageLinks from 'core-web/components/Meta/LanguageLinks';
import { APP_ORGANIZATION } from 'theme/config/constants';

const structuredDatas = {
    'webpage': loadable(() => import('core-web/components/Meta/StructuredData/WebPageStructuredData')),
    'website': loadable(() => import('core-web/components/Meta/StructuredData/WebsiteStructuredData')),
};

const returnRobotString = arr =>
    arr.length
        ? arr
              .filter(x => x)
              .join(', ')
              .trim()
        : null;

const isPresent = prop => {
    return prop && prop !== '' && prop !== '0' && prop.length > 0;
};

const buildCanonicalUrl = (page, application, query, isPaginationPage) => {
    const {
        data: {
            seo: { canonical },
            permalink,
        },
    } = page;
    const { origin, protocol } = application;
    const baseUrl = (protocol || '') + (origin || '');

    let url = baseUrl + permalink.toLowerCase();

    if (canonical) {
        url = baseUrl + canonical.toLowerCase();
    }

    const canonicalQuery = `?${Object.entries(query)
        .filter(q => (q[0] === 'page' && q[1] > 1) || q[0] === 'category')
        .map(q => q.join('='))
        .join('&')}`;

    if (isPaginationPage && canonicalQuery?.length > 1) {
        url += canonicalQuery;
    }

    return url || null;
};

const subDesc = string => {
    return string.length >= 160
        ? string
              .replace(/<\/{0,1}[^>]+>/g, '')
              .slice(0, 157)
              .concat('...')
        : string.replace(/<\/{0,1}[^>]+>/g, '');
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const PageMeta = ({ page, application, location }) => {
    const { t } = useTranslation();

    if (!page.data || !page.data.seo || !application || !location) {
        return null;
    }

    let title = null;
    let description = null;
    let keywords = null;
    let noIndex = null;
    let noFollow = null;
    let ogTitle = null;
    let ogDescription = null;
    let ogImage = null;
    let ogUrl = null;
    let ogType = 'website';
    let robots = null;
    const ogLocale = application?.locale || null;
    const appName = process.env.REACT_APP_NAME || '';
    const pageData = page.data || {};
    const postType = pageData.post_type || '';
    const seoData = pageData.seo || {};
    const pageSettings = pageData.settings || {};
    const query = getQueryParams(location);
    const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
    const protocol = application.protocol || 'https://';
    const { origin } = application;
    const baseUrl = protocol + (origin || '');
    const isCategoryPage = page.type === 'product_category';

    title = isPresent(seoData.title) ? seoData.title : isPresent(pageData.title) ? pageData.title : null;

    description = isPresent(seoData.description)
        ? subDesc(seoData.description)
        : isPresent(seoData.og_description)
        ? subDesc(seoData.og_description)
        : null;

    if (!description && postType === 'post') {
        description = isPresent(pageData.ingress) ? subDesc(pageData.ingress) : null;
    }

    if (!description && postType === 'product_category') {
        description = isPresent(pageData.settings?.header_data?.top_description)
            ? subDesc(pageData.settings.header_data.top_description)
            : null;
    }

    const isPagination =
        isCategoryPage ||
        page.type === 'manufacturer' ||
        page.template === 'article_landingpage' ||
        page.template === 'search';

    const canonical = buildCanonicalUrl(page, application, query, isPagination);

    keywords = isPresent(seoData.keywords) ? seoData.keywords : null;

    ogUrl = isPresent(canonical) && baseUrl ? baseUrl + canonical : null;

    ogTitle = isPresent(seoData.og_title) ? seoData.og_title : title;
    ogDescription = isPresent(seoData.og_description) ? subDesc(seoData.og_description) : description;
    const rawOgImageUrl = isPresent(seoData.og_image) ? seoData.og_image : null;

    if (rawOgImageUrl) {
        const strippedImageUrl = rawOgImageUrl.match(/q=[0-9]+&w=[0-9]+&h=[0-9]+&auto=[a-zA-Z]+/g)
            ? rawOgImageUrl.substring(0, rawOgImageUrl.lastIndexOf('?q='))
            : rawOgImageUrl;

        ogImage = imgixSrc(strippedImageUrl, {
            q: 70,
            w: 1200,
            h: 630,
            auto: 'format',
        });
    }

    noIndex = isPresent(seoData.no_index) ? 'noindex' : null;
    noFollow = isPresent(seoData.no_follow) ? 'nofollow' : null;

    const isDuab = appName === 'duab';
    const isMk = appName === 'maskinklippet';
    const isDg = appName === 'dogger';
    const isFrishop = appName === 'frishop';
    const allowedApps = isMk || isDuab || isDg || isFrishop;

    if (allowedApps && (isCategoryPage || page.type === 'manufacturer')) {
        // Insert page number in SEO title
        if (query.page && query.page > 1) {
            title = `${t('page')} ${query.page} | ${title}`;
        }

        // If header description exist, use it as SEO description
        const headerDescription = get(
            pageSettings,
            `header_data.${isMk || isDg || isFrishop ? 'top_description' : 'description'}`
        );
        if (headerDescription && headerDescription.length) {
            description = subDesc(headerDescription.replace(/(\r\n|\n|\r)/gm, ''));
        }
    }

    if (isDuab && page.type === 'manufacturer') {
        if (title.length < 40 && isPresent(pageData.title)) {
            title = t('seo_meta.manufacturer.title', { title: decodeHTMLEntities(pageData.title) });
        }

        if (!isPresent(description) && isPresent(pageData.title)) {
            description = t('seo_meta.manufacturer.description', { title: decodeHTMLEntities(pageData.title) });
        }
    }

    if (isDuab && isCategoryPage && !isPresent(description) && isPresent(pageData.title)) {
        description = t('seo_meta.category.description', { title: decodeHTMLEntities(pageData.title) });
    }

    if (page.type === 'post') {
        ogType = 'article';
    }

    if (appName === 'hylte' && application.locale !== 'sv_SE') {
        if (title.indexOf(APP_ORGANIZATION)) {
            title = title.replace(APP_ORGANIZATION, t('site_title'));
        }

        if (ogTitle.indexOf(APP_ORGANIZATION)) {
            ogTitle = ogTitle.replace(APP_ORGANIZATION, t('site_title'));
        }
    }

    robots = returnRobotString([noIndex, noFollow]);

    const alternateHrefs = page?.data?.alternates || null;
    let hreflangs;

    if (alternateHrefs) {
        const languages = getLanguages(application.languages, ogLocale);
        hreflangs = getHreflangs(alternateHrefs, languages, page);
    }

    let breadcrumbs = page?.data?.breadcrumbs;
    if (breadcrumbs) {
        breadcrumbs = breadcrumbs.map((item, index) => {
            return {
                '@type': 'ListItem',
                position: index + 1,
                name: decodeHTMLEntities(item.title),
                item: protocol + origin + item.permalink,
            };
        });

        breadcrumbs.push({
            '@type': 'ListItem',
            position: breadcrumbs.length + 1,
            name: decodeHTMLEntities(page.data.title),
        });
    }

    const componentProps = {};
    let StructuredDataComponent;

    if (page.data.permalink === '/') {
        StructuredDataComponent = structuredDatas.website;
        componentProps.application = application;
    } else if (page.type !== 'post') {
        StructuredDataComponent = structuredDatas.webpage;
        Object.assign(componentProps, { breadcrumbs, name: title, description });
    }

    // check if the last character is a number to hide hrefLang after page 1 for SEO purposes
    const lastCharacter = ogUrl.charAt(ogUrl.length - 1);
    const showHrefLang = !/\d/.test(lastCharacter);

    return (
        <>
            <Helmet>
                {title && <title>{decodeHTMLEntities(title)}</title>}
                {canonical && <link rel="canonical" href={canonical} />}
                {description && <meta name="description" content={decodeHTMLEntities(description)} />}
                {keywords && <meta name="keywords" content={keywords} />}
                {robots && <meta name="robots" content={robots} />}
                {ogTitle && <meta property="og:title" content={ogTitle} />}
                {ogDescription && <meta property="og:description" content={ogDescription} />}
                {ogType && <meta property="og:type" content={ogType} />}
                {ogUrl && <meta property="og:url" content={ogUrl} />}
                {ogImage && <meta property="og:image" content={ogImage} />}
                {ogLocale && <meta property="og:locale" content={ogLocale} />}
                {facebookAppId && <meta property="fb:app_id" content={facebookAppId} />}
            </Helmet>

            {StructuredDataComponent && <StructuredDataComponent {...componentProps} />}

            {showHrefLang && hreflangs && <LanguageLinks links={hreflangs} showHrefLang={showHrefLang} />}
        </>
    );
};

PageMeta.propTypes = {
    application: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
};

export default withRouter(injectModels(['application', 'page'])(PageMeta));
