import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { inServer } from 'core-web/constants';
// Volatile : If true, don't touch this, let it be.
const Link = forwardRef(({ isVolatile, to, ...parentProps }, ref) => {
    if (!to && !isVolatile) {
        return parentProps.children;
    }

    let LinkRenderer = RouterLink;
    const type = typeof to;
    let decodedTo = to;

    const scrollWithOffset = (el, behavior) => {
        if (!inServer) {
            const y = el.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: y, behavior });
        }
    };

    if (to && type !== 'function') {
        // Check if it's an external link.
        const pathname = type === 'object' ? to.pathname || to : to;

        // Replace amp to handle navigation with filter params
        const decodedPath = pathname?.replace(/&amp;/g, '&');
        decodedTo = type === 'object' ? { ...to, pathname: decodedPath } : decodedPath;

        if (pathname && pathname.indexOf('/') !== 0) {
            // Probably not an internal link.
            LinkRenderer = ({ target, rel, isVolatile, ...props }) => {
                // For anchor links
                if (pathname.indexOf('#') === 0) {
                    return (
                        <HashLink
                            {...props}
                            ref={ref}
                            to={(decodedTo && decodedTo) || '/#'}
                            scroll={el => scrollWithOffset(el, 'smooth')}
                        />
                    );
                }
                return (
                    <a href={pathname} target={target || '_blank'} rel={rel || 'noopener'} {...props}>
                        {props.children}
                    </a>
                );
            };
        } else if (pathname && pathname.indexOf('#') !== -1) {
            return (
                <HashLink
                    {...parentProps}
                    ref={ref}
                    to={(decodedTo && decodedTo) || '/#'}
                    scroll={el => scrollWithOffset(el, 'auto')}
                />
            );
        }
    }

    return <LinkRenderer {...parentProps} ref={ref} to={(decodedTo && decodedTo) || '/#'} />;
});

Link.propTypes = {
    children: PropTypes.node,
    isVolatile: PropTypes.bool,
    rel: PropTypes.string,
    target: PropTypes.string,
    to: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func]),
};

Link.defaultProps = {
    children: null,
    isVolatile: false,
    rel: null,
    target: null,
    to: false,
};

export default Link;
