import fontFamilies from 'theme/config/fontFamilies';
import { css } from 'emotion';

import acumin from './acumin';
import akkurat from './akkurat';

export const fontString = `
    @font-face {
        font-family: ${fontFamilies.acumin};
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url('${acumin.semiBold.eot}');
        src: local('Acumin'), local('Acumin'),
            url('${acumin.semiBold.eot}?#iefix') format('embedded-opentype'),
            url('${acumin.semiBold.woff2}') format('woff2'),
            url('${acumin.semiBold.woff}') format('woff');
    }

    @font-face {
        font-family: ${fontFamilies.akkurat};
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url('${akkurat.light.eot}');
        src: local('Akkurat Light'), local('Akkurat-Light'),
            url('${akkurat.light.eot}?#iefix') format('embedded-opentype'),
            url('${akkurat.light.woff2}') format('woff2'),
            url('${akkurat.light.woff}') format('woff');
    }

    @font-face {
        font-family: ${fontFamilies.akkurat};
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('${akkurat.regular.eot}');
        src: local('Akkurat Regular'), local('Akkurat-Regular'),
            url('${akkurat.regular.eot}?#iefix') format('embedded-opentype'),
            url('${akkurat.regular.woff2}') format('woff2'),
            url('${akkurat.regular.woff}') format('woff');
    }

    @font-face {
        font-family: ${fontFamilies.akkurat};
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('${akkurat.bold.eot}');
        src: local('Akkurat Bold'), local('Akkurat-Bold'),
            url('${akkurat.bold.eot}?#iefix') format('embedded-opentype'),
            url('${akkurat.bold.woff2}') format('woff2'),
            url('${akkurat.bold.woff}') format('woff');
    }
`;

export default css(fontString);
