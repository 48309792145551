import { post } from 'core-web/libs/grebbcommerce-api/util/authClient';
import { getModel } from 'core-web/state';
import { auth } from 'core-web/util/firebase';

export default async (basketId, customerId, companyId) => {
    if (!basketId) {
        return;
    }

    const token = await auth.currentUser.getIdToken();

    const parameters = {
        'application_id': getModel('application').applicationId,
        'customer_id': customerId || '',
        'company_id': companyId || '',
    };

    const response = await post(`/frontend/secret/${basketId}/buyer`, parameters, {
        'Authorization': `Bearer ${token}`,
    });

    return await response.body();
};
