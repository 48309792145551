export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const LOGIN_EMAIL = 'LOGIN_EMAIL';
export const LOGIN_EMAIL_SUCCESS = 'LOGIN_EMAIL_SUCCESS';
export const LOGIN_EMAIL_ERROR = 'LOGIN_EMAIL_ERROR';

export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR';

export const LOGIN_FACEBOOK = 'LOGIN_FACEBOOK';
export const LOGIN_FACEBOOK_SUCCESS = 'LOGIN_FACEBOOK_SUCCESS';
export const LOGIN_FACEBOOK_ERROR = 'AUTH_FACBOOK_ERROR';

export const LOGIN_GOOGLE = 'LOGIN_GOOGLE';
export const LOGIN_GOOGLE_SUCCESS = 'LOGIN_GOOGLE_SUCCESS';
export const LOGIN_GOOGLE_ERROR = 'LOGIN_GOOGLE_ERROR';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const SET_USER = 'SET_USER';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER ';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS ';
export const SET_INIT_USER_SUCCESS = 'SET_INIT_USER_SUCCESS';
