import React, { Component } from 'react';
import styled from 'theme/libs/styled';
import scroll from 'core-web/libs/scroll';
import { injectModel } from 'core-web/state';

const Wrapper = styled('div')`
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 100000;

    .slide-in--top,
    .slide-in--left,
    .slide-in--right {
        transition: transform ${({ duration }) => duration}ms cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    &.entering,
    &.exited,
    &.exiting {
        .slide-in--top {
            transform: translate3d(0, -100%, 0);
        }
        .slide-in--left {
            transform: translate3d(-100%, 0, 0);
        }
        .slide-in--right {
            transform: translate3d(100%, 0, 0);
        }
        .fade {
            opacity: 0;
        }
    }

    &.entered {
        .slide-in--top,
        .slide-in--left,
        .slide-in--right {
            transform: translate3d(0, 0, 0);
        }

        .fade {
            opacity: 1;
        }
    }
`;

const Background = styled('div')`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    transition: opacity ${({ duration }) => duration}ms linear;
`;

class OverlayWrapper extends Component {
    componentDidMount() {
        scroll.preventScroll();

        document.addEventListener('click', this.handleClick, false);
        document.addEventListener('touchend', this.handleClick, false);

        window.onpopstate = e => {
            this.props.overlay.hide();
        }
    }

    componentWillUnmount() {
        scroll.allowScroll();

        document.removeEventListener('click', this.handleClick, false);
        document.removeEventListener('touchend', this.handleClick, false);
    }
    // keep this for now, remove before launch
    // handleClick = e => {
    //     e.stopPropagation();
    //     if (
    //         !this.node ||
    //         this.node.contains(e.target) ||
    //         e.defaultPrevented
    //     ) {
    //         return;
    //     }
    //     this.props.overlay.hide();
    // };
    closeOverlay = () => {
        this.props.overlay.hide();
    };

    render() {
        const { children, className, duration = 350 } = this.props;
        return (
            <Wrapper className={className} duration={duration}>
                <Background
                    ref={node => (this.node = node)}
                    className="fade"
                    duration={duration}
                    onClick={this.closeOverlay}
                />
                {children}
            </Wrapper>
        );
    }
}

export default injectModel('overlay')(OverlayWrapper);
