import { useEffect, useRef, useState, useCallback } from 'react';

// @todo: fix support for treshold as array
interface ObserverOptions {
    root?: HTMLElement | null;
    rootMargin?: string;
    threshold?: number;
    triggerOnce?: boolean;
}
interface ObserverEntry {
    boundingClientRect: DOMRectReadOnly | null;
    intersectionRatio: number;
    intersectionRect: DOMRectReadOnly | null;
    isIntersecting: boolean;
    rootBounds: DOMRectReadOnly | null;
    target: Element | null;
    time: typeof global.DOMHighResTimeStamp;
}

export default (options: ObserverOptions = {}): [ObserverEntry, (element: HTMLElement) => void] => {
    const { root = null, rootMargin = '0px 0px 0px 0px', threshold = 0, triggerOnce = false } = options;

    const [entry, setEntry] = useState<ObserverEntry>({
        boundingClientRect: null,
        intersectionRatio: 0,
        intersectionRect: null,
        isIntersecting: false,
        rootBounds: null,
        target: null,
        time: 0,
    });

    const observer = useRef<IntersectionObserver | null>(null);

    const setNode = useCallback(
        node => {
            if (observer.current) {
                observer.current.disconnect();
            }

            observer.current = new IntersectionObserver(
                ([entry], self) => {
                    setEntry(entry);

                    if (triggerOnce && entry.intersectionRatio > 0) {
                        self.unobserve(entry.target);
                    }
                },
                {
                    root,
                    rootMargin,
                    threshold,
                }
            );

            observer.current.disconnect();

            if (node) {
                observer.current.observe(node);
            }
        },
        [threshold, root, rootMargin, triggerOnce]
    );

    useEffect(() => {
        return () => (observer.current ? observer.current.disconnect() : undefined);
    }, []);

    return [entry, setNode];
};
