import { post } from 'core-web/libs/grebbcommerce-api/util/authClient';
import { getModel } from 'core-web/state';

export default async (basketId, cardNo, cvc) => {
    const { applicationId } = getModel('application');

    const data = {
        'application_id': applicationId,
        'card_no': cardNo,
        cvc,
        'ip_address': '127.0.0.1',
        'user_agent': 'grebban',
    };

    const response = await post(`/frontend/basket/gift-card/${basketId}/reserve`, data);
    return await response.body();
};
