// Models
import Application from './Application';
import Checkout from './Checkout';
import Customer from './Customer';
import Newsletter from './Newsletter';
import Overlay from './Overlay';
import Search from './Search';
import basket from './basket';
import filterSearch from './filterSearch';
import page from './page';
import products from './products';

export default {
    application: Application,
    basket,
    checkout: Checkout,
    customer: Customer,
    filterSearch,
    newsletter: Newsletter,
    overlay: Overlay,
    page,
    products,
    search: Search,
};
