import GTM from '@grebban/gtm';

export default ({ option, isAuth }) => {
    GTM.addDataLayerEvent(`checkout_option_shipping${isAuth ? '_auth' : ''}`, {
        ecommerce: {
            checkout: {
                actionField: { step: 1, option },
            },
        },
    });
};
