// Trims whitespace of all strings in an object
export const trimObjectValues = obj => {
    for (const prop in obj) {
        if (typeof obj[prop] === 'string') {
            obj[prop] = obj[prop].trim();
        } else if (typeof obj[prop] === 'object') {
            trimObjectValues(obj[prop]);
        }
    }
    return obj;
};
