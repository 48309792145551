import { get } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async (id, parameters = {}) => {
    if (!parameters.hasOwnProperty('pricelist_seed')) {
        const pricelistSeed = getModel('customer').pricelistSeed;

        if (pricelistSeed) {
            parameters['pricelist_seed'] = pricelistSeed;
        }
    }

    return await get(`/frontend/product/${id}/qtybreak`, {
        'application_id': getModel('application').applicationId,
        ...parameters,
    });
};
