import { put } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';
import tracking from 'core-web/components/Tracking';

export default async (basketId, lineNo, quantity) => {
    const trackingData = tracking.getTrackingData();
    const data = {
        'application_id': getModel('application').applicationId,
        quantity,
        ...trackingData,
    };

    return await put(`/frontend/basket/${basketId}/package/${lineNo}`, data);
};
