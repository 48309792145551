import { get } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async (partNos = [], pricelistSeed = '') => {
    // Sort partNos before passing
    const sortedPartNos = partNos
        .filter((v, i) => partNos.indexOf(v) === i)
        .sort((a, b) => a * 1 - b * 1)
        .join(',');

    const parameters = {
        'application_id': getModel('application').applicationId,
        'part_no_seed': sortedPartNos,
    };

    if (!pricelistSeed) {
        pricelistSeed = getModel('customer').pricelistSeed;
    }

    parameters['pricelist_seed'] = pricelistSeed;

    return await get('/frontend/products/no/prices', parameters);
};
