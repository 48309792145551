import React from 'react';
import PropTypes from 'prop-types';

import SVG from './base/SVG';

const FacebookIcon = ({ color, ...props }) => (
    <SVG fill="none" viewBox="0 0 32 32" {...props}>
        <path
            fill={color}
            fillRule="evenodd"
            d="M28 0a4 4 0 014 4v24a4 4 0 01-4 4h-6.6V20h4.2l.8-5.2h-5v-3.4c0-1.4.7-2.8 3-2.8h2.2V4.2s-2-.3-4-.3c-4 0-6.7 2.5-6.7 7v3.9h-4.6v5.1H16V32h-12A4 4 0 010 28V4a4 4 0 014-4h24z"
            clipRule="evenodd"
        />
    </SVG>
);

FacebookIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

FacebookIcon.defaultProps = {
    color: 'currentColor',
    height: '32px',
    width: '32px',
};

export default FacebookIcon;
