import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { injectModels } from 'core-web/state';
import decodeHTMLEntities from 'core-web/util/decodeHTMLEntities';
import { above, below } from 'core-web/util/mediaqueries';
import Image from 'core-web/components/Image';
import Link from 'core-web/components/Link';
import colors from 'theme/config/colors';
import { NEWSLETTER_SIGNUP_FOOTER } from 'theme/config/constants';
import variables from 'theme/config/variables';
import styled from 'theme/libs/styled';
import FacebookIcon from 'theme/components/icons/FacebookIcon';
import InstagramIcon from 'theme/components/icons/InstagramIcon';
import TwitterIcon from 'theme/components/icons/TwitterIcon';
import YouTubeIcon from 'theme/components/icons/YouTubeIcon';
import NewsletterSignup from 'theme/components/newsletter/NewsletterSignup';

const Li = styled('li')();
const Div = styled('div')();
const Flex = styled('div')({ display: 'flex' });
const Button = styled('button')();

const Wrapper = styled('footer')`
    width: 100%;
    display: flex;
    flex-direction: column;
    color: ${colors.lightGrey};
    padding: 32px 16px;

    & > div:not(:first-of-type):not(:last-of-type) {
        margin-bottom: 48px;
    }

    ${above.md} {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
`;

const Heading = styled('h3')`
    font: 700 16px/1em 'Akkurat';
    margin-bottom: 24px;
`;

const Navigation = styled('div', { shouldForwardProp: prop => prop !== 'items' })`
    ${above.lg} {
        flex: ${({ items }) => (items > 7 ? '2' : '1')};
    }

    ${above.xl} {
        flex: ${({ items }) => Math.ceil(items / 4)};
    }
`;

const NavigationList = styled('ul')`
    line-height: 1em;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 48px;
    height: 100%;

    ${above.lg} {
        max-height: 14em;
    }

    ${below.xl} {
        li:not(:last-of-type) {
            margin-bottom: 16px;
        }
    }

    ${above.xl} {
        max-height: 8em;

        li:not(:nth-of-type(4n)) {
            margin-bottom: 16px;
        }
    }
`;

const SocialLink = styled(Link)`
    display: inline-block;

    &:not(:last-of-type) {
        margin-right: 16px;
    }
`;

const Partners = styled('div')`
    flex: 0 1 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: center;

    & > * {
        margin-top: 12px;
    }

    & > :not(:last-child) {
        margin-right: 24px;
    }
`;

const Footer = ({ customer, overlay, content = {} }) => {
    const { social, newsletter, navigation = [], partners = [] } = content;
    const { auth } = customer;
    const { t } = useTranslation();

    let newsletterContent = newsletter;
    // CMS Newsletter module has changed so we do this to handle both old and new
    if (newsletter.newsletter) {
        newsletterContent = newsletter.newsletter;
    }

    return (
        <Div background={colors.darkGrey} width="100%">
            <Wrapper maxWidth={variables.maxWidth} m="auto">
                {!!navigation.length && (
                    <Flex flexWrap={['wrap', null, 'nowrap']} order="1" flex={['1', '100%', '1 0 auto']}>
                        {navigation.map(({ heading, items }, index) => (
                            <Navigation width={['50%', '33%', '25%']} key={heading + index} items={items.length}>
                                <Heading>{decodeHTMLEntities(heading)}</Heading>
                                <NavigationList>
                                    {(items || []).map(
                                        ({ item }, index) =>
                                            item && (
                                                <Li key={index} pr="8px">
                                                    {item.url ? (
                                                        <Link
                                                            to={item.url}
                                                            dangerouslySetInnerHTML={{ __html: item.text }}
                                                        />
                                                    ) : (
                                                        <span>{item.text}</span>
                                                    )}
                                                </Li>
                                            )
                                    )}
                                    {!auth && index === 2 && (
                                        //Hide login until My Pages goes live
                                        <Li display="none">
                                            <Button onClick={() => overlay.show('customer_login')}>
                                                {t('sign_in')}
                                            </Button>
                                        </Li>
                                    )}
                                </NavigationList>
                            </Navigation>
                        ))}
                    </Flex>
                )}

                {social && social.socials.length && (
                    <Div flex={['1', null, '100%']} order={['2', null, '3']}>
                        <Heading>{social.heading}</Heading>
                        {social.socials.map(({ link, icon }, index) => (
                            <SocialLink key={index} to={link.url} title={icon}>
                                {icon === 'facebook' && <FacebookIcon />}
                                {icon === 'instagram' && <InstagramIcon />}
                                {icon === 'youtube' && <YouTubeIcon />}
                                {icon === 'twitter' && <TwitterIcon />}
                            </SocialLink>
                        ))}
                    </Div>
                )}

                {newsletterContent && (
                    <Div order={['3', null, '2']}>
                        {/* @todo: Fix validation */}
                        <Heading>{newsletterContent.header}</Heading>
                        <NewsletterSignup
                            isFooter
                            content={newsletterContent}
                            signupSource={NEWSLETTER_SIGNUP_FOOTER}
                        />
                    </Div>
                )}

                {!!partners.length && (
                    <Partners order="4">
                        {partners.map(
                            ({ link, logotype }, index) =>
                                logotype && (
                                    <Link to={link && link.url} key={index}>
                                        <Image
                                            alt={logotype.title}
                                            src={{
                                                url: logotype.url,
                                                w: 120,
                                            }}
                                            width="auto"
                                            maxHeight="15px"
                                        />
                                    </Link>
                                )
                        )}
                    </Partners>
                )}
            </Wrapper>
        </Div>
    );
};

Footer.propTypes = {
    content: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    overlay: PropTypes.object.isRequired,
};

export default injectModels(['customer', 'overlay'])(Footer);
