import React from 'react';
import Transition from 'react-transition-group/Transition';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { injectModel } from 'core-web/state';
import OverlayWrapper from './OverlayWrapper';

// Load in assets from theme/overlays.
let activeOverlay = null;
const overlays = {
    'filter_overlay': loadable(() => import('theme/overlays/FilterOverlay')),
    'basket_overlay': loadable(() => import('theme/overlays/BasketOverlay')),
    'product_specification': loadable(() => import('theme/overlays/ProductSpecificationOverlay')),
    'spare_parts_overlay': loadable(() => import('theme/overlays/ProductSparepartsOverlay')),
    'customer_login': loadable(() => import('theme/overlays/CustomerLogin')),
    'gallery_zoom_overlay': loadable(() => import('theme/overlays/GalleryZoomOverlay')),
    'easy_peasy_overlay': loadable(() => import('theme/overlays/EasyPeasyOverlay')),
    'notify_overlay': loadable(() => import('theme/overlays/NotifyOverlay')),
};

const DURATION = 200;

const Overlay = ({ overlay }) => {
    const { current, data, wrapper } = overlay;

    const Wrapper = wrapper ? wrapper : OverlayWrapper;

    const currentOverlay = current && overlays[current] !== undefined && overlays[current];
    const ActiveOverlay = currentOverlay || activeOverlay;

    activeOverlay = ActiveOverlay;

    return (
        <Transition
            mountOnEnter
            unmountOnExit
            enter
            in={!!currentOverlay}
            timeout={{
                appear: 0,
                enter: 50, // Set to 50 to work with loadable components
                exit: 200,
            }}
        >
            {state => (
                <Wrapper className={state} duration={DURATION}>
                    <ActiveOverlay {...(data || {})} />
                </Wrapper>
            )}
        </Transition>
    );
};

Overlay.propTypes = {
    overlay: PropTypes.shape({
        current: PropTypes.string,
        data: PropTypes.object,
        wrapper: PropTypes.node,
    }).isRequired,
};

export default injectModel('overlay')(Overlay);
