import React from 'react';
import PropTypes from 'prop-types';
import styled from 'theme/libs/styled';
import { media } from 'core-web/util/mediaqueries';

export interface Theme {
    color?: string;
    backgroundColor?: string;
    backgroundColorHover?: string;
    borderColor?: string;
    opacity?: string;
    disabled?: Theme;
    hover?: Theme & {
        opacity?: string;
        timingFunction: string;
        duration: string;
    };
}

export interface Sizes {
    [key: string]: {
        [index: string]: string;
        height: string;
        fontSize: string;
        padding: string;
    };
}

interface Props {
    theme: Theme;
    sizes: Sizes;
    // @todo
    // size: string | null | undefined | (string | null | undefined)[];
    size: any;
    height: string | string[] | null;
    fontSize: string | string[] | null;
    padding: string | string[] | null;
}

const ignoredProps = ['theme'];

const color = ({ theme: { color, opacity, backgroundColor, borderColor } }: { theme: Theme }) => `
    color: ${color || ''};
    opacity: ${opacity || '1'};
    background-color: ${backgroundColor || ''};
    border: ${borderColor ? `1px solid ${borderColor}` : 'none'};
`;

// prettier-ignore
const disabled = ({ theme: { disabled } }: { theme: Theme }) => disabled ? `
    &:disabled, &.disabled {
        cursor: initial;
        color: ${disabled.color || ''};
        background-color: ${disabled.backgroundColor || ''};
        border: ${disabled.borderColor ? `1px solid ${disabled.borderColor}` : 'none'};
    }
` : ``;

// prettier-ignore
const hover = ({ theme: { hover } }: { theme: Theme }) => hover ? `
    &:hover:not(:disabled):not(.disabled) {
        opacity: ${hover.opacity || '1'};
        color: ${hover.color || ''};
        background-color: ${hover.backgroundColor || ''};
    }
        transition: color ${hover.duration} ${hover.timingFunction};
        background-color ${hover.duration} ${hover.timingFunction};
` : ``;

// prettier-ignore
const StyledButton = styled('button', {
    shouldForwardProp: (prop: string) => ignoredProps.indexOf(prop) === -1,
})`
    ${color}
    ${disabled}
    ${media.hover} {
        ${hover}
    }
`;

// @todo: any
const mapSize = (obj: Sizes, key: any, prop: string) => {
    if (obj && key) {
        return Array.isArray(key) ? key.map((s: string) => (s ? obj[s][prop] : null)) : obj[key][prop];
    }

    return null;
};

const Button = ({ theme, sizes, size, height, fontSize, padding, ...rest }: Props) => {
    const btnHeight = height || mapSize(sizes, size, 'height');
    const btnFontSize = fontSize || mapSize(sizes, size, 'fontSize');
    const btnPadding = padding || mapSize(sizes, size, 'padding');

    return <StyledButton theme={theme} height={btnHeight} fontSize={btnFontSize} padding={btnPadding} {...rest} />;
};

const responsivePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]);

Button.propTypes = {
    alignItems: responsivePropType,
    display: PropTypes.oneOfType([
        PropTypes.oneOf(['initial', 'flex', 'inline-flex', 'none']),
        PropTypes.arrayOf(PropTypes.oneOf(['initial', 'flex', 'inline-flex', 'none'])),
    ]),
    fontSize: responsivePropType,
    height: responsivePropType,
    justifyContent: responsivePropType,
    padding: responsivePropType,
    theme: PropTypes.object.isRequired,
    size: responsivePropType,
    sizes: PropTypes.object,
};

Button.defaultProps = {
    alignItems: 'center',
    display: 'flex',
    fontSize: null,
    height: null,
    justifyContent: 'center',
    padding: null,
    size: null,
    sizes: null,
};

export default Button;
