export const STORM_PACKAGE_TYPES = [8, 22];
export const PACKAGE_TYPE_ID = 8;
export const SIM_CODE = '';
export const SIM_TYPES = [];
export const MOLNUS_SIM_CODE = '';
export const MOLNUS_SIM_TYPES = [];
export const LICENSE_CODE = '';
export const STORM_FILE_IMAGE_TYPES = [1];
export const STORM_FILE_VIDEO_TYPES = [4, 6];
export const STORM_MEDIA_TYPES = [...STORM_FILE_IMAGE_TYPES, ...STORM_FILE_VIDEO_TYPES];
//List of file type code-prefixes for excluding files depending on locale.
export const STORM_FILE_PREFIXES = ['hjl_', 'dg_', 'du_', 'fs_dk_'];
export const FREIGHT_PRODUCT_TYPE = 3;
export const SALE_PRODUCT_NO = process.env.REACT_APP_STORM_SALE_NO;
export const SALE_PRODUCT_CODE = 'custom_price';
export const STORM_ROLE_SALE = process.env.REACT_APP_STORM_ROLE_SALE;
export const STORM_ROLE_ADMIN = process.env.REACT_APP_STORM_ROLE_ADMIN;
export const STORM_MEDIA_URL = process.env.REACT_APP_STORM_MEDIA_URL;
export const STORM_NO_VAT_SALES_AREA_CODE = 29;

export const COLLECTOR_FRONTEND_URL = process.env.REACT_APP_COLLECTOR_FRONTEND_URL;
export const COLLECTOR_STORE_ID_B2B = process.env.REACT_APP_COLLECTOR_STORE_ID_B2B;
export const COLLECTOR_STORE_ID_B2C = process.env.REACT_APP_COLLECTOR_STORE_ID_B2C;
// dynamic placeholder filenames?
export const PLACEHOLDER = `${process.env.REACT_APP_MEDIA_URL}/site/placeholder.jpg`;
export const PLACEHOLDER_SPARE_PARTS = `${process.env.REACT_APP_MEDIA_URL}/site/placeholder_spare_part.jpg`;
//Product relations code
export const MANUAL_ORDER_HANDLING = 'manual_order_handling';
export const STORM_SALES_REP = 'sales_rep';
export const UPSELL_BASKET = ['fs_upsell_basket'];
export const UPSELL_CHECKOUT = ['fs_upsell_checkout'];
export const PRODUCT_PAGE_TIPS = ['fs_product_page_tips', 'UpSell'];
export const ACCESSORIES = ['fs_accessories', 'Accessory-3'];
export const PRODUCT_NEW = ['hy_nyhet', 'fs_nyhet'];
export const PRODUCT_OUT_FOR_SEASON = 'hyma_slut_sasong';
export const PRODUCT_REPLACED_PARAMETRIC = 'hyma_replaced';
export const PRODUCT_MACHINE_TEXT = 'hy_machine_translation';
export const PRODUCT_PREORDER = 'fs_prebooking';
export const PRODUCT_BACKORDER = 'fs_backorder';
export const PRODUCT_EXPIRED = 'hyma_expired';
export const PRODUCT_DROPSHIP = 'fs_dropship';
export const PRODUCT_CLEARANCE = '';
export const RELATED_PACKAGES = '';
export const PRICE_INC_VAT = 'pricing.from.incVat';
export const PRICE_EX_VAT = 'pricing.from.exVat';
export const APP_ORGANIZATION = 'Frishop';
export const INTERNATIONAL_APP_ORGANIZATION = 'Frishop';
export const AFFILIATION = 'Frishop';
export const RESOLVE_REDIRECT_PARAMETERS = [];
export const GLOBAL_PRODUCT_DESC = 'hy_globalproductdesc';
export const PRODUCT_ONLY_IN_COMBINATION = 'hy_onlyincombination';
export const VIRTUAL_PRODUCT = 'fs_virtual';
export const FREE_FREIGHT = 'fs_freefreight';
export const POSTNORD_SERVICE_POINT_CODE = 'P19';
export const POSTNORD_POSTBOX_CODE = 'P19BOX';
export const BRING_SERVICE_POINT_CODE = 'PNL342';
export const HY_INT_FRAKT_FRI = 'HY_INT_FRAKT_FRI';
export const HY_SE_FRAKT_FRI = 'HY_SE_FRAKT_FRI';
export const EASY_PEASY = 'hy_epi';
export const EASY_PEASY_ICON = `${process.env.REACT_APP_MEDIA_URL}/site/EasyPeasy_icon.png`;
export const EASY_PEASY_LOGO = `${process.env.REACT_APP_MEDIA_URL}/site/EasyPeasy_Logo.png`;
export const STORM_FILTER_PREFIX_BLOCKLIST = ['mk_', 'hjl_', 'du_', 'dg_', 'internal_', 'hyma_'];
export const DROPSHIP_MARKET_SPECIFIC = {
    'da_DK': 'fs_dk_dropship',
};
export const PRODUCTNAME_EXTRA = 'fs_productname_extra';
export const PRODUCTDESC_EXTRA = 'fs_productdescription_extra';

// Newsletter Signup Sources
export const NEWSLETTER_SIGNUP_FOOTER = 'Footer';
export const NEWSLETTER_SIGNUP_SITEFORM = 'Siteform';

// Badge Image
export const BADGE_IMAGE = 'hy_badge_image';

// Cookie List
export const COOKIE_LIST = [
    'sfmc_id',
    'collector_token',
    'checkoutId',
    'paymentReference',
    'giftcard_balance',
    'loginPolicy',
    'isLoggedIn',
    'delivery_postcode',
    'shipping_service_point',
    'delivery_method',
    'companyId',
    'customerId',
    'compare_products',
    'show_vat',
    'basket_items_amount',
    'basket_id',
    'hide_info_banner',
    'user_newsletter_marketing',
    'user_newsletter_email',
    'XSRF-TOKEN',
    'frishop_optanon_consent',
    'frishop_session',
];
