import { get } from 'core-web/libs/grebbcommerce-api/util/authClient';
import { getModel } from 'core-web/state';
import { auth } from 'core-web/util/firebase';

export default async basketId => {
    let token;
    if (auth.currentUser) {
        token = await auth.currentUser.getIdToken();
    }

    const response = await get(
        `/frontend/basket/id/${basketId}`,
        { 'application_id': getModel('application').applicationId },
        token ? { 'Authorization': `Bearer ${token}` } : {}
    );

    return await response.body();
};
