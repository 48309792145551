import GTM from '@grebban/gtm';
import { getModel } from 'core-web/state';
import { getProducts } from './utils/product';

export default ({ basketId, items, isAuth }) => {
    const application = getModel('application');
    GTM.addDataLayerEvent(`checkout${isAuth ? '_auth' : ''}`, {
        ecommerce: {
            basket: { id: basketId },
            checkout: {
                products: getProducts(items),
            },
        },
        currency: application.currency.code,
    });
};
