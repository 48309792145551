import GTM from '@grebban/gtm';
import { getProduct } from './utils/product';

export default ({ product, upsellType }) => {
    if (!product) {
        return;
    }

    GTM.addDataLayerEvent('add_upsell_product', {
        upsellType,
        product: getProduct(product),
    });
};
