import React, { Component } from 'react';

class ErrorCatcher extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    componentDidCatch(error, info) {
        this.setState({
            hasError: true,
        });

        console.warn(error);
        console.warn(info);
    }

    render() {
        if (this.state.hasError) {
            return <p>Something went wrong...</p>;
        }

        return this.props.children;
    }
}

export default ErrorCatcher;
