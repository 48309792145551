export default (obj: object | null | undefined, key: string, def = undefined) => {
    if (obj === undefined || obj === null) {
        return def;
    }

    const keys = key.split('.');

    if (keys.length <= 1 && process.env.REACT_APP_DEBUG === 'true') {
        console.warn('No dot present in get argument:', key);
    }

    const ret = keys.reduce((o: any, k: string) => (o === undefined || o === null ? o : o[k]), obj);

    return ret !== undefined && ret !== null ? ret : def;
};
