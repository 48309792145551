import React from 'react';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { injectModels } from 'core-web/state';

const navbars = {
    'default': loadable(() => import('./Navbar')),
    'checkout': loadable(() => import('./NavbarCheckout')),
    'checkout-thanks': loadable(() => import('./NavbarCheckoutThanks')),
};

const checkoutTemplates = ['checkout', 'checkout-thanks', 'checkout-sales', 'checkout-auth'];

const Header = ({ application, content, page }) => {
    const pageIsLoaded = page && page.data;
    const isCheckoutView = pageIsLoaded && checkoutTemplates.includes(page.data.template);
    const isCheckoutThanks = pageIsLoaded && page.data.template === 'checkout-thanks';
    const isFrontPageView = pageIsLoaded && page.data.template === 'frontpage';
    const layout = isCheckoutThanks ? page.data.template : isCheckoutView ? 'checkout' : 'default';
    const isBannerSiteWide = application?.config?.header?.information_banner?.set_site_wide;
    const showBanner = isBannerSiteWide ? isBannerSiteWide : isFrontPageView;

    const Component = navbars[layout] || navbars.default;

    return <Component content={content} showBanner={pageIsLoaded && showBanner} application={application} />;
};

Header.propTypes = {
    application: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
};

export default injectModels(['application', 'page'])(Header);
