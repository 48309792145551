import { put } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';
import tracking from 'core-web/components/Tracking';

export default async (basketId, items) => {
    const trackingData = tracking.getTrackingData();

    const data = {
        'application_id': getModel('application').applicationId,
        items,
        ...trackingData,
    };

    return await put(`/frontend/basket/${basketId}/items/update`, data);
};
