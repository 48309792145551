import { get } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async (servicePointId: number, provider = '') => {
    const application = getModel('application');
    const { applicationId, locale } = application;
    const shippingProviderQuery = !!provider ? `&provider=${provider}` : '';
    const servicePointQuery = `&service_point_id=${servicePointId}`;

    return await get(
        `/frontend/shipping/delivery-point?country=${locale
            .slice(-2)
            .toUpperCase()}${servicePointQuery}${shippingProviderQuery}`,
        {
            'application_id': applicationId,
        }
    );
};
