import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Events from 'core-web/libs/Events';
import { PageEvents, ProductEvents } from 'core-web/libs/Events/constants';
import { injectModels } from 'core-web/state';
import { PageMeta } from 'core-web/components/Meta';
import { inServer } from 'core-web/constants';
import views from 'core-web/views';

class RouteResolver extends Component {
    static propTypes = {
        application: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        page: PropTypes.object.isRequired,
        search: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        const { application, page, search } = props;

        search.queryDefault(props.location);

        Events.subscribe('reload', () => {
            this.resolve();
        });

        if (!inServer && page.data) {
            setTimeout(() => {
                Events.trigger(PageEvents.VIEW, { application, page });

                if (page.type === 'product') {
                    Events.trigger(ProductEvents.VIEW, { data: page.data.product });
                }
            });
        }
    }

    componentDidMount() {
        const { page } = this.props;

        if (!page.data) {
            this.resolve();
        }

        this.lastPageRender = null;
    }

    shouldComponentUpdate(nextProps) {
        const { page, location } = this.props;

        if (page.isFetching && !nextProps.page.isFetching) {
            return true;
        }

        if (location.pathname !== nextProps.location.pathname) {
            this.resolve(nextProps.application, nextProps.page);
        } else if (location.search !== nextProps.location.search) {
            this.resolveSearch();
        }

        return false;
    }

    resolve = () => {
        const { application, page } = this.props;

        const currentPathname = application.getCurrentPathname();
        const search = page.getCurrentSearch();

        page.resolveSlug(currentPathname, search);
    };

    resolveSearch = () => {
        const { page } = this.props;
        const search = page.getCurrentSearch();
        page.resolveSearch(search);
    };

    render() {
        const { page, history } = this.props;
        const NotFound = views.not_found || null;
        // Default page = Not Found.
        let PageRenderer = NotFound;

        // If we are loading and have an old page to show.
        if (page.isFetching && this.lastPageRender) {
            PageRenderer = this.lastPageRender;
            // If we do not have a page.type and we are not loading, we should show NotFound.
        } else if (page.isFetching || page.isFetching === null) {
            PageRenderer = () => <div />;
        }

        if (page.isFetching === false && page.type) {
            if (!inServer && page.type === 'redirect' && page.data.to) {
                history.replace(page.data.to);
                return null;
            }
            // Check if this page has a template set.
            if (page.template && views[page.type] && views[page.type].templates) {
                // Check if the template we're after is defined.
                if (views[page.type].templates[page.template]) {
                    PageRenderer = views[page.type].templates[page.template];
                    this.lastPageRender = PageRenderer;
                } else {
                    console.error(`The template ${page.template} for page type ${page.type} was not found.`);
                }
            } else {
                PageRenderer = page.type && views[page.type];
                this.lastPageRender = PageRenderer;
            }
        } else if (page.isFetching === false && !page.type) {
            // if last page was 404 set as last page
            this.lastPageRender = PageRenderer;
        }

        return (
            <Fragment>
                <PageMeta />
                <PageRenderer data={page.data} />
            </Fragment>
        );
    }
}

export default withRouter(injectModels(['application', 'page', 'search'])(RouteResolver));
