const getQueryParams = location => {
    const params = {};

    if (location?.search) {
        const search = location.search.substr(1);

        const paramsArray = search.split('&');

        let lastAddedItemKey = null;
        paramsArray.forEach(p => {
            const array = p.split('=');

            if (array[1]) {
                lastAddedItemKey = array[0];
                params[array[0]] = array[1];
            } else if (lastAddedItemKey) {
                params[lastAddedItemKey] += `&${array[0]}`;
            }
        });
    }

    return params;
};

export default getQueryParams;
