import GTM from '@grebban/gtm';

export default ({ event, type, message }) => {
    if (!event || !type || !message) {
        return;
    }

    GTM.addDataLayerEvent(event, {
        data: {
            type,
            message,
        },
    });
};
