import { acquireMutex } from 'core-web/state';
import store from 'core-web/state/store';
import { PACKAGE_TYPE_ID } from 'theme/config/constants';
import {
    addBasketInformation,
    addBasketItems,
    addBasketPaymentFields,
    addGiftCard,
    addPackageToBasket,
    addToBasket,
    addVoucher,
    checkGiftCard,
    createBasket,
    deleteBasketItem,
    deliveryReset,
    emptyBasket,
    getAbandonedBasket,
    getBasket,
    getBasketId,
    getBasketIdCookie,
    getBasketPayment,
    getBasketPaymentCallback,
    getCheckout,
    getOrCreateBasket,
    getServicePoints,
    isEmpty,
    maybeUpdateSiteVatSetting,
    removeBasket,
    removeOutOfStockItems,
    removeVoucher,
    resetBasketBuyer,
    rollbackGiftCard,
    setBasketId,
    toggleBasket,
    updateBasket,
    updateBasketBuyer,
    updateBasketCompanyInfo,
    updateBasketItem,
    updateBasketItemPrice,
    updateBasketItems,
    updateBasketPackageItem,
    updateBasketSecret,
    updateDeliveryMethod,
    updateItemDiscounts,
    updatePaymentMethod,
    updateSecretBasketBuyer,
    updateServicePoint,
} from './actions';

export { default as BasketReducers } from './reducers';

const basketActions = {
    getBasketId: () => {
        return store.dispatch(getBasketId());
    },
    getBasketIdCookie: () => {
        return getBasketIdCookie();
    },
    getPaymentId: () => {
        return store.getState().products.showVat
            ? process.env.REACT_APP_STORM_PAYMENT_METHOD_B2C
            : process.env.REACT_APP_STORM_PAYMENT_METHOD_B2B;
    },
    getAbandonedBasket: basketId => {
        return store.dispatch(getAbandonedBasket(basketId));
    },
    getBasket: () => {
        return store.dispatch(getBasket());
    },
    setBasketId: basketId => {
        store.dispatch(setBasketId(basketId));
    },
    addToBasket: async (partNo, quantity, pricelistId, infos, comment) => {
        await store.dispatch(getOrCreateBasket());
        const basketId = await store.dispatch(getBasketId());
        const mutexLock = await acquireMutex('basket.getBasket');
        let basketItemId = null;
        let newQuantity = null;
        const imsi = infos?.imsi;
        const basketItems = store.getState().basket.items;

        basketItems &&
            basketItems.forEach(item => {
                // We need to check if there is a parent_line_no here, otherwise we might try to update promotions or packages, which we shouldn't.
                if (item.parent_line_no === null && item.part_no === partNo) {
                    basketItemId = item.id;
                    newQuantity = parseInt(quantity, 10) + parseInt(item.quantity, 10);
                }
            });

        mutexLock();
        // We should not update existing sim-products, so if the imsi is set here, we create a new order-line. We should probably refactor this but there is no time atm.
        if (basketItemId && !imsi) {
            return store.dispatch(updateBasketItem(basketId, partNo, basketItemId, newQuantity, pricelistId, 'add'));
        }

        return store.dispatch(addToBasket(basketId, partNo, quantity, pricelistId, infos, comment));
    },
    addPromotionItems: async items => {
        if (!items.length) {
            return;
        }
        await store.dispatch(getOrCreateBasket());
        const basketId = await store.dispatch(getBasketId());

        const addOrUpdate = async promotionItems => {
            if (!promotionItems.length) {
                return;
            }
            const basket = store.getState().basket;
            const itemsCopy = JSON.parse(JSON.stringify(promotionItems));
            const newItems = itemsCopy.reduce((newItems, promotionItem) => {
                let basketItemId = null;
                let newQuantity = null;
                (basket.items || []).forEach(item => {
                    // We need to check if there is a parent_line_no here, otherwise we might try to update promotions or packages, which we shouldn't.
                    if (item.parent_line_no === null && item.part_no === promotionItem.part_no) {
                        basketItemId = item.line_no;
                        newQuantity = parseInt(item.quantity, 10) + parseInt(promotionItem.quantity, 10);
                    }
                });
                if (basketItemId) {
                    promotionItem.quantity = newQuantity;
                    promotionItem['line_no'] = basketItemId;
                }
                return [...newItems, promotionItem];
            }, []);

            const nonExsistingItems = newItems.filter(item => !item.line_no);
            const existingItems = promotionItems.filter(item =>
                newItems.find(newItem => newItem.line_no && newItem.part_no === item.part_no)
            );

            if (nonExsistingItems.length) {
                // should we add possible dropship info here?
                // search for getDropshipInfo to se examples
                await store.dispatch(addBasketItems(basketId, nonExsistingItems));
                return addOrUpdate(existingItems);
            }
            return store.dispatch(updateBasketItems(basketId, newItems));
        };

        return addOrUpdate(items);
    },
    addPackageToBasket: async (items, mainProduct) => {
        // @TODO Check if mainProduct can be removed.
        await store.dispatch(getOrCreateBasket());
        const basketId = await store.dispatch(getBasketId());
        const mutexLock = await acquireMutex('basket.getBasket');
        if (!basketId) {
            await store.dispatch(createBasket());
        }
        mutexLock();
        return store.dispatch(addPackageToBasket(basketId, items, mainProduct));
    },
    updateBasketPackageItem: async (item, quantity, source) => {
        const basketId = await store.dispatch(getBasketId());
        const mutexLock = await acquireMutex('basket.getBasket');
        if (!basketId) {
            await store.dispatch(createBasket());
        }
        mutexLock();
        return store.dispatch(updateBasketPackageItem(basketId, item, quantity, source));
    },

    removeBasket: () => {
        return store.dispatch(removeBasket());
    },
    removeOutOfStockItems: () => {
        return store.dispatch(removeOutOfStockItems());
    },

    modifyBasketItemQuantity: async (item, quantityChange, absoluteQuantity = false) => {
        if (!absoluteQuantity && quantityChange === 0) {
            // We cannot change the relative quantity with 0..
            console.warn('basket.modifyBasketItemQuantity() - We cannot change the relative quantity with 0');
            return false;
        }
        // Fetches the basketId
        const basketId = await store.dispatch(getBasketId());
        let result = null;
        try {
            // Uses the quantity directly if absolute is set, otherwise adds/subtracts the change from the item.quantity
            const newQuantity = absoluteQuantity ? quantityChange : item.quantity + quantityChange;
            // If the item is a package
            if (item.type === PACKAGE_TYPE_ID) {
                result = await basketActions.updateBasketPackageItem(
                    item,
                    newQuantity,
                    newQuantity > item.quantity ? 'add' : 'remove'
                );
            } else {
                // Standard product (in this case, this would also handle IMSI products)
                if (newQuantity === 0) {
                    // Delete it from the basket
                    result = await basketActions.deleteFromBasket(item.line_no);
                } else {
                    // Update the item according to the new quantity.
                    // It is just easier using the updateBasketItem directly instead of adding more functionality to the other functions
                    result = await store.dispatch(
                        updateBasketItem(
                            basketId,
                            item.part_no,
                            item.id,
                            newQuantity,
                            item.price_list_id,
                            newQuantity > item.quantity ? 'add' : 'remove'
                        )
                    );
                }
            }
        } catch (e) {
            // @TODO Error handling and user feedback.
            console.error(new Error(e));
        }
        return result;
    },

    deleteFromBasket: async lineNo => {
        if (lineNo) {
            const basketId = await store.dispatch(getBasketId());
            return store.dispatch(deleteBasketItem(basketId, lineNo));
        }
    },
    removeFromBasket: async (partNo, quantity, pricelistId) => {
        // @TODO Check if needed, Might not be used anymore.
        const basketId = await store.dispatch(getBasketId());
        const mutexLock = await acquireMutex('basket.getBasket');
        let basketItemId = null;
        let newQuantity = null;
        store.getState().basket &&
            store.getState().basket.items.forEach(item => {
                // @TODO Must be changed to line_no.. we cannot know what line_no from the part_no etc.
                if (item.part_no === partNo) {
                    basketItemId = item.id;
                    newQuantity = item.quantity - quantity;
                }
            });

        mutexLock();
        if (basketId !== null) {
            return store.dispatch(updateBasketItem(basketId, partNo, basketItemId, newQuantity, pricelistId, 'remove'));
        }
    },
    emptyBasket: voucherCode => {
        return store.dispatch(emptyBasket(voucherCode));
    },
    updateBasketItems: async items => {
        const basketId = await store.dispatch(getBasketId());
        return store.dispatch(updateBasketItems(basketId, items));
    },

    updateBasketItemPrice: (basketId, basketItemId, lineNo, price, percentage) => {
        return store.dispatch(updateBasketItemPrice(basketId, basketItemId, lineNo, price, percentage));
    },
    toggleBasket: () => {
        return store.dispatch(toggleBasket());
    },
    addGiftCard: (cardNo, cvc) => {
        return store.dispatch(addGiftCard(cardNo, cvc));
    },
    checkGiftCard: (cardNo, cvc) => {
        return store.dispatch(checkGiftCard(cardNo, cvc));
    },
    rollbackGiftCard: paymentCode => {
        return store.dispatch(rollbackGiftCard(paymentCode));
    },
    addVoucher: voucherId => {
        return store.dispatch(addVoucher(voucherId));
    },
    removeVoucher: voucherId => {
        return store.dispatch(removeVoucher(voucherId));
    },
    getBasketPayment: (paymentParameters, provider) => {
        return store.dispatch(getBasketPayment(paymentParameters, provider));
    },
    getCheckout: (returnDeliveryMethods = false, returnDeliveryPoints = false) => {
        return store.dispatch(getCheckout(returnDeliveryMethods, returnDeliveryPoints));
    },
    updatePaymentMethod: (
        paymentMethodId,
        returnDeliveryMethods = false,
        returnDeliveryPoints = false,
        isAuth = false
    ) => {
        paymentMethodId = paymentMethodId || basketActions.getPaymentId();
        return store.dispatch(
            updatePaymentMethod(paymentMethodId, returnDeliveryMethods, returnDeliveryPoints, isAuth)
        );
    },
    updateDeliveryMethod: (data, returnDeliveryMethods = false, returnDeliveryPoints = false) => {
        return store.dispatch(updateDeliveryMethod(data, returnDeliveryMethods, returnDeliveryPoints));
    },
    addBasketInformation: data => {
        return store.dispatch(addBasketInformation(data));
    },
    updateBasket: (basketId, data, skipEvent) => {
        return store.dispatch(updateBasket(basketId, data, skipEvent));
    },
    // TODO: REMOVE UNUSED STUFF HERE WHEN API READY
    updateBasketSecret: (basketId, data) => {
        return store.dispatch(updateBasketSecret(basketId, data));
    },
    updateBasketBuyer: (basketId, customerId, companyId) => {
        return store.dispatch(updateBasketBuyer(basketId, customerId, companyId));
    },
    resetBasketBuyer: (basketId, returnDeliveryMethods = false, returnDeliveryPoints = false) => {
        return store.dispatch(resetBasketBuyer(basketId, returnDeliveryMethods, returnDeliveryPoints));
    },
    updateSecretBasketBuyer: (basketId, customerId, companyId) => {
        return store.dispatch(updateSecretBasketBuyer(basketId, customerId, companyId));
    },
    getBasketPaymentCallback: checkoutId => {
        return store.dispatch(getBasketPaymentCallback(checkoutId));
    },
    addBasketPaymentFields: data => {
        return store.dispatch(addBasketPaymentFields(data));
    },
    isEmpty: () => {
        return store.dispatch(isEmpty());
    },
    updateBasketCompanyInfo: (skipEvent = false) => {
        return store.dispatch(updateBasketCompanyInfo(skipEvent));
    },
    deliveryReset: (returnDeliveryMethods = false, returnDeliveryPoints = false) => {
        return store.dispatch(deliveryReset(returnDeliveryMethods, returnDeliveryPoints));
    },
    getServicePoints: (postalCode, methodCode) => {
        return store.dispatch(getServicePoints(postalCode, methodCode));
    },
    updateServicePoint: id => {
        return store.dispatch(updateServicePoint(id));
    },
    updateItemDiscounts: (basketId, data) => {
        return store.dispatch(updateItemDiscounts(basketId, data));
    },
    maybeUpdateSiteVatSetting: () => {
        return store.dispatch(maybeUpdateSiteVatSetting());
    },
};
export default basketActions;
