import GTM from '@grebban/gtm';
import { getImpression } from './utils/impression';

export default async data => {
    if (!data?.product) {
        return;
    }

    GTM.addDataLayerEvent('product_click', {
        ecommerce: {
            click: {
                // actionField: { list: data.list },
                products: [getImpression(data.product)],
            },
        },
    });
};
