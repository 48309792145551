import { buildQueryString, get, request } from 'core-web/libs/grebbcommerce-api/util';
import { API_URL, FORCE_API_URL, inServer } from 'core-web/constants';

export default async (url, parameters = {}, origin) => {
    // Build parameters.
    const builtParameters = buildQueryString(parameters);

    if (
        ((inServer &&
            process.env.REACT_APP_ALLOWED_DOMAINS &&
            JSON.parse(process.env.REACT_APP_ALLOWED_DOMAINS).every(domain => !url?.includes(`https://${domain}`))) ||
            !process.env.REACT_APP_ALLOWED_DOMAINS) &&
        process.env.REACT_APP_URL
    ) {
        url = process.env.REACT_APP_URL;
    }

    const requestUrl = `/frontend/content/resolve-app?url=${encodeURIComponent(url)}${
        builtParameters && `&${builtParameters}`
    }`;

    if (
        process.env.REACT_APP_ALLOWED_DOMAINS &&
        JSON.parse(process.env.REACT_APP_ALLOWED_DOMAINS).every(domain => !origin?.includes(`https://${domain}`)) &&
        API_URL
    ) {
        return await request({ method: 'GET', path: requestUrl, baseUrl: API_URL });
    } else if (process.env.REACT_APP_ALLOWED_DOMAINS && origin) {
        return await request({ method: 'GET', path: requestUrl, baseUrl: FORCE_API_URL ? API_URL : origin });
    }

    return await get(requestUrl);
};
